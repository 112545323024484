import React, { useEffect, useState } from "react";
import MobileLoadingMessage from "./MobileLoadingMessage";
import { FaChevronLeft } from "react-icons/fa";

const MobilePageTemplate = ({
  //icon to display on the page
  icon,
  //title of the page
  title,
  //array of pages objects to display as list
  pages = [],
  //boolean value to determine if the page is loading - optional
  loadingCondition = false,
  //message to display while loading - optional
  loadingMessage = "Loading...",
}) => {
  const [currentPage, setCurrentPage] = useState(null);

  useEffect(() => {
    //get query params
    const urlParams = new URLSearchParams(window.location.search);
    const page = urlParams.get("page");
    if (page) {
      //find page object from pages array
      const pageObj = pages.find((p) => p.name.toLowerCase() === page);
      setCurrentPage(pageObj);
    }
  }, [pages]);

  if (loadingCondition)
    return <MobileLoadingMessage message={loadingMessage} />;

  if (!currentPage)
    return (
      <div className="flex column">
        <div>
          <div className="listItem title border-bottom bg-primaryShadow">
            <img src={icon} style={{ width: 30 }} />
            {title}
          </div>
        </div>
        {pages.map((page, index) => {
          return (
            <div
              key={index}
              className="listItem border-bottom bg-primaryShadow"
              style={{
                color: page.component ? "white" : "grey",
              }}
              onClick={() => {
                if (page.component) {
                  //set query params
                  window.history.pushState(
                    {},
                    "",
                    window.location.origin +
                      window.location.pathname +
                      "?page=" +
                      page.name.toLowerCase()
                  );
                  setCurrentPage(page);
                }
              }}
            >
              {page.name}
            </div>
          );
        })}
      </div>
    );

  if (currentPage)
    return (
      <div>
        <div
          className="listItem title border-bottom bg-primaryShadow flex center"
          style={{
            position: "relative",
          }}
        >
          <div
            onClick={() => {
              window.history.pushState(
                {},
                "",
                window.location.origin + window.location.pathname
              );
              setCurrentPage(null);
            }}
            style={{
              position: 'absolute',
              left: '15px'
            }}
          >
            <FaChevronLeft />
          </div>
          <div
            style={{
              flex: 1,
              textAlign: "center",
              padding: "0 30px",
            }}
          >
            {currentPage.name}
          </div>
        </div>

        {currentPage.component}
      </div>
    );
};

export default MobilePageTemplate;
