import { ColorContext } from "App";
import React, { useContext, useEffect } from "react";

export default function Help() {
  const theme = useContext(ColorContext);
  const LINKS = [
    {
      name: "Email",
      content: (
        <div>
          <a
            style={{
              color: theme.primaryHighlight,
            }}
            href="mailto:overwatch.support@rescalert.net"
          >
            support@rescalert.net
          </a>
          {/* {" "}
          {`<--- Emails sent to this address will create a ticket`} */}
        </div>
      ),
    },
    {
      name: "Helpdesk My Tickets URL",
      content: (
        <div>
          <a
            style={{
              color: theme.primaryHighlight,
            }}
            href="https://portal.rescalert.com/my/tickets"
          >
            https://portal.rescalert.com/my/tickets
          </a>
        </div>
      ),
    },
    {
      name: "Helpdesk New Ticket",
      content: (
        <div>
          <a
            style={{
              color: theme.primaryHighlight,
            }}
            href="https://portal.rescalert.com/helpdesk"
          >
            https://portal.rescalert.com/helpdesk
          </a>
        </div>
      ),
    },
    // {
    //   name: "Live Chat URL",
    //   content: (
    //     <div>
    //       <a
    //         style={{
    //           color: theme.primaryHighlight,
    //         }}
    //         href="https://portal.rescalert.com/im_livechat/support/2"
    //       >
    //         https://portal.rescalert.com/im_livechat/support/2
    //       </a>
    //     </div>
    //   ),
    // },
  ];

  return (
    <div
      style={{
        padding: 20,
        color: "white",
      }}
    >
      <ul>
        <h1>Overwatch by RescAlert Helpdesk</h1>
        {LINKS.map((link, i) => (
          <li key={i} style={{
            marginTop: 10,
          }}>
            {link.name}: {link.content}
          </li>
        ))}
      </ul>
    </div>
  );
}
