import { AlarmsContentWrapper, AlarmContentRow } from "components";
import { useAlarms } from "hooks";
import useDebounce from "hooks/useDebounce";
import React, { useEffect, useState } from "react";

export default function AlarmHistory() {
  const alarms = useAlarms();

  return (
    <AlarmsContentWrapper
      title='Alarm History'
      pageIndex={alarms.page}
      maxPageIndex={Math.floor((alarms.totalCount - 1) / alarms.limit)} //use floor because it's an index
      headerFields={[
        "Current State",
        "Source",
        "Device",
        "Type",
        "Activity",
        "Status",
        "",
      ]}
      onPageChanged={(_page) => {
        alarms.setPage(_page);
      }}
      onLimitChanged={(_limit) => {
        alarms.setLimit(_limit);
      }}
      onSearchChanged={(_search) => {
        alarms.setSearch(_search);
      }}
    >
      {alarms.alarms
        ?.filter((alarm, index) => index < alarms.limit)
        .map((alarm, index) => (
          <AlarmContentRow key={alarm.id} alarm={{ ...alarm, index: index }} />
        ))}
    </AlarmsContentWrapper>
  );
}
