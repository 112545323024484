import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import * as api from "../../../apis/FusionAPI";
import Popup from "reactjs-popup";
import ConfirmPopup from "components/UI/ConfirmPopup";
import PopupMenu from "./PopupMenu";
import ContentRow from "./ContentRow";

import { FaEye, FaSave } from "react-icons/fa";
import { FaPencil } from "react-icons/fa6";

import Cookies from "js-cookie";
import WidgetLoadingAnimation from "components/Widgets/WidgetLoadingAnimation";
import { ColorContext } from "App";
import { getVisibleUsers } from "utils/roleUtils";

export default function MembersTab({
  list,
  mousePosition,
  setMousePosition,
  perms,
  setMemberList,
  // roleList,
  onCreateUser,
  listOnly,
  editableOrgName,
}) {
  const navigate = useNavigate();
  const theme = useContext(ColorContext);

  const [search, setSearch] = useState("");
  const [modalOpen, setModalOpen] = useState(true);
  const [selected, setSelected] = useState(0);

  const [showConfirmDeleteMember, setShowConfirmDeleteMember] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);

  const [orgName, setOrgName] = useState("");
  const [editOrgName, setEditOrgName] = useState(false);
  const [hierarchy, setHierarchy] = useState(null);
  const [roleList, setRoleList] = useState([]);
  const [yourHighestRoleHierarchyId, setYourHighestRoleHierarchyId] =
    useState(null);

  useEffect(() => {
    (async () => {
      await api.getAllRoles().then((res) => {
        setRoleList([...res.data.result]);
      });

      if (editableOrgName)
        await api.getOrgName().then((res) => {
          setOrgName(res.data.result.work_place_name);
        });
        
      await api
        .getWorkplaceMembers(getVisibleUsers(perms))

        .then((res) => {
          setMemberList([...res.data.result]);
        });
    })();
  }, []);
  useEffect(() => {
    setModalOpen(false);
    const rolesToHierarch = roleList.filter((item) => {
      return parseInt(item.role_Id) >= 0;
    });
    let hierarchy = {};
    rolesToHierarch.forEach((role) => {
      hierarchy[role.role_Id] = role.hierarchy_level;
    });
    let max = 999;

    let l = roleList;
    const myRoles = l
      .filter((item) => {
        if (item.users?.includes(Cookies.get("username"))) {
          return item.role_Id;
        }
      })
      .map((item) => {
        return item.role_Id;
      });

    rolesToHierarch.forEach((role) => {
      if (myRoles.includes(role.role_Id)) {
        if (role.hierarchy_level <= max) {
          max = role.hierarchy_level;
        }
      } else {
      }
    });
    setYourHighestRoleHierarchyId(max);

    setHierarchy(hierarchy);
  }, [roleList]);

  const updateOrgName = async () => {
    await api
      .updateOrgName(orgName)
      .then((res) => {
        Cookies.set("organizationName", orgName);
        setEditOrgName(false);
      })
      .catch((err) => {});
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
        overflowY: "auto",
      }}
    >
      {showConfirmDeleteMember && (
        <ConfirmPopup
          open={showConfirmDeleteMember}
          setOpen={setShowConfirmDeleteMember}
          message={`Are you sure you want to remove ${selectedMember.name} from the workplace?`}
          options={["Cancel", "Yes"]}
          buttonStyles={[
            { backgroundColor: theme.background, color: theme.fontColor },
            { backgroundColor: theme.primaryHighlight, color: theme.fontColor },
          ]}
          callbacks={[
            () => {
              setShowConfirmDeleteMember(false);
              setModalOpen(false);
              setSelectedMember(null);
            },
            () => {
              (async () => {
                await api.deleteUser(selectedMember.userId);
                setModalOpen(false);
                setSelectedMember(null);
                setShowConfirmDeleteMember(false);
                await api
                  .getWorkplaceMembers([9, 10, 12, 13])

                  .then((res) => {
                    setMemberList([...res.data.result]);
                  });
              })();
            },
          ]}
        />
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 5,
        }}
      >
        {editableOrgName && (
          <div
            style={{
              display: "flex",
              gap: 10,
              alignItems: "center",
            }}
          >
            <input
              type="text"
              style={{
                fontSize: 24,
                backgroundColor: "#426b98",
                height: 39,
                borderRadius: 10,
                paddingLeft: 15,
                paddingRight: 15,
                width: "max-content",
                border: editOrgName ? "1px solid white" : "none",
              }}
              disabled={!editOrgName}
              value={orgName}
              onChange={(e) => setOrgName(e.target.value)}
            />

            {editOrgName ? (
              <button
                onClick={() => {
                  updateOrgName();
                }}
              >
                <FaSave />
              </button>
            ) : (
              <button
                onClick={() => {
                  setEditOrgName(true);
                }}
              >
                <FaPencil />
              </button>
            )}
          </div>
        )}

        <div
          style={{
            marginLeft: "auto",
            display: "flex",
            alignItems: "center",
          }}
        >
          <input
            placeholder="Search..."
            style={{
              width: 194,
              height: 39,
              backgroundColor: theme.primary,
              borderRadius: 10,
              paddingLeft: 15,
              paddingRight: 15,
            }}
            onChange={(e) => setSearch(e.target.value)}
            value={search}
          />
        </div>
      </div>
      <div
        style={
          {
            // marginLeft: 33,
          }
        }
      >
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "2fr 1.5fr 3fr 3fr 2fr .9fr",
            paddingTop: 20,
            paddingBottom: 20,
            textAlign: "left",
            fontSize: 20,
            borderBottom: "1px solid gray",
            textAlign: "left",
          }}
        >
          <div>Users</div>
          <div>Roles</div>
          <div>Email</div>
          <div>Created By</div>
          <div>Date Created</div>
          <div> </div>
        </div>
      </div>
      <div
        style={{
          overflowY: "auto",
          height: "100%",
        }}
      >
        {list.length === 0 && <WidgetLoadingAnimation />}
        {list &&
          hierarchy &&
          yourHighestRoleHierarchyId !== null &&
          list
            .sort((a, b) => {
              return new Date(b.date_added) - new Date(a.date_added);
            })
            .sort((a, b) => {
              return Cookies.get("username") === a.email ? -1 : 1;
            })
            .map((item, index) => {
              let highestHierarchyRole = 999;
              JSON.parse(item.roles).forEach((role) => {
                if (role < 0) return;
                if (hierarchy[role] <= highestHierarchyRole) {
                  highestHierarchyRole = hierarchy[role];
                }
              });
              let sharedHierarchyOrHigher = null;
              if (perms.accessLevel === 8) {
                sharedHierarchyOrHigher = false;
              } else
                sharedHierarchyOrHigher =
                  highestHierarchyRole <= yourHighestRoleHierarchyId;

              return (
                <div key={index}>
                  {(item.name
                    .toLowerCase()
                    .replace(/ /g, "")
                    .includes(search.toLowerCase().replace(/ /g, "")) ||
                    item.role_name
                      .toLowerCase()
                      .replace(/ /g, "")
                      .includes(search.toLowerCase().replace(/ /g, "")) ||
                    item.email
                      .toLowerCase()
                      .replace(/ /g, "")
                      .includes(search.toLowerCase().replace(/ /g, "")) ||
                    item.create_by_email
                      .toLowerCase()
                      .replace(/ /g, "")
                      .includes(search.toLowerCase().replace(/ /g, ""))) && (
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "2fr 1.5fr 3fr 3fr 2fr .25fr",
                        paddingTop: 20,
                        height: 80,
                        textAlign: "left",
                        fontSize: 20,
                        borderBottom: "1px solid gray",
                        textAlign: "left",
                      }}
                    >
                      <div
                        style={{
                          fontSize: 16,
                          fontWeight: "normal",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          marginLeft: 5,
                          marginRight: 5,
                        }}
                      >
                        <div>{item.name}</div>
                        {item.activated === 0 && (
                          <div
                            style={{
                              color: "red",
                              fontSize: 15,
                              marginLeft: 10,
                            }}
                          >
                            {"(pending)"}
                          </div>
                        )}
                      </div>
                      <div style={{ fontSize: 16, fontWeight: "normal" }}>
                        {/* {item.role_name} */}
                        {item.roles ? (
                          JSON.parse(item.roles).filter((item) => {
                            return item !== -2;
                          }).length > 1 ? (
                            <Popup
                              trigger={<div>Multiple Roles</div>}
                              position="top center"
                              on="hover"
                              closeOnDocumentClick
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: 5,
                                  fontSize: 16,
                                  fontWeight: "normal",
                                  color: theme.fontColor,
                                  backgroundColor: theme.primary,
                                  borderRadius: 5,
                                }}
                              >
                                {JSON.parse(item.roles)
                                  .filter((item) => {
                                    return item != -2;
                                  })
                                  .map((role, index) => {
                                    return (
                                      <div
                                        key={index}
                                        style={{
                                          padding: 5,
                                          borderRadius: 5,
                                        }}
                                      >
                                        {/* {role} */}
                                        {roleList.find(
                                          (roleItem) =>
                                            roleItem.role_Id === role
                                        )
                                          ? roleList.find(
                                              (roleItem) =>
                                                roleItem.role_Id === role
                                            ).role_name
                                          : "missing?"}
                                      </div>
                                    );
                                  })}
                              </div>
                            </Popup>
                          ) : (
                            <div>
                              {JSON.parse(item.roles).length > 1
                                ? roleList.find(
                                    (roleItem) =>
                                      roleItem.role_Id ===
                                      JSON.parse(item.roles).filter((item) => {
                                        return item != -2;
                                      })[0]
                                  )?.role_name
                                : roleList.find(
                                    (roleItem) =>
                                      roleItem.role_Id ===
                                      JSON.parse(item.roles)[0]
                                  )
                                ? roleList.find(
                                    (roleItem) =>
                                      roleItem.role_Id ===
                                      JSON.parse(item.roles)[0]
                                  ).role_name
                                : "missing role_Id"}
                            </div>
                          )
                        ) : (
                          item.role_name
                        )}
                      </div>
                      <div
                        style={{
                          fontSize: 16,
                          fontWeight: "normal",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          marginLeft: 5,
                          marginRight: 5,
                        }}
                      >
                        {item.email}
                      </div>
                      <div
                        style={{
                          fontSize: 16,
                          fontWeight: "normal",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          marginLeft: 5,
                          marginRight: 5,
                        }}
                      >
                        {item.create_by_email}
                      </div>
                      <div
                        style={{
                          fontSize: 16,
                          fontWeight: "normal",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          marginLeft: 5,
                          marginRight: 5,
                        }}
                      >
                        {item.date_added}
                      </div>
                      {((perms.accessLevel === 12 && item.perm_level === 12) ||
                        (sharedHierarchyOrHigher &&
                          perms.accessLevel !== 12 &&
                          perms.accessLevel !== 13) ||
                        !perms.testPermission(
                          [8, 10, 11, 12, 13],
                          ["Create-EditUsers"]
                        )) && (
                        <button
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: 71,
                            height: 71,
                          }}
                          onClick={() =>
                            navigate(`/system/viewuser?userid=${item.userId}`)
                          }
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              backgroundColor: "#2a3e52",
                              borderRadius: 100,
                              width: 39,
                              height: 39,
                            }}
                          >
                            <FaEye />
                          </div>
                        </button>
                      )}
                      {Cookies.get("username") !== item.email &&
                        (perms.accessLevel === 8 ||
                          (perms.accessLevel === 13 && item.perm_level <= 12) ||
                          (perms.accessLevel === 12 && item.perm_level <= 10) ||
                          (perms.accessLevel === 10 &&
                            item.perm_level <= 10)) &&
                        (!sharedHierarchyOrHigher ||
                          perms.accessLevel === 13 ||
                          perms.accessLevel === 12) &&
                        perms.testPermission(
                          [8, 10, 11, 12, 13],
                          ["Create-EditUsers"]
                        ) && (
                          <Popup
                            position="top right"
                            trigger={
                              <button
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  width: 71,
                                  height: 71,
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    backgroundColor: "#2a3e52",
                                    borderRadius: 100,
                                    width: 39,
                                    height: 39,
                                  }}
                                >
                                  ...
                                </div>
                              </button>
                            }
                          >
                            <div>
                              {perms.testPermission(
                                [8, 9, 10, 11, 12, 13],
                                ["EditRole"]
                              ) && (
                                <button
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    width: 200,
                                    height: 50,
                                    borderRadius: 15,
                                    backgroundColor: theme.primary,
                                    border: "solid black 1px",
                                    color: theme.fontColor,
                                  }}
                                  onClick={() =>
                                    navigate(
                                      `/system/edituser?userid=${item.userId}`
                                    )
                                  }
                                >
                                  EDIT
                                </button>
                              )}
                              {perms.testPermission(
                                [8, 9, 10, 11, 12, 13],
                                ["DeleteRole"]
                              ) && (
                                <button
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    width: 200,
                                    height: 50,
                                    borderRadius: 15,
                                    backgroundColor: "red",
                                    border: "solid black 1px",
                                    color: theme.fontColor,
                                  }}
                                  onClick={() => {
                                    setSelectedMember(item);
                                    setShowConfirmDeleteMember(true);
                                  }}
                                >
                                  REMOVE
                                </button>
                              )}
                            </div>
                          </Popup>
                        )}
                    </div>
                  )}
                </div>
              );
            })}
      </div>
      {/* <div
        style={{
          // background:'blue',
          border: "2px solid rgba(255,0,0,.2)",
        }}
      > */}
      {!listOnly &&
        perms.testPermission([8, 9, 10, 11, 12, 13], ["Create-EditUsers"]) && (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              // height: "100%",
            }}
          >
            <button
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: 176,
                height: 65,
                backgroundColor: theme.primary,
                borderRadius: 5,
                // marginRight: 48,
                // marginTop: 17,
              }}
              onClick={onCreateUser}
            >
              <div>Create New User</div>
            </button>
          </div>
        )}
      {/* </div> */}
      {/* <PopupMenu
        contents={
          <div>
            {perms.testPermission(
              [8, 9, 10, 11, 12, 13],
              ["Create-EditUsers"]
            ) && (
              <button
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: 200,
                  // height: 50,
                  borderRadius: 15,
                  backgroundColor: theme.primary,
                  border: "solid black 1px",
                  color: theme.fontColor,
                }}
                onClick={() => {
                  navigate(`/system/edituser?userid=${item.userId}`);
                }}
              >
                EDIT
              </button>
            )}
            {perms.testPermission([8, 9, 10, 11, 12, 13], ["RemoveUsers"]) && (
              <button
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: 200,
                  // height: 50,
                  borderRadius: 15,
                  backgroundColor: "red",
                  border: "solid black 1px",
                  color: theme.fontColor,
                }}
                onClick={() => {
                  setSelectedMember(item);
                  setShowConfirmDeleteMember(true);
                }}
              >
                REMOVE
              </button>
            )}
          </div>
        }
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        mousePosition={mousePosition}
      /> */}
    </div>
  );
}
