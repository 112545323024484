import React, { useContext, useState } from "react";
import { ColorContext } from "App";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { LIMIT_OPTIONS } from "hooks/useAlarms";
import styles from "./AlarmContentWrapper.module.css";
import "styles/global.css";
import classNames from "classnames";
import { BsCheck } from "react-icons/bs";
import { IoClose } from "react-icons/io5";

/**
 * A wrapper for alarms page content. Meant for Pending Alarms and Alarm History.
 * @param {{children: *, title: string, pageIndex: number, maxPageIndex: number, headerFields: string[], onPageChanged: (page: number), onLimitChanged: (limit: number), onSearchChanged: (search: string), silenceControlsEnabled?: boolean, allSelected?: boolean, onSelectAll?: (), onAcknowledge?: (), onSilence?: ()}}
 * @returns React Component
 */
export default function AlarmsContentWrapper({
  children,
  title,
  pageIndex,
  maxPageIndex,
  headerFields,
  onPageChanged,
  onLimitChanged,
  onSearchChanged,
  silenceControlsEnabled = false,
  allSelected = false,
  onSelectAll = () => {},
  onAcknowledge = () => {},
  onSilence = () => {},
}) {
  const theme = useContext(ColorContext);

  const setPageIndex = (newPageIndex) => {
    newPageIndex = Math.max(0, Math.min(maxPageIndex, newPageIndex)); //insure that new index is within range
    onPageChanged(newPageIndex);
  };

  //We don't actually need to store the limit in a state. The <input> does it for us.
  const setLimit = (newLimitOption) => {
    onLimitChanged(LIMIT_OPTIONS[newLimitOption]);
  };

  return (
    <div className={styles["root"]}>
      {/* Header */}
      <div className={styles["controls-container"]}>
        <div className={"full-width"}>
          <div className={styles["title"]}>{title}</div>

          {/* Controls beneath title */}
          <div className={classNames(styles["control-parent"])}>
            {/* Acknowledge / Silence controls */}
            {silenceControlsEnabled && (
              <div className={classNames(styles["control-parent"])}>
                <div className={styles["select-control"]}>
                  <div
                    className={classNames(
                      allSelected ? "circle-fill" : "circle-border",
                      "cursor-pointer",
                      "center-content"
                    )}
                    style={{
                      "--radius": allSelected ? "16px" : "15px", //This is to remove motion when the circle changes from border to
                    }}
                    onClick={onSelectAll}
                  >
                    {allSelected && <BsCheck color={"black"} size={25} />}
                  </div>
                  Select All
                </div>
                <div className={styles["select-control"]}>
                  <div
                    className={classNames(
                      "circle-fill",
                      "center-content",
                      "cursor-pointer"
                    )}
                    style={{
                      "--fill-color": theme.primary,
                      color: theme.fontColor,
                    }}
                    onClick={onAcknowledge}
                  >
                    <BsCheck size={25} />
                  </div>
                  Acknowledge
                </div>
                <div className={styles["select-control"]}>
                  <div
                    className={classNames(
                      "circle-fill",
                      "center-content",
                      "cursor-pointer"
                    )}
                    style={{
                      "--fill-color": theme.primary,
                      color: theme.fontColor,
                    }}
                    onClick={onSilence}
                  >
                    <IoClose size={25} />
                  </div>
                  Silence
                </div>
              </div>
            )}

            {/* Page number/swtich control */}
            {maxPageIndex >= 1 && (
              <div className={classNames(styles["control-parent"])}>
                <button onClick={() => setPageIndex(pageIndex - 1)}>
                  <FaChevronLeft
                    color={pageIndex === 0 ? "grey" : "white"}
                    size={20}
                  />
                </button>
                <div>{`Page ${pageIndex + 1} of ${maxPageIndex + 1}`}</div>
                <button onClick={() => setPageIndex(pageIndex + 1)}>
                  <FaChevronRight
                    color={pageIndex >= maxPageIndex ? "grey" : "white"}
                    size={20}
                  />
                </button>
              </div>
            )}

            {/* Limit selector */}
            <div className={classNames(styles["control-parent"])}>
              <div>Limit</div>
              <select
                className={styles["selector"]}
                onChange={(e) => setLimit(e.target.value)}
              >
                {LIMIT_OPTIONS.map((option, index) => (
                  <option value={index}>{option}</option>
                ))}
              </select>
            </div>
          </div>
        </div>

        {/* Searchbox */}
        <input
          className={styles["searchbox"]}
          style={{ "--background-color": theme.primaryHighlight }}
          type='text'
          placeholder='Search...'
          onChange={(e) => onSearchChanged(e.target.value)}
        />
      </div>

      {/* Content header */}
      <div className={styles["content-header"]}>
        {headerFields &&
          headerFields.map((header) => (
            <div
              className={classNames(styles["content-row"], styles["section"])}
            >
              {header}
            </div>
          ))}
      </div>

      {/* Content */}
      <div className={styles["content-container"]}>{children}</div>
    </div>
  );
}
