import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { IconContext } from "react-icons";
import * as api from "../../apis/FusionAPI";
import GetWidgetData from "data/widgetData";
import "../Dashboard.css";
import { GetPermListForRole } from "data/permData";
// import { testPermission } from "data/routeData";
import TabsHeader from "components/TabsHeader";
import DetailsTab from "./Components/DetailsTab";
import PermissionsTab from "./Components/PermissionsTab";
import DashboardEditTab from "./Components/DashboardEditTab";
import DashboardViewTab from "./Components/DashboardViewTab";
import { usePerms } from "hooks/usePerms";
import { ColorContext, DeviceContext } from "App";
import { getVisibleUsers } from "utils/roleUtils";

export default function EditRole() {
  const navigate = useNavigate();
  let widgetData = GetWidgetData();
  const perms = usePerms();
  const deviceContext = useContext(DeviceContext);

  const [permList, setPermList] = useState([]);
  const [params, setParams] = useSearchParams();
  const [tab, setTab] = useState([0]);
  const [formData, setFormData] = useState({
    roleName: "",
    members: [],
    initialMembers: [],
    permissions: permList.map(() => true),
    layout: [],
  });
  const [checks, setChecks] = useState({ roleName: true });
  const [result, setResult] = useState("");
  const [membersList, setMembersList] = useState([]);

  useEffect(() => {
    const initTab = [params.get("tab") ? parseInt(params.get("tab")) : 0];
    setTab([...initTab]);

    (async () => {
      let perms = [];

      let deviceTypeRes = await api.getDeviceTypes();
      if (deviceTypeRes.status === 200)
        deviceTypeRes = deviceTypeRes.data.result;

      const deviceTypeArr = Object.keys(deviceTypeRes).flatMap((systemType) => {
        return Object.keys(deviceTypeRes[systemType]).flatMap((deviceType) => {
          if (
            Object.keys(deviceTypeRes[systemType][deviceType]).length > 0 &&
            !Object.keys(deviceTypeRes[systemType][deviceType]).includes(
              "validDevices"
            )
          )
            return Object.keys(deviceTypeRes[systemType][deviceType]).map(
              (subType) => systemType + " " + deviceType + "_" + subType
            );
          else return systemType + " " + deviceType;
        });
      });

      const newPerms = GetPermListForRole(deviceTypeArr);
      perms = newPerms;

      const globalWidgetPermList = (await api.getGlobalWidgetPermList()).data
        .result;

      perms = perms.filter((filter, index) => {
        if (filter.linked > 0) {
          return globalWidgetPermList.permissions.includes(
            perms[index + 1].name
              .replace(/\s/g, "")
              .replace(/\//g, "-")
              .replace(/:/g, "_")
          );
        } else {
          return globalWidgetPermList.permissions.includes(
            filter.name
              .replace(/\s/g, "")
              .replace(/\//g, "-")
              .replace(/:/g, "_")
          );
        }
      });

      Object.keys(perms).forEach((perm, index) => {
        perms[perm].index = index;
      });

      setPermList([...perms]);

      let newWidgetList = {};
      Object.keys(widgetData).forEach((key) => {
        if (globalWidgetPermList.widgetList.includes(parseInt(key))) {
          newWidgetList = { ...newWidgetList, [key]: widgetData[key] };
        }
      });

      widgetData = newWidgetList;

      const userData = await api
        .getWorkplaceMembers(getVisibleUsers(perms))
        .then((res) => {
          const temp = [...res.data.result];
          temp.map((item) => (item = { ...item, selected: false }));
          return [...temp];
        });

      await api.getRole(params.get("roleid")).then((result) => {
        const parsedPerms = JSON.parse(result.data.result[0].role_perms);

        const permissions = perms.map(() => false);

        Object.keys(parsedPerms).map((key) => {
          const str = parsePermName(key);
          const index = perms.findIndex((item) => item.name === str);

          permissions[index] = parsedPerms[key];
        });

        const initUsers = [];
        const temp = [];

        for (let i = 0; i < userData.length; i++) {
          const userRoles = JSON.parse(userData[i].roles);
          if (userRoles.includes(parseInt(params.get("roleid")))) {
            initUsers.push({ ...userData[i] });
            temp.push({ ...userData[i], selected: true });
          } else {
            temp.push({ ...userData[i] });
          }
        }

        setMembersList([...temp]);
        setFormData({
          ...formData,
          roleName: result.data.result[0].role_name,
          permissions: [...permissions],
          initialMembers: [...initUsers],
          layout: JSON.parse(result.data.result[0].widget_list),
        });
      });
    })();
  }, []);

  const theme = useContext(ColorContext);
  const renderTab = () => {
    if (!perms.testPermission([8, 9, 10, 11, 12, 13], ["EditRole"])) return;

    switch (tab[0]) {
      case 0:
        return (
          <DetailsTab
            formData={formData}
            setFormData={setFormData}
            checks={checks}
            membersList={membersList}
            setMembersList={setMembersList}
            widgetData={widgetData}
            permList={permList}
            roleid={params.get("roleid")}
          />
        );
      case 1:
        return (
          <PermissionsTab
            formData={formData}
            setFormData={setFormData}
            checks={checks}
            widgetData={widgetData}
            permList={permList}
            roleid={params.get("roleid")}
          />
        );
      case 2:
        if (
          !perms.testPermission([8, 9, 10, 11, 12, 13], ["EditRoleDashboard"])
        )
          return;
        return (
          <DashboardEditTab
            formData={formData}
            setFormData={setFormData}
            checks={checks}
            widgetData={widgetData}
            permList={permList}
            onNext={() => onNext()}
          />
        );
      case 3:
        if (!perms.testPermission([8, 9, 10, 11, 12, 13], ["ViewAsRole"]))
          return;
        return (
          <DashboardViewTab
            formData={formData}
            setFormData={setFormData}
            checks={checks}
            widgetData={widgetData}
            permList={permList}
          />
        );
      default:
        return <></>;
    }
  };

  const onBack = () => {
    if (tab[0] === 0) navigate("/system/rolemanager");
    else {
      setTab([tab[0] - 1]);
    }
  };

  const onNext = () => {
    if (tab[0] === 3) {
      setChecks({ roleName: formData.roleName !== "" });

      if (formData.roleName !== "") {
        onSubmit();
      }
    } else {
      setTab([tab[0] + 1]);
    }
  };

  const onSubmit = () => {
    let permissions = {};

    formData.permissions.forEach((perm, index) => {
      const permName = permList[index].name
        .replace(/\s/g, "")
        .replace(/\//g, "-")
        .replace(/:/g, "_");
      permissions = { ...permissions, [permName]: perm };
    });

    const members = membersList
      .filter((filter) => {
        let msList = [];
        if (filter.msRoles) msList = JSON.parse(filter.msRoles);
        return (
          filter.selected && !msList.includes(parseInt(params.get("roleid")))
        );
      })
      .map((member) => {
        return member.userId;
      });

    api
      .updateRole(
        params.get("roleid"),
        formData.roleName,
        members,
        JSON.stringify(permissions),
        JSON.stringify(formData.layout)
      )
      .then((res) => {
        setResult({ type: "success", message: "Role created successfully" });
        navigate("/system/rolemanager?tab=0");
      })
      .catch((e) => {
        setResult({
          type: "failed",
          message: "Failed: " + e.response.data.message,
        });
      });
  };

  const parsePermName = (permName) => {
    let str = permName
      .replace(/-/g, "/")
      .replace(/_/g, permName.includes("TechnologyBridge") ? "_" : ":");
    let characters = str.split("");
    let result = [];

    characters.forEach((char, index) => {
      if (
        char === char.toUpperCase() &&
        index > 0 &&
        !(char === "I" && characters[index - 1] === "A") &&
        //RescAlert
        !(char === "A" && characters[index - 1] === "c") &&
        //AWARE
        !(char === "W" && characters[index - 1] === "A") &&
        !(char === "A" && characters[index - 1] === "W") &&
        !(char === "R" && characters[index - 1] === "A") &&
        !(char === "E" && characters[index - 1] === "R")
      ) {
        result.push(" ");
      }
      result.push(char);
    });

    str = result.join("");
    str = str.replace(" _ ", "_").replace("Siren / Giant", "Siren/Giant");
    return str;
  };

  return (
    <div style={{ userSelect: "none", marginRight: 30 }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <button
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            backgroundColor: theme.primary,
            width: 115,
            height: 31,
            borderRadius: 5,
          }}
          onClick={onBack}
        >
          <div style={{ display: "flex", alignItems: "center", marginLeft: 5 }}>
            <IconContext.Provider value={{ color: theme.fontColor, size: 20 }}>
              <FaChevronLeft />
            </IconContext.Provider>
          </div>
          <div style={{ marginRight: 5, fontSize: 13, fontWeight: "bold" }}>
            {tab == 0 ? "Return" : "Back"}
          </div>
        </button>
        <button
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end",
            backgroundColor: theme.primary,
            width: 115,
            height: 31,
            borderRadius: 5,
          }}
          onClick={onNext}
        >
          <div style={{ marginLeft: 5, fontSize: 13, fontWeight: "bold" }}>
            {tab == 3 ? "Submit" : "Next"}
          </div>
          <div
            style={{ display: "flex", alignItems: "center", marginRight: 5 }}
          >
            <IconContext.Provider value={{ color: theme.fontColor, size: 20 }}>
              <FaChevronRight />
            </IconContext.Provider>
          </div>
        </button>
      </div>
      <div style={{ marginTop: 15 }}>
        <TabsHeader
          currentTabs={tab}
          onTabChange={(newTab) => setTab(newTab)}
          options={[
            { option: "Role Details" },
            { option: "Permissions" },
            { option: "Dashboard Edit" },
            { option: "Dashboard View" },
          ]}
        />
      </div>
      <div
        style={{
          display: "flex",
          backgroundColor: theme.base,
          alignItems: "center",
          justifyContent: "center",
          marginTop: 17,
          marginBottom: 20,
        }}
      >
        {renderTab()}
      </div>
      {result && (
        <div
          style={{
            color: result.type === "success" ? "green" : "red",
            fontSize: 30,
            fontWeight: "bold",
          }}
        >
          {result.message}
        </div>
      )}
    </div>
  );
}
