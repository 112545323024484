import { ColorContext } from "App";
import React, { useContext } from "react";
import {
  BsChevronBarLeft,
  BsChevronBarRight,
  BsChevronLeft,
  BsChevronRight,
} from "react-icons/bs";
import { PiGearSix } from "react-icons/pi";

const PageIndex = ({ pageIndex, setPageIndex, maxPages, width = 250 }) => {
  const generatePageNumbers = () => {
    const pages = []; // Padding entries at the beginning
    for (
      let i = Math.max(1, pageIndex - 2);
      i <= Math.min(Math.ceil(maxPages), pageIndex + 2);
      i++
    ) {
      pages.push(i);
    }
    if (pages.length < 5) {
      const diff = 5 - pages.length;
      if (pageIndex > 3) {
        for (let i = 1; i <= diff; i++) {
          pages.push(pages[pages.length - 1] + i);
        }
      } else {
        for (let i = 1; i <= diff; i++) {
          pages.unshift(pages[0] - i);
        }
      }
    }
    return pages;
  };

  const theme = useContext(ColorContext);

  return (
    <div
      style={{
        userSelect: "none",
        display: "flex",
        justifyContent: "center",
        gap: 10,
        marginTop: 20,
        color: theme.fontColor,
      }}
    >
      <div
        style={{
          opacity: pageIndex > 1 ? 1 : 0.2,
          marginRight: 20,
        }}
      >
        <button
          onClick={() => {
            if (pageIndex > 1) setPageIndex(1);
          }}
        >
          <BsChevronBarLeft />
        </button>
        <button
          onClick={() => {
            if (pageIndex > 1) setPageIndex(pageIndex - 1);
          }}
        >
          <BsChevronLeft />
        </button>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: 20,
          justifyContent: "center",
          minWidth: width - 50,
        }}
      >
        {generatePageNumbers().map((page) => (
          <div
            key={page}
            style={{
              textAlign: "center",
              cursor: "pointer",
              opacity:
                page > 0 && page <= Math.ceil(maxPages)
                  ? page === pageIndex
                    ? 1
                    : 0.5
                  : 0,
              fontWeight: page === pageIndex ? "bold" : "normal",
            }}
            onClick={() =>
              page > 0 && page <= Math.ceil(maxPages) && setPageIndex(page)
            }
          >
            {page}
          </div>
        ))}
      </div>

      <div
        style={{
          opacity: pageIndex < Math.ceil(maxPages) ? 1 : 0.2,
          marginLeft: 20,
        }}
      >
        <button
          onClick={() => {
            if (pageIndex < Math.ceil(maxPages)) setPageIndex(pageIndex + 1);
          }}
        >
          <BsChevronRight />
        </button>
        <button
          onClick={() => {
            if (pageIndex < Math.ceil(maxPages))
              setPageIndex(Math.floor(Math.ceil(maxPages)));
          }}
        >
          <BsChevronBarRight />
        </button>
      </div>
    </div>
  );
};

export default PageIndex;
