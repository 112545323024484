import Cookies from 'js-cookie';
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';

import * as api from "../apis/FusionAPI";
import { LicenseInformation } from './System/LicenseInformation';
import { ColorContext } from 'App';
import { clearCookies } from 'utils/cookieUtils';

export default function ActivateAccount({
	setCookiesAgreed,
	setPrivacyAgreed,
}) {
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	
	const [initPassword, setInitPassword] = useState(null);
	const [password, setPassword] = useState(null);
	const [confirmPassword, setConfirmPassword] = useState(null);
	const [check, setCheck] = useState(true);
	const [error, setError] = useState("");
	const [currentScreen, setCurrentScreen] = useState('password');
	const [licenseSuccess, setLicenseSuccess] = useState(false);

	
	const code = searchParams.get("code");
  const theme = useContext(ColorContext);

	useEffect(() => {
		(async () => {
			clearCookies();
			let verified;
			await api.activate_checkVerification(code)
				.then((res) => {
					verified = res.data.message;
					setInitPassword(res.data.password);
				})
				.catch((e) => {
					
					verified = 'User already validated';
				});
			

			if (verified === 'User already validated') {
				
				goToLogin();
			}
		})();
	}, [searchParams]);

	const onSubmit = async () => {
		setCheck(password && password === confirmPassword);

		if (password === confirmPassword) {
			const code = searchParams.get("code");
			await api.activate_setPassword(code, password)
				.then((res) => {
					
					if (res.status === 200) {
						setInitPassword(false);
						// setCurrentScreen('activate');
						onActivate();
						// setCurrentScreen('license');
					} else {
						setError("Something went wrong");
					}
				})
				.catch((e) => {
					setError(e.message);
				});
		}
	}

	const onActivate = async () => {
		
		const code = searchParams.get("code");
		await api.activate_activateAccount(code)
			.then((res) => {
				
				if (res.status === 200) {
					// goToLogin();
					if (res.data.accessLevel === undefined) {
						
					}
					else if (res.data.accessLevel === 13) {
						setCurrentScreen('license');
					}
					else if (res.data.accessLevel !== 13){
						setCurrentScreen('skipLicense');
					}
				} else {
					setError("Something went wrong");
				}
			})
			.catch((e) => {
				setError(e.message);
			});
	}
	
	const onLicenseSubmit = () => {
		
		setLicenseSuccess(true);

	}

	const goToLogin = async () => {
		Cookies.remove("accessToken");
		Cookies.remove("refreshToken");
		if (Cookies.get("rememberMe") === "false") Cookies.remove("email");

		setCookiesAgreed(true);
		setPrivacyAgreed(true);
		navigate('/');
	}

	return (
		<div
			style={{
				color: theme.fontColor,
			}}
		>
			{
				currentScreen === 'password' && (
					<div
						style={{
							width: 320,
							height: 220,
							backgroundColor: theme.base,
							padding: 5,
							paddingLeft: 15,
							marginTop: 22
						}}
					>
						<div
							style={{
								width: 251,
								height: 49,
								marginTop: 22
							}}
						>
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									color: check ? 'white' : 'red'
								}}
							>
								<div>Password</div>
								{!check && !password &&
									<div style={{ marginLeft: 15 }}>* Must enter password</div>
								}
							</div>
							<input
								style={{
									width: 251,
									height: 31,
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'space-between',
									alignItems: 'center',
									paddingLeft: 10,
									paddingRight: 10,
									backgroundColor: theme.primary,
									fontSize: 13,
									borderRadius: 10,
									border: `1px solid grey`,
								}}
								type='password'
								onChange={(value) => setPassword(value.target.value)}
								value={password}
							/>
						</div>
						<div
							style={{
								width: 251,
								height: 49,
								marginTop: 22
							}}
						>
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									color: check ? 'white' : 'red',
									width: 500,
								}}
							>
								<div>Confirm Password</div>
								{!check &&
									<div style={{ marginLeft: 15 }}>* Must match password</div>
								}
							</div>
							<input
								style={{
									width: 251,
									height: 31,
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'space-between',
									alignItems: 'center',
									paddingLeft: 10,
									paddingRight: 10,
									backgroundColor: theme.primary,
									fontSize: 13,
									borderRadius: 10,
									border: `1px solid grey`,
								}}
								type='password'
								onChange={(value) => setConfirmPassword(value.target.value)}
								value={confirmPassword}
							/>
						</div>
						<button
							style={{
								width: 100,
								height: 35,
								backgroundColor: theme.primary,
								borderRadius: 15,
								marginTop: 22
							}}
							onClick={onSubmit}
						>
							Set Password
						</button>
					</div>
				)
			}
			{/* {currentScreen === 'activate' &&
				(
					<div
						style={{
							width: 320,
							height: 110,
							backgroundColor: theme.base,
							padding: 5,
							paddingLeft: 15,
							marginTop: 22,
							fontSize: 30,
						}}
					>
						<div>
							Activate Account
						</div>
						<button
							style={{
								width: 100,
								height: 35,
								backgroundColor: theme.primary,
								borderRadius: 15,
								marginTop: 22,
								fontSize: 15,
							}}
							onClick={onActivate}
						>
							Activate
						</button>
					</div>
				)  
			} */}
			{currentScreen === "license" && !licenseSuccess && <div style={{width: 500}}>
					<LicenseInformation inline activationCode={code} callback={onLicenseSubmit}/>
				</div>
			}
			{currentScreen === "license" && licenseSuccess && 
					<div
						style={{
							width: 500,
							backgroundColor: theme.base,
							padding: 5,
							paddingLeft: 15,
							marginTop: 22
						}}
					>
					License Activation Successful!
					<div style={{background:theme.primaryHighlight, padding: 5, marginTop: 10, borderRadius: 5, width:'max-content'}} onClick={() => goToLogin()}>
						Login
					</div>
				</div>
			}
			
			{currentScreen === "skipLicense" && 
					<div
						style={{
							width: 500,
							backgroundColor: theme.base,
							padding: 5,
							paddingLeft: 15,
							marginTop: 22
						}}
					>
					Activation Successful!
					<div style={{background:theme.primaryHighlight, padding: 5, marginTop: 10, borderRadius: 5, width:'max-content'}} onClick={() => goToLogin()}>
						Login
					</div>
				</div>
			}

			
			{error &&
				<div
					style={{
						color: 'red',
						fontSize: 30,
					}}
				>
					{"Error: " + error}
				</div>
			}
		</div >
	)
}
