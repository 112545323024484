/**
 * Tests device permissions for each type/subtype starting at the input object recursively.
 * @param {Object} deviceTypesObject DeviceContext.deviceTypes or sub object that will be recursively looked through.
 * @param {String} permission The text before the device type in the permission, i.e. 'Create-Edit', 'View', 'Delete', etc.
 * @param {Function} testPermissionFunction The function used to test the permission. Takes a string that is the permission. Must return true or false.
 * @param {Int32} initialDepth ADVANCED: Not required unless you are trying to bypass certain depths.
 * @returns True if ALL permissions are true.
 */
export function TestDevicePermsAll(
  deviceTypesObject,
  permission,
  testPermissionCallback,
  initialDepth = 0
) {
  const deviceStrs = BuildStrings(deviceTypesObject, initialDepth).map(
    (string) => `${permission}${string}Connections`
  );

  return !deviceStrs
    .map((perm) => testPermissionCallback(perm))
    .includes(false);
}

/**
 * Tests device permissions for each type/subtype starting at the input object recursively.
 * @param {Object} deviceTypesObject DeviceContext.deviceTypes or sub object that will be recursively looked through. Note: Use DeviceContext.deviceTypes unless you know what you're doing.
 * @param {String} permission The text before the device type in the permission, i.e. 'Create-Edit', 'View', 'Delete', etc.
 * @param {Function} testPermissionFunction The function used to test the permission. Takes a string that is the permission. Must return true or false.
 * @param {Int32} initialDepth ADVANCED: Not required unless you are trying to bypass certain depths.
 * @returns {Boolean} True if at least one permission is true
 */
export function TestDevicePermsAny(
  deviceTypesObject,
  permission,
  testPermissionCallback,
  initialDepth = 0
) {

  const deviceStrs = BuildStrings(deviceTypesObject, initialDepth).map(
    (string) => `${permission}${string}Connections`
  );

  return deviceStrs
    .map((perm) => {
      const val = testPermissionCallback(perm);
      return val;
    })
    .includes(true);
}

function BuildStrings(obj, depth) {

  if (Object.keys(obj).length < 1 || Object.keys(obj).includes("validDevices"))
    return [];

  let strs = [];

  Object.keys(obj).forEach((key) => {
    const children = BuildStrings(obj[key], depth + 1);

    if (children.length < 1) {
      strs.push((depth > 0 ? "_" : "") + key);
    } else {
      children.forEach((child) => {
        strs.push(key + child);
      });
    }
  });

  return strs.map((str) => str.replaceAll(" ", "").replaceAll("/", "-"));
}
