import NodeStatus from "components/NodeStatus";
import React from "react";
import { extractDateTimeFromUrl } from "utils/dateUtils";

export default function DeviceMapDataView({ device }) {
  let deviceType = device.system_type + "_" + device.device_type;

  switch (deviceType.toLowerCase()) {
    case "aware_flood":
      return <RenderAwareMapDeviceData device={device} />;
    default:
      return <RenderRescAlertMapDeviceData device={device} />;
  }
}

function RenderRescAlertMapDeviceData({ device }) {
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignContent: "center",
          gap: 10,
          marginBottom: 10,
        }}
      >
        <h2>
          {(device.device_type !== "Manual Road Closure"
            ? device.static_id + " "
            : "") + device.alias}
        </h2>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignContent: "center",
            gap: 10,
            alignItems: "center",
          }}
        >
          <b>Status:</b> <NodeStatus device={device} />
        </div>
        <b>{new Date(device.data[0].date_created).toLocaleString()}</b>
        <div>
          <b>Comments:</b>{" "}
          {device.data[0].comments || <i>No comments available.</i>}
        </div>
      </div>
    </>
  );
}

// SYSTEM SPECIFIC DEVICE RENDERING ON DEVICES MAP
function RenderAwareMapDeviceData({ device }) {
  return (
    <>
      <h2>
        {(device.device_type !== "Manual Road Closure"
          ? device.static_id + " "
          : "") + device.alias}
      </h2>
      {device?.data[0] && (
        <div>
          {new Date(device?.data[0].ts).toLocaleDateString()},
          {new Date(device?.data[0].ts).toLocaleTimeString()}
        </div>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignContent: "center",
          gap: 10,
          alignItems: "center",
          marginBottom: 10,
          marginTop: 5,
        }}
      >
        <b>Status:</b> <NodeStatus device={device} />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginBottom: 10,
          justifyContent: "space-between",
          gap: 20,
          textWrap: "none",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            textAlign: "left",
            textWrap: "none",
            gap: 5,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              whiteSpace: "nowrap",
            }}
          >
            <div
              style={{
                width: 25,
                height: 10,
                background: "#FFFF00",
                marginRight: 5,
                marginTop: 2,
              }}
            ></div>
            Actual Depth:{" "}
            {device?.data[0].depth1 +
              device?.water_level_adj +
              " in/" +
              ((device?.data[0].depth1 + device?.water_level_adj) / 12).toFixed(
                3
              ) +
              " ft"}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              whiteSpace: "nowrap",
            }}
          >
            <div
              style={{
                width: 25,
                height: 10,
                background: "#0075ff",
                marginRight: 5,
                marginTop: 2,
              }}
            ></div>
            Depth:{" "}
            {device?.data[0].depth1 +
              " in/" +
              (device?.data[0].depth1 / 12).toFixed(3) +
              " ft"}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              whiteSpace: "nowrap",
            }}
          >
            <div
              style={{
                width: 25,
                height: 10,
                background: "#62f671",
                marginRight: 5,
                marginTop: 2,
              }}
            ></div>
            Temperature:{" "}
            {device?.data[0].h2oTemp +
              "°C/" +
              ((device?.data[0].h2oTemp * 9) / 5 + 32).toFixed(2) +
              " °F"}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              whiteSpace: "nowrap",
            }}
          >
            <div
              style={{
                width: 25,
                height: 10,
                background: "#7f00ff",
                marginRight: 5,
                marginTop: 2,
              }}
            ></div>
            Pressure:
            {device?.data[0].baro +
              " mBar/" +
              (device?.data[0].baro * 0.02952998).toFixed(3) +
              " inHg"}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              whiteSpace: "nowrap",
            }}
          >
            <div
              style={{
                width: 25,
                height: 10,
                background: "#afb943",
                marginRight: 5,
                marginTop: 2,
              }}
            ></div>
            Battery: {device?.data[0].battery}V{" "}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              whiteSpace: "nowrap",
            }}
          >
            <div
              style={{
                width: 25,
                height: 10,
                background: "red",
                marginRight: 5,
                marginTop: 2,
              }}
            ></div>
            Alert Indicated:{" "}
            {device?.data[0].ffi1 ||
            device?.data[0].drInd ||
            device?.data[0].depthInd
              ? "True"
              : "False"}
          </div>
        </div>
        {device?.data[0].img_link_url && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <img
              src={`${device?.data[0].img_link_url}`}
              alt="Device"
              style={{ width: "200px", height: "150px" }}
            />
            <div>
              Image Timestamp:{" \n"}
              {extractDateTimeFromUrl(device?.data[0].img_link_url)}
            </div>
          </div>
        )}
      </div>
    </>
  );
}
