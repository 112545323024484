import React, { useContext, useEffect, useState } from "react";
import ReactGridLayout from "react-grid-layout";

import "./Dashboard.css";
import Cookies from "js-cookie";
import * as api from "../apis/FusionAPI";
import Widget from "components/Widget";
import AddWidgetComponent from "components/AddWidgetComponent";
import Popup from "reactjs-popup";
import WidgetSelector from "components/WidgetSelector";
import zIndex from "@mui/material/styles/zIndex";
import GetWidgetData from "data/widgetData";
import { usePerms } from "hooks/usePerms";
import { ColorContext, LayoutSizeContext } from "App";

let userList = [];

export default function Dashboard({
  dimensions,
  sidebarOpen,
  search,
  definedWidgetData,
  accessLevel,
}) {
  const [list, setList] = useState(null);
  const [choiceList, setChoiceList] = useState(null);

  //top widgets
  const [weatherAlertCount, setWeatherAlertCount] = useState(0);
  const [fusionAlertCount, setFusionAlertCount] = useState(0);
  const [totalAlarmsCount, setTotalAlarmsCount] = useState(0);
  const [canMoveWidgets, setCanMoveWidgets] = useState(false);
  const [canEditWidgets, setCanEditWidgets] = useState(false);

  const theme = useContext(ColorContext);
  //widget selector
  const [widgetSelectorOpen, setWidgetSelectorOpen] = useState(false);
  const [widgetSizeSelectorOpen, setWidgetSizeSelectorOpen] = useState(false);

  const perms = usePerms();
  useEffect(() => {
    if (!Cookies.get("city")) {
      Cookies.set("city", "");
    }
    if (!Cookies.get("state")) {
      Cookies.set("state", "");
    }

    if (!Cookies.get("latitude")) {
      Cookies.set("latitude", 0);
    }
    if (!Cookies.get("longitude")) {
      Cookies.set("longitude", 0);
    }
  }, []);

  useEffect(() => {
    setCanMoveWidgets(
      perms.testPermission([8, 9, 10, 11, 12, 13], ["WidgetsAreMovable"])
    );
    setCanEditWidgets(
      perms.testPermission([8, 9, 10, 11, 12, 13], ["WidgetsAreEditable"])
    );
  }, [perms.sData]);

  useEffect(() => {
    api
      .getAllActiveAlerts()
      .then((res) => {
        const weatherAlerts = res.data.alerts
          .filter((filter) => filter.provider == "Custom Weather")
          .map((item) => item);

        const fusionAlerts = res.data.alerts
          .filter((filter) => filter.provider == "Fusion")
          .map((item) => item);

        setWeatherAlertCount(weatherAlerts.length);
        setFusionAlertCount(fusionAlerts.length);
      })
      .catch((e) => console.error(e));

    api
      .getPendingAlarms()
      .then((res) => {
        if (!res?.data?.data) {
          return;
        }

        setTotalAlarmsCount(
          res.data.data.filter((filter) => filter.state == 1).length
        );
      })
      .catch((e) => console.error("Error after pending alarms:", e));

    api
      .getUserPerms()
      .then((res) => {
        const role = parseInt(res.data.roleId);
        const widgetData = GetWidgetData();

        if (role > -1) {
          const filteredWidgets = Object.values(widgetData)
            .filter((f) =>
              perms.testPermission(
                [8, 9, 10, 11, 12, 13],
                f.permissions,
                JSON.parse(res.data.perms)
              )
            )
            .map((v) => v);
          setChoiceList(filteredWidgets);
        } else {
          setChoiceList(
            Object.keys(widgetData).map((key) => {
              return {
                display: true,
                w: 1,
                h: 1,
                isDraggable: true,
                locked: false,
                x: 0,
                y: 0,
                id: key,
              };
            })
          );
        }
      })
      .catch((e) => console.error(e));

    //get user's widgets
    api.getUserWidgetData().then((res) => {
      let temp = JSON.parse(res.data.widgets);

      setList([...temp]);

      userList = [...temp];
    });
  }, []);

  const rowHeight = 519;
  const numColumns = 3;

  const layout = useContext(LayoutSizeContext);

  const onListChange = (newList) => {
    setList(newList);

    //update user settings
    newList = newList.map((item) => {
      if (!item.w) item = { ...item, w: 1 };
      if (!item.h) item = { ...item, h: 1 };
      return item;
    });

    api
      .updateUserWidgetLayout(newList)
      .then((res) => {
        return true;
      })
      .catch((e) => {
        return false;
      });
  };

  const openWidgetSelector = (value) => {
    setWidgetSelectorOpen(value);
  };

  const addWidget = (widget) => {
    return onListChange([...list, widget]);
  };

  const removeWidget = (toRemove) => {
    onListChange([
      ...list.filter((filter, index) => {
        return index !== toRemove;
      }),
    ]);
  };

  const HeaderWidget = ({ innerComponent }) => {
    return (
      <div
        style={{
          backgroundColor: "red",
          width: 382,
          height: 63.19,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: 25,
          marginRight: 15,
        }}
      >
        {innerComponent}
      </div>
    );
  };

  return (
    <div
      style={{
        userSelect: "none",
        display: "flex",
        // minHeight: "100%",
        // margin: 10,
        overflow: "auto",
        // overflowX: "hidden",
        // width: "500px",
        // width: "98%",
        // background: "rgba(255,0,0,.2)",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          // minWidth: rowHeight,
          // width: "100%",
        }}
      >
        <div>
          {/* Header */}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "baseline",
            }}
          >
            Welcome back, {Cookies.get("username")}, glad to see you again! Ask
            me anything.
          </div>

          {/* Header widgets */}
          {perms.testPermission(
            [8, 9, 10, 11, 12, 13],
            ["AnalyticHeaders"]
          ) && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: 15,
                justifyContent: "space-between",
                // width: "100%"
                // width: layout.width - 20,
                width: "100%",
                gap: 15,
                // maxWidth: dimensions.width - (sidebarOpen ? 295 : 50),
              }}
            >
              <TotalWeatherAlerts weatherAlertCount={weatherAlertCount} />
              <TotalFusionAlerts fusionAlertsCount={fusionAlertCount} />
              <AIAlarms />
              <PendingAlarmsHeader totalAlarmsCount={totalAlarmsCount} />
            </div>
          )}

          {/* Widgets */}
          <div
            style={{
              marginBottom: 100,
              // background: "rgba(0,220,0)",
              // width: "100%",
              width: layout.width,
              // background: "rgba(255,0,0,.2)",
              // width: "99%"
            }}
          >
            {/* test */}
            <ReactGridLayout
              // className="layout"
              cols={numColumns}
              rowHeight={layout.widgetHeight}
              // colWidth={layoutWidth / numColumns - 10}
              width={layout.width + 20}
              onDragStop={(e, last, curr) => {
                //update list with new positions
                const temp = [...list];
                for (let i = 0; i < e.length; i++) {
                  if (e[i].i !== "a")
                    temp[i] = { ...temp[i], x: e[i].x, y: e[i].y };
                }

                onListChange(temp);
              }}
              draggableHandle='.handle'
              // containerPadding={[15, 15]}
              style={{
                alignItems: "start",
                marginLeft: "-10px",
              }}
              // margin={[0, 0]}
            >
              {list &&
                list.map((item, index) => {
                  if (
                    !definedWidgetData[
                      item.id
                    ].sidebarData.accessLevel.includes(accessLevel)
                  )
                    return;

                  if (item?.displayed === false || item?.active === false)
                    return;

                  if (
                    !definedWidgetData[item.id].name
                      .replace(" ", "")
                      .toLowerCase()
                      .includes(search.replace(" ", "").toLowerCase())
                  )
                    return;

                  if (search !== "") {
                    return (
                      <div
                        key={index}
                        data-grid={{
                          ...definedWidgetData[item.id],
                          ...item,
                          i: index,
                          isDraggable: canMoveWidgets
                            ? item.isDraggable
                            : false,
                          x: 0,
                          y: 0,
                        }}
                      >
                        <Widget
                          key={0}
                          item={{
                            ...definedWidgetData[item.id],
                            ...item,
                          }}
                          index={index}
                          expandable
                          list={list}
                          onListChange={onListChange}
                        />
                      </div>
                    );
                  } else {
                    return (
                      <div
                        key={index}
                        data-grid={{
                          ...definedWidgetData[item.id],
                          ...item,
                          i: index,
                          isDraggable: canMoveWidgets
                            ? item.isDraggable
                            : false,
                        }}
                      >
                        <Widget
                          key={0}
                          item={{ ...definedWidgetData[item.id], ...item }}
                          index={index}
                          expandable
                          list={list}
                          onListChange={onListChange}
                          removeWidget={removeWidget}
                        />
                      </div>
                    );
                  }
                })}
              {canEditWidgets && (
                <div
                  key='a'
                  style={{
                    width: rowHeight,
                    height: rowHeight,
                    backgroundColor: theme.base,
                  }}
                  data-grid={{
                    i: "default",
                    displayed: true,
                    isDraggable: false,
                    locked: true,
                    static: false,
                    x: 0,
                    y: Infinity,
                    w: 1,
                    h: 1,
                  }}
                >
                  <AddWidgetComponent
                    openWidgetSelector={() => openWidgetSelector(true)}
                  />
                </div>
              )}
            </ReactGridLayout>
          </div>
        </div>
      </div>
      {choiceList && (
        <Popup
          open={widgetSelectorOpen}
          closeOnDocumentClick={false}
          onClose={() => openWidgetSelector(false)}
        >
          <WidgetSelector
            closeSelector={() => openWidgetSelector(false)}
            widgetData={definedWidgetData}
            userList={
              typeof choiceList == "string"
                ? JSON.parse(choiceList)
                : choiceList
            }
            onAddWidget={addWidget}
          />
        </Popup>
      )}
    </div>
  );
}

function TotalWeatherAlerts({ weatherAlertCount }) {
  const theme = useContext(ColorContext);
  return (
    <div
      style={{
        backgroundColor: theme.primaryShadow,
        // width: 310,
        width: "100%",
        height: 43,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
      }}
    >
      <div
        style={{
          fontSize: 9.4,
          marginLeft: 5,
        }}
      >
        Weather Alerts
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          // width: 310 - 10,
          marginLeft: 5,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div
            style={{
              fontSize: 14.1,
            }}
          >
            {weatherAlertCount}
          </div>
          {/* <div
            style={{
              marginLeft: 10,
              fontSize: 10.97,
              color: "green",
            }}
          >
            {`+${alertDiff}%`}
          </div> */}
        </div>
        {/* <div
          style={{
            fontSize: 10.97,
          }}
        >
          Increase over past 3 days
        </div> */}
      </div>
    </div>
  );
}

function TotalFusionAlerts({ fusionAlertsCount }) {
  const theme = useContext(ColorContext);
  return (
    <div
      style={{
        backgroundColor: theme.primaryShadow,
        // width: 310,
        width: "100%",
        height: 43,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
      }}
    >
      <div
        style={{
          fontSize: 9.4,
          marginLeft: 5,
        }}
      >
        Fusion Alerts
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          // width: 310 - 10,
          marginLeft: 5,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div
            style={{
              fontSize: 14.1,
            }}
          >
            {fusionAlertsCount}
          </div>
          {/* {todaysAlertDiff > -Infinity && todaysAlertDiff < Infinity && (
            <div
              style={{
                marginLeft: 10,
                fontSize: 10.97,
                color: todaysAlertDiff >= 0 ? "red" : "green",
              }}
            >
              {todaysAlertDiff < 0
                ? `${todaysAlertDiff}%`
                : `+${todaysAlertDiff}%`}
            </div>
          )} */}
        </div>
        {/* {todaysAlertDiff > -Infinity && todaysAlertDiff < Infinity && (
          <div
            style={{
              fontSize: 10.97,
            }}
          >
            {todaysAlertDiff >= 0
              ? "Increase from the past 3 days"
              : "Decrease from the past 3 days"}
          </div>
        )} */}
      </div>
    </div>
  );
}

function AIAlarms() {
  const theme = useContext(ColorContext);
  return (
    <div
      style={{
        backgroundColor: theme.primaryShadow,
        // width: 310,
        width: "100%",
        height: 43,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
      }}
    >
      <div
        style={{
          fontSize: 9.4,
          marginLeft: 5,
        }}
      >
        AI Alerts
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          // width: 310 - 10,
          marginLeft: 5,
        }}
      >
        Coming Soon
      </div>
    </div>
  );
}

function PendingAlarmsHeader({ totalAlarmsCount }) {
  const theme = useContext(ColorContext);
  return (
    <div
      style={{
        backgroundColor: theme.primaryShadow,
        // width: 310,
        width: "100%",
        height: 43,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
      }}
    >
      <div
        style={{
          fontSize: 9.4,
          marginLeft: 5,
        }}
      >
        Pending Alarms
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          // width: 310 - 10,
          marginLeft: 5,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div
            style={{
              fontSize: 14.1,
            }}
          >
            {totalAlarmsCount}
          </div>
        </div>
      </div>
    </div>
  );
}
