import React, { useEffect, useState } from "react";
import { AlarmsContentWrapper, AlarmContentRow } from "components";
import * as api from "apis/FusionAPI";
import { useAlarms } from "hooks";

export default function PendingAlarms() {
  const alarms = useAlarms(true);

  const [selectedAlarms, setSelectedAlarms] = useState([]);

  // Event Handlers
  const onAcknowledge = (alarmId) => {
    // Acknowledge
    api
      .acknowledgeAlarm(alarmId)
      .then((res) => {
        // Then Silence
        if (res.status === 200) api.silenceAlarm(alarmId);
      })
      .catch((err) => {
        console.error("Error when acknowledging", alarmId, ":", err);
      });
  };

  const onSilence = (alarmId) => {
    // Silence
    api.silenceAlarm(alarmId).catch((err) => {
      console.error("Error when silencing", alarmId, ":", err);
    });
  };

  const onSelectAlarm = (alarmId) => {
    if (!selectedAlarms.includes(alarmId))
      setSelectedAlarms([...selectedAlarms, alarmId]);
    else setSelectedAlarms(selectedAlarms.filter((alarm) => alarm !== alarmId));
  };

  const onSelectAll = () => {
    if (selectedAlarms.length !== alarms.alarms.length) {
      const alarmIds = alarms.alarms.map((alarm) => alarm.id);
      setSelectedAlarms(alarmIds);
    } else setSelectedAlarms([]);
  };

  const onAcknowledgeSelected = () => {
    onAcknowledge(selectedAlarms);
  };

  const onSilenceSelected = () => {
    onSilence(selectedAlarms);
  };

  return (
    <AlarmsContentWrapper
      title='Pending Alarms'
      pageIndex={alarms.page}
      maxPageIndex={Math.floor((alarms.totalCount - 1) / alarms.limit)} //use floor because it's an index
      headerFields={[
        "Current State",
        "Source",
        "Device",
        "Type",
        "Activity",
        "Status",
        "",
      ]}
      onPageChanged={(_page) => {
        setSelectedAlarms([]); //clear selected alarms
        alarms.setPage(_page);
      }}
      onLimitChanged={(_limit) => {
        alarms.setLimit(_limit);
      }}
      onSearchChanged={(_search) => {
        alarms.setSearch(_search);
      }}
      silenceControlsEnabled
      allSelected={selectedAlarms.length === alarms.alarms?.length}
      onSelectAll={onSelectAll}
      onAcknowledge={onAcknowledgeSelected}
      onSilence={onSilenceSelected}
    >
      {alarms.alarms
        ?.filter((alarm, index) => index < alarms.limit)
        .map((alarm, index) => (
          <AlarmContentRow
            key={alarm.id}
            alarm={{ ...alarm, index: index }}
            controlsEnabled
            selected={selectedAlarms.includes(alarm.id)}
            silenced={alarm.silenced}
            onSelect={() => onSelectAlarm(alarm.id)}
            onAcknowledge={() => onAcknowledge(alarm.id)}
            onSilence={() => onSilence(alarm.id)}
          />
        ))}
    </AlarmsContentWrapper>
  );
}
