import React, { useContext, useEffect, useState } from "react";
import { IconContext } from "react-icons";
import { TbCheck, TbLetterX } from "react-icons/tb";
import { GetPermListForRole } from "data/permData";
import ToggleButton from "pages/System/DeviceSettings/ToggleButton";
import { usePerms } from "hooks/usePerms";
import { ColorContext } from "App";

const PermissionsTab = ({
  formData,
  setFormData,
  checks,
  widgetData,
  permList,
  edit = true,
  roleid = 0,
}) => {
  const perms = usePerms();

  const [search, setSearch] = useState("");

  const enableLinkToggle = (array, index) => {
    const temp = [...array];

    permList
      .filter((item) => {
        return item?.enableLinked > 0;
      })
      .forEach((item) => {
        if (index > item.index && index <= item.index + item.enableLinked)
          temp[item.index] = true;
      });

    return temp;
  };

  const theme = useContext(ColorContext);
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          height: 31,
          marginLeft: 30,
          marginTop: 18,
        }}
      >
        <div style={{ fontSize: 19, fontWeight: "bold" }}>Permissions</div>
        <div>
          <div
            style={{
              width: 194,
              height: 39,
              backgroundColor: theme.primary,
              borderRadius: 10,
              paddingLeft: 15,
              paddingRight: 15,
              marginRight: 40,
              marginTop: 17,
            }}
          >
            <input
              placeholder="Search..."
              style={{ width: 194, height: 39 }}
              onChange={(value) => setSearch(value.target.value)}
              value={search}
            />
          </div>
        </div>
      </div>
      <div
        className="scrollbar-hidden"
        style={{
          height: window.innerHeight - 350,
          marginLeft: 30,
          marginTop: 21,
          overflowY: "auto",
          overflowX: "clip",
        }}
      >
        {permList &&
          permList
            .filter((filter, index) => {
              if (filter.linked === 0) {
                return perms.testPermission(
                  [8, 9, 10, 11, 12, 13],
                  [
                    filter.name
                      .replace(/\s/g, "")
                      .replace(/\//g, "-")
                      .replace(/:/g, "_"),
                  ]
                );
              } else {
                for (let i = 1; i <= filter.linked; i++) {
                  if (
                    perms.testPermission(
                      [8, 9, 10, 11, 12, 13],
                      [
                        permList[index + i].name
                          .replace(/\s/g, "")
                          .replace(/\//g, "-")
                          .replace(/:/g, "_"),
                      ]
                    )
                  )
                    return true;
                }

                return false;
              }
            })
            .map((item) => {
              let linkEnabled = true;
              for (let i = 0; i < item.linked; i++) {
                if (!formData.permissions[item.index + 1 + i]) {
                  linkEnabled = false;
                  if (formData.permissions[item.index]) {
                    const temp = [...formData.permissions];
                    temp[item.index] = false;
                    setFormData({ ...formData, permissions: [...temp] });
                  }
                }
              }

              if (
                item.linked > 0 &&
                linkEnabled != formData.permissions[item.index]
              ) {
                const temp = [...formData.permissions];
                temp[item.index] = linkEnabled;

                setFormData({ ...formData, permissions: [...temp] });
              }

              if (
                (item.display &&
                  item.display
                    .toLowerCase()
                    .replace(/ /g, "")
                    .includes(search.toLowerCase().replace(/ /g, ""))) ||
                item.name
                  .toLowerCase()
                  .replace(/ /g, "")
                  .includes(search.toLowerCase().replace(/ /g, "")) ||
                item.desc
                  .toLowerCase()
                  .replace(/ /g, "")
                  .includes(search.toLowerCase().replace(/ /g, ""))
              )
                return (
                  <div>
                    {item.linked > 0 && item.index != 0 && (
                      <div style={{ borderBottom: "solid grey 2px" }} />
                    )}
                    <div
                      key={item.index}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        height: 71,
                        fontSize: 16,
                      }}
                    >
                      <div>
                        <div style={{ fontWeight: "bold" }}>
                          {item.display ? item.display : item.name}
                        </div>
                        <div>{item.desc}</div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end",
                          width: 200,
                          height: 71,
                        }}
                      >
                        <ToggleButton
                          isOff={
                            parseInt(roleid) === -1 || parseInt(roleid) === -3
                              ? false
                              : !formData.permissions[item.index]
                          }
                          onClick={() => {
                            if (!edit) return;
                            if (item.linked === 0) {
                              let temp = [...formData.permissions];
                              temp[item.index] = !temp[item.index];

                              if (temp[item.index])
                                temp = [...enableLinkToggle(temp, item.index)];
                              else if (permList[item.index]?.enableLinked > 0) {
                                for (
                                  let x = 1;
                                  x <= permList[item.index].enableLinked;
                                  x++
                                ) {
                                  temp[item.index + x] = false;
                                }
                              }

                              setFormData({
                                ...formData,
                                permissions: [...temp],
                              });
                            } else {
                              let temp = [...formData.permissions];
                              temp[item.index] = !temp[item.index];

                              if (temp[item.index])
                                temp = [...enableLinkToggle(temp, item.index)];
                              else if (permList[item.index]?.enableLinked > 0) {
                                for (
                                  let x = 1;
                                  x <= permList[item.index].enableLinked;
                                  x++
                                ) {
                                  temp[item.index + x] = false;
                                }
                              }

                              for (let i = 0; i < item.linked; i++) {
                                temp[item.index + 1 + i] = temp[item.index];
                              }
                              setFormData({
                                ...formData,
                                permissions: [...temp],
                              });
                            }
                          }}
                        />
                      </div>
                    </div>
                    {item.linked > 0 && (
                      <div style={{ borderBottom: "solid grey 2px" }} />
                    )}
                  </div>
                );
            })}
      </div>
    </div>
  );
};

export default PermissionsTab;
