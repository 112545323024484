import classNames from "classnames";
import React, { useContext, useEffect, useRef, useState } from "react";
import Popup from "reactjs-popup";
import styles from "./EllipsisMenu.module.css";
import "styles/global.css";
import { ColorContext } from "App";

/**
 * Defines a circular ellipsis button and the popup controls for it.
 * @param {{options: {component: ()}[], open: boolean, onOpen: (), onClose: ()}}
 * @example <EllipsisMenu
               options={[
                 {
                   component: (
                     <div onClick={option1Clicked}>Option 1</div>
                   ),
                 },
                 {
                   component: (
                     <div onClick={option2Clicked}>Option 2</div>
                   ),
                 },
               ]}
               open={ellipsisOpen}
               onOpen={() => setEllipsisOpen(true)}
               onClose={() => setEllipsisOpen(false)}
             />
 * @returns React Component
 */
export default function EllipsisMenu({ options, open, onOpen, onClose }) {
  const theme = useContext(ColorContext);

  const popupRef = useRef();

  //open handler
  useEffect(() => {
    if (open) popupRef.current?.open();
    else popupRef.current?.close();
  }, [open]);

  //onclick events
  const close = () => {
    popupRef.current.close();
    onClose();
  };

  //trigger component
  const popupTrigger = () => {
    return (
      <div
        className={classNames("circle-fill", styles["center-ellipsis"])}
        style={{
          "--fill-color": theme.primary,
          "--radius": "15px",
        }}
      >
        ...
      </div>
    );
  };

  if (options && Array.isArray(options) && options.length > 0)
    return (
      <Popup
        ref={popupRef}
        on={"click"}
        trigger={popupTrigger()}
        onOpen={onOpen}
        position={"bottom center"}
        closeOnDocumentClick
      >
        <div className={styles["popup-container"]}>
          {options.map((option, index) => (
            <div key={index} className={styles["popup-option"]}>
              {option?.component}
            </div>
          ))}
          <div
            className={classNames(
              styles["popup-option"],
              styles["popup-button-close"]
            )}
            onClick={close}
          >
            Close
          </div>
        </div>
      </Popup>
    );
}
