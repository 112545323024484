import { ColorContext, DeviceContext } from "App";
import React, { useContext, useEffect, useState } from "react";
import DeviceCustomInput from "./DeviceCustomInput";

const Device = ({ deviceList, setData, data, setError }) => {
  const theme = useContext(ColorContext);
  const deviceContext = useContext(DeviceContext);

  const dataMaps = {
    aware: {
      flood: {
        depth1: { name: "Sensor Depth", units: "Inches", type: "float" },
        sensordepths: { name: "Actual Depths", units: "Inches", type: "float" },
        depthDet1: {
          name: "Depth Threshold 1",
          units: "Inches",
          type: "float",
        },
        depthDet2: {
          name: "Depth Threshold 2",
          units: "Inches",
          type: "float",
        },
        depthDet3: {
          name: "Depth Threshold 3",
          units: "Inches",
          type: "float",
        },
        airTemp: { name: "Air Temperature", units: "°C", type: "float" },
        baro: { name: "Pressure", units: "mBar", type: "float" },
        battery: { name: "Battery", units: "Voltage", type: "float" },
        depthInd: {
          name: "Depth Detection Indicator (Threshold Met)",
          type: "bool",
        },
        drInd: { name: "Drop Rate Indicator (Threshold Met)", type: "bool" },
        dropSDI: {
          name: "Device Drop Count (SDI-12) Tipping bucket",
          units: "Drops",
          type: "float",
        },
        soilSDI: {
          name: "Soil Moisture Reading (SDI-12)",
          units: "ADC (Analog to Digital Converter)",
          type: "float",
        },
        soilTempSDI: {
          name: "Soil Temperature Reading (SDI-12)",
          units: "°C",
          type: "float",
        },
        ffi1: { name: "Flash Flood Indicator", type: "bool" },
        h2oTemp: { name: "Water Temperature", units: "°C", type: "float" },
        rssi: { name: "Signal Strength", units: "dB", type: "float" },
      },
    },
    "rescalert devices": {
      "manual road closure": {
        status: {
          name: "Device Status",
          type: "bool",
          values: {
            true: "Closed",
            false: "Open",
          },
        },
      },
      "technology bridge_flashing lights": {
        status: {
          name: "Device Status",
          type: "bool",
          values: {
            true: "Closed",
            false: "Open",
          },
        },
      },
      "technology bridge_siren/giant voice": {
        status: {
          name: "Device Status",
          type: "bool",
          values: {
            true: "Alerting",
            false: "Idle",
          },
        },
      },
    },
  };

  const [systemType, setSystemType] = useState();
  const [deviceType, setDeviceType] = useState();
  const [deviceSubType, setDeviceSubType] = useState();

  const AWARErangesAndSteps = {
    depth1: { range: [0, 100], step: 1 },
  };

  useEffect(() => {
    if (
      data?.node_id === undefined ||
      data?.deviceType === undefined ||
      data?.keyToCheck === undefined ||
      data?.keyToCompare === undefined ||
      data?.operator === undefined ||
      data?.valueToCompare === undefined ||
      data?.selectedDevice === undefined ||
      data?.inputValueType === undefined
    ) {
      setData({
        node_id: "",
        deviceType: "",
        keyToCheck: "",
        keyToCompare: "",
        operator: "",
        valueToCompare: "",
        selectedDevice: "",
        inputValueType: false,
        error: true,
      });
    }

    //TODO: Spend at least a month making this make sense
    if (data.deviceType) {
      if (data.deviceType.includes("RescAlert Devices")) {
        setSystemType("RescAlert Devices");
        const types = data.deviceType
          .replace("RescAlert Devices ", "")
          .split("_");
        setDeviceType(types[0]);
        setDeviceSubType(types[1]);
      } else {
        const systemType = data.deviceType.split(" ")[0];
        const deviceType = data.deviceType.split(" ")[1].split("_")[0];
        const subType = data.deviceType.split(" ")[1].split("_")[1];

        setSystemType(systemType);
        setDeviceType(deviceType);
        setDeviceSubType(subType);
      }
    }
  }, []);

  const updateData = (item) => {
    if (item.selectedDevice && item.selectedDevice.data) {
      item.selectedDevice.data = [item.selectedDevice.data[0]];
    }
    setData({
      ...data,
      ...item,

      error: verifyData({ ...data, ...item }),
    });
  };

  const verifyData = (data) => {
    let error = false;
    if (
      data.node_id === "" ||
      data.deviceType === "" ||
      data.keyToCheck === "" ||
      (data.keyToCompare === "" && data.valueToCompare === "") ||
      (data.keyToCompare === null && data.valueToCompare === null) ||
      data.operator === "" ||
      data.operator === null
    ) {
      error = true;
      setError({ message: "Action incomplete, check for missing fields." });
    }
    if (!error) {
      setError(null);
    }
    return error;
  };

  const getDeviceTypeString = (device) => {
    return device?.system_type + " " + device?.device_type || "";
  };

  const operators = [
    { operator: "==", types: ["all"] },
    { operator: "!=", types: ["all"] },
    { operator: ">", types: ["float"] },
    { operator: "<", types: ["float"] },
    { operator: ">=", types: ["float"] },
    { operator: "<=", types: ["float"] },
  ];

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div
        style={{
          padding: 20,
          borderRadius: 10,
          margin: 10,
          background: theme.primary,
          display: "flex",
          flexDirection: "row",
          gap: 20,
        }}
      >
        <div>
          System:{" "}
          <select
            style={{
              background: theme.base,
              padding: 5,
            }}
            onChange={(e) => {
              setSystemType(e.target.value);
              setDeviceType("");
              setDeviceSubType("");
              updateData({
                deviceType: "",

                //we have to reset the values beneath it
                node_id: null,
                selectedDevice: null,
                keyToCheck: null,
                valueToCompare: null,
                keyToCompare: null,
                operator: null,
              });
            }}
            value={systemType}
          >
            <option value=''>-Select-</option>
            {Object.keys(deviceContext.deviceTypes).map((type) => {
              return <option value={type}>{type}</option>;
            })}
          </select>
        </div>
        {systemType && (
          <div>
            Device Type:{" "}
            <select
              style={{
                background: theme.base,
                padding: 5,
              }}
              onChange={(e) => {
                setDeviceType(e.target.value);
                setDeviceSubType("");

                if (
                  deviceContext.deviceTypes[systemType][e.target.value] &&
                  (Object.keys(
                    deviceContext.deviceTypes[systemType][e.target.value]
                  ).length === 0 ||
                    Object.keys(
                      deviceContext.deviceTypes[systemType][e.target.value]
                    ).includes("validDevices"))
                )
                  updateData({
                    deviceType: systemType + " " + e.target.value,

                    //we have to reset the values beneath it
                    node_id: null,
                    selectedDevice: null,
                    keyToCheck: null,
                    valueToCompare: null,
                    keyToCompare: null,
                    operator: null,
                  });
                else
                  updateData({
                    deviceType: "",

                    //we have to reset the values beneath it
                    node_id: null,
                    selectedDevice: null,
                    keyToCheck: null,
                    valueToCompare: null,
                    keyToCompare: null,
                    operator: null,
                  });
              }}
              value={deviceType}
            >
              <option value=''>-Select-</option>
              {Object.keys(deviceContext.deviceTypes[systemType]).map(
                (type) => {
                  return <option value={type}>{type}</option>;
                }
              )}
            </select>
          </div>
        )}
        {deviceType &&
          Object.keys(deviceContext.deviceTypes[systemType][deviceType])
            .length > 0 &&
          !Object.keys(
            deviceContext.deviceTypes[systemType][deviceType]
          ).includes("validDevices") && (
            <div>
              Sub Type:{" "}
              <select
                style={{
                  background: theme.base,
                  padding: 5,
                }}
                onChange={(e) => {
                  setDeviceSubType(e.target.value);
                  updateData({
                    deviceType:
                      systemType + " " + deviceType + "_" + e.target.value,

                    //we have to reset the values beneath it
                    node_id: null,
                    selectedDevice: null,
                    keyToCheck: null,
                    valueToCompare: null,
                    keyToCompare: null,
                    operator: null,
                  });
                }}
                value={deviceSubType}
              >
                <option value=''>-Select-</option>
                {Object.keys(
                  deviceContext.deviceTypes[systemType][deviceType]
                ).map((type) => {
                  return <option value={type}>{type}</option>;
                })}
              </select>
            </div>
          )}
        {data.deviceType && (
          <div>
            Device Alias:{" "}
            <select
              style={{
                background: theme.base,
                padding: 5,
              }}
              onChange={(e) => {
                // find device by node_id
                const device = deviceList.find((device) => {
                  return device.node_id.toString() === e.target.value;
                });

                updateData({
                  node_id: e.target.value,
                  selectedDevice: device,

                  //we have to reset the values beneath it
                  keyToCheck: "",
                  valueToCompare: "",
                  keyToCompare: "",
                  operator: "",
                });
              }}
              value={data.node_id}
            >
              <option value=''>-Select-</option>
              {deviceList.map((device) => {
                if (getDeviceTypeString(device) === data.deviceType) {
                  return (
                    <option value={device.node_id}>
                      {(device.device_type !== "Manual Road Closure"
                        ? device.static_id + " "
                        : "") + device.alias}
                    </option>
                  );
                }
              })}
            </select>
          </div>
        )}
      </div>
      {data.selectedDevice && (
        <div
          style={{
            display: "flex",
            padding: 20,
            borderRadius: 10,
            margin: 10,
            marginTop: 0,
            background: theme.primary,
            flexDirection: "row",
            gap: 20,
            alignItems: "center",
          }}
        >
          Value:
          <select
            style={{
              background: theme.base,
              padding: 5,
              width: 200,
            }}
            onChange={(e) => {
              updateData({ keyToCheck: e.target.value });
            }}
            value={data.keyToCheck}
          >
            <option value=''>-Select-</option>
            {data.selectedDevice?.data.length > 0 &&
              Object.keys({
                ...data.selectedDevice?.data[0],
                ...data.selectedDevice?.device_settings,
              }).map((key) => {
                if (
                  dataMaps[data.selectedDevice.system_type.toLowerCase()][
                    data.selectedDevice.device_type.toLowerCase()
                  ][key]
                )
                  return (
                    <option value={key}>
                      {
                        dataMaps[data.selectedDevice.system_type.toLowerCase()][
                          data.selectedDevice.device_type.toLowerCase()
                        ][key].name
                      }
                    </option>
                  );

                return null;
              })}
          </select>
          {data.keyToCheck && (
            <select
              style={{
                background: theme.base,
                padding: 5,
              }}
              onChange={(e) => {
                updateData({ operator: e.target.value });
              }}
              value={data.operator}
            >
              <option value=''>-Select-</option>
              {operators.map((operator) => {
                if (
                  operator.types.includes("all") ||
                  operator.types.includes(
                    dataMaps[data.selectedDevice.system_type.toLowerCase()][
                      data.selectedDevice.device_type.toLowerCase()
                    ][data.keyToCheck].type
                  )
                ) {
                  if (operator.operator === "==")
                    return <option value={operator.operator}>=</option>;
                  return (
                    <option value={operator.operator}>
                      {operator.operator}
                    </option>
                  );
                } else if (data.operator === operator.operator)
                  updateData({ operator: null });
              })}
            </select>
          )}
          {data.operator &&
            (data.inputValueType ? (
              <div>
                <DeviceCustomInput
                  value={data.valueToCompare}
                  onChange={(value) => updateData({ valueToCompare: value })}
                  dataMapItem={
                    dataMaps[data.selectedDevice.system_type.toLowerCase()][
                      data.selectedDevice.device_type.toLowerCase()
                    ][data.keyToCheck]
                  }
                />
              </div>
            ) : (
              <select
                style={{
                  background: theme.base,
                  padding: 5,
                  width: 200,
                }}
                onChange={(e) => {
                  updateData({ keyToCompare: e.target.value });
                }}
                value={data.keyToCompare}
              >
                <option value=''>-Select-</option>
                {Object.keys({
                  ...data.selectedDevice?.data[0],
                  ...data.selectedDevice?.device_settings,
                }).map((key) => {
                  if (
                    dataMaps[data.selectedDevice.system_type.toLowerCase()][
                      data.selectedDevice.device_type.toLowerCase()
                    ][key] &&
                    dataMaps[data.selectedDevice.system_type.toLowerCase()][
                      data.selectedDevice.device_type.toLowerCase()
                    ][key].type ===
                      dataMaps[data.selectedDevice.system_type.toLowerCase()][
                        data.selectedDevice.device_type.toLowerCase()
                      ][data.keyToCheck].type
                  )
                    return (
                      <option value={key}>
                        {
                          dataMaps[
                            data.selectedDevice.system_type.toLowerCase()
                          ][data.selectedDevice.device_type.toLowerCase()][key]
                            .name
                        }
                      </option>
                    );

                  return null;
                })}
              </select>
            ))}
          {data.operator && (
            <div
              onClick={(e) => {
                updateData({
                  valueToCompare: "",
                  keyToCompare: "",
                  inputValueType: !data.inputValueType,
                });
              }}
              style={{
                cursor: "pointer",
                padding: 5,
                borderRadius: 5,
                border: "1px solid grey",
              }}
            >
              {data.inputValueType
                ? "Change to compare"
                : "Change to custom value"}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Device;
