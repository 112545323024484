import React, { useContext } from "react";
import { FaSpinner } from "react-icons/fa";
import airQualityImg from "../../assets/airquality/loading.png";
import { LayoutSizeContext } from "App";

const COLOR_PRIMARY = "#383E41";

const WidgetLoadingAnimation = ({ widgetName, dimensions }) => {
  const layoutContext = useContext(LayoutSizeContext);

  //Returns a collection of horizontal boxes in an orientation based on direction
  const fillBoxes = (numBoxes, direction = "column", margins = 10) => {
    const rowStyle = {
      height: layoutContext.widgetWidth / 9,
      flex: 1,
      backgroundColor: COLOR_PRIMARY,
      borderRadius: 30,
      margin: margins * (layoutContext.widgetWidth / 1000),
      // marginBottom: margins,
    };

    const columnStyle = {
      height: layoutContext.widgetWidth / 10,
      backgroundColor: COLOR_PRIMARY,
      borderRadius: 30,
      margin: margins * (layoutContext.widgetWidth / 1000),
      // marginBottom: margins,
    };

    return (
      <div
        style={{
          display: "flex",
          flexDirection: direction,
        }}
      >
        {[...Array(numBoxes)].map((item, index) => {
          return (
            <div
              key={index}
              style={direction === "column" ? columnStyle : rowStyle}
            />
          );
        })}
      </div>
    );
  };

  //Returns a collection of vertical ovals in an orientation based on direction
  const fillOvals = (numOvals, direction = "row") => {
    const rowStyle = {
      backgroundColor: COLOR_PRIMARY,
      flex: 1,
      borderRadius: 50,
      width: layoutContext.widgetWidth / 6,
      height: layoutContext.widgetHeight / 2,
      margin: 10,
      // marginBottom: 20,
    };

    const columnStyle = {
      backgroundColor: COLOR_PRIMARY,
      borderRadius: 50,
      width: 100,
      height: 300,
      margin: 10,
      // marginBottom: 20,
    };

    return (
      <div style={{ display: "flex", flexDirection: direction }}>
        {[...Array(numOvals)].map((item, index) => {
          return (
            <div
              key={index}
              style={direction === "column" ? columnStyle : rowStyle}
            />
          );
        })}
      </div>
    );
  };

  const fillLongOvals = (
    numOvals,
    height = layoutContext.widgetHeight / 6,
    direction = "column",
    margins = 10
  ) => {
    const rowStyle = {
      backgroundColor: COLOR_PRIMARY,
      flex: 1,
      borderRadius: 50,
      // width: 100,
      height: height,
      margin: margins,
      marginBottom: margins,
    };

    const columnStyle = {
      backgroundColor: COLOR_PRIMARY,
      borderRadius: 50,
      // width: 100,
      height: height,
      margin: margins,
      marginBottom: margins,
    };

    return (
      <div style={{ display: "flex", flexDirection: direction }}>
        {[...Array(numOvals)].map((item, index) => {
          return (
            <div
              key={index}
              style={direction === "column" ? columnStyle : rowStyle}
            />
          );
        })}
      </div>
    );
  };

  //Basic map preview
  const fillMap = () => {
    return (
      <div
        style={{
          backgroundColor: COLOR_PRIMARY,
          borderRadius: 50,
          height: "100%",
          width: "100%",
        }}
      />
    );
  };

  //Standard map-type template
  const basicMapSetup = (d, boxes = 6) => {
    if (d === "1x1")
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            height: "100%",
          }}
          className='animation-breathing'
        >
          {fillMap()}
        </div>
      );

    if (d === "1x2")
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            flexDirection: "column",
            width: "100%",
            height: "100%",
          }}
          className='animation-breathing'
        >
          <div
            style={{
              flex: 1,
              width: "100%",
              height: "100%",
            }}
          >
            {fillMap()}
          </div>
          <div style={{ flex: 1, marginTop: 30, width: "100%" }}>
            {fillBoxes(boxes)}
          </div>
        </div>
      );

    if (d === "2x1")
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            flexDirection: "row",
            width: "100%",
            height: "100%",
          }}
          className='animation-breathing'
        >
          <div style={{ flex: 1 }}>{fillBoxes(boxes)}</div>
          <div style={{ flex: 1 }}>{fillMap()}</div>
        </div>
      );
  };

  //Standard forecast-type template
  const basicForecast = (d) => {
    if (d === "1x1")
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          className='animation-breathing'
        >
          <div>{fillOvals(3)}</div>
          <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
            <div style={{ flex: 1 }}>{fillBoxes(3, "column", 4)}</div>
            <div style={{ flex: 1 }}>{fillBoxes(3, "column", 4)}</div>
          </div>
        </div>
      );

    if (d === "1x2")
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          className='animation-breathing'
        >
          <div>{fillOvals(4)}</div>
          <div>{fillOvals(4)}</div>
          <div style={{ width: "100%" }}>{fillBoxes(6, "column", 9)}</div>
        </div>
      );

    if (d === "2x1")
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          className='animation-breathing'
        >
          <div>{fillOvals(8)}</div>
          <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
            <div style={{ flex: 1 }}>{fillBoxes(3, "column", 4)}</div>
            <div style={{ flex: 1 }}>{fillBoxes(3, "column", 4)}</div>
            <div style={{ flex: 1 }}>{fillBoxes(3, "column", 4)}</div>
          </div>
        </div>
      );
  };

  //Air quality template
  const arcBox = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          alignItems: "center",
          height: "90%",
        }}
        className='animation-breathing'
      >
        <div style={{ width: "100%" }}>{fillBoxes(1)}</div>
        <img
          src={airQualityImg}
          alt={"airQualityImg"}
          width={layoutContext.widgetWidth / 1.2}
          height={layoutContext.widgetHeight / 1.2}
        />
        <div style={{ width: "100%" }}>{fillBoxes(1)}</div>
      </div>
    );
  };

  //Widget loading animations
  const widgetLoadingAnimations = {
    AirportDelay: (d) => {
      return <div className='animation-breathing'>{fillBoxes(4)}</div>;
    },
    SkiReport: (d) => {
      return <div className='animation-breathing'>{fillBoxes(4)}</div>;
    },
    SurfAlert: (d) => {
      return (
        <div
          className='animation-breathing'
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
          }}
        >
          {[...Array(2)].map((item, index) => {
            return (
              <div
                key={index}
                style={{
                  flex: 1,
                  width: "100%",
                  height: "100%",
                }}
              >
                {fillBoxes(1)}
                <div
                  style={{
                    height: "50%",
                    backgroundColor: COLOR_PRIMARY,
                    borderRadius: 30,
                    margin: 10,
                    marginBottom: 20,
                  }}
                />
              </div>
            );
          })}
        </div>
      );
    },
    FireDanger: (d) => {
      if (d === "1x1")
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "100%",
              justifyContent: "space-around",
            }}
            className='animation-breathing'
          >
            <div>{fillOvals(3)}</div>
            <div style={{ width: "90%" }}>{fillBoxes(2)}</div>
          </div>
        );

      if (d === "1x2")
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "100%",
              justifyContent: "space-around",
            }}
            className='animation-breathing'
          >
            <div>
              <div>{fillOvals(4)}</div>
              <div>{fillOvals(4)}</div>
            </div>
            <div style={{ width: "100%" }}>{fillBoxes(3)}</div>
          </div>
        );

      if (d === "2x1")
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "100%",
              justifyContent: "space-around",
            }}
            className='animation-breathing'
          >
            <div>{fillOvals(7)}</div>
            <div style={{ width: "90%" }}>{fillBoxes(3, "row")}</div>
          </div>
        );
    },
    HurricanesTropicalStorms: (d) => {
      return basicMapSetup(d);
    },
    Earthquake: (d) => {
      return basicMapSetup(d);
    },
    TideTable: (d) => {
      return <div className='animation-breathing'>{fillBoxes(4)}</div>;
    },
    BuoyCondition: (d) => {
      return <div className='animation-breathing'>{fillBoxes(4)}</div>;
    },
    WaveAndWindCondition: (d) => {
      if (d === "2x1")
        return (
          <div className='animation-breathing'>
            <div style={{ width: "50%" }}>{fillBoxes(1)}</div>
            <div>{fillBoxes(4)}</div>
          </div>
        );
      else
        return (
          <div
            style={{ display: "flex", flexDirection: "column" }}
            className='animation-breathing'
          >
            <div
              style={{
                alignSelf: "center",
                justifySelf: "center",
                width: "90%",
              }}
            >
              {fillBoxes(1)}
            </div>
            <div>{fillBoxes(4)}</div>
          </div>
        );
    },
    MyAdvisories: (d) => {
      return <div className='animation-breathing'>{fillBoxes(4)}</div>;
    },
    NWSAlerts: (d) => {
      return <div className='animation-breathing'>{fillBoxes(4)}</div>;
    },
    AirQualityObservation: (d) => {
      if (d === "1x1") return arcBox();
      if (d === "1x2")
        return <div className='animation-breathing'>{fillLongOvals(7)}</div>;
      if (d === "2x1")
        return (
          <div
            style={{ display: "flex", flexDirection: "row" }}
            className='animation-breathing'
          >
            <div style={{ flex: 1 }}>{fillLongOvals(3)}</div>
            <div style={{ flex: 1 }}>{fillLongOvals(3)}</div>
          </div>
        );
    },
    AirQualityForecast: (d) => {
      if (d === "1x1") return arcBox();
      if (d === "1x2")
        return (
          <div className='animation-breathing'>
            {fillLongOvals(5, layoutContext.widgetHeight / 4)}
          </div>
        );
      if (d === "2x1")
        return (
          <div
            style={{ display: "flex", flexDirection: "row" }}
            className='animation-breathing'
          >
            <div style={{ flex: 1 }}>
              {fillLongOvals(2, layoutContext.widgetHeight / 4)}
            </div>
            <div style={{ flex: 1 }}>
              {fillLongOvals(2, layoutContext.widgetHeight / 4)}
            </div>
          </div>
        );
    },
    "15DayForecast": (d) => {
      return basicForecast(d);
    },
    DetailedForecast: (d) => {
      return basicForecast(d);
    },
    WeatherForecast: (d) => {
      return basicForecast(d);
    },
    SevereWeatherAlerts: (d) => {
      return basicMapSetup(d);
    },
    MapForecast: (d) => {
      return basicMapSetup(d);
    },
    Charts: (d) => {
      if (d === "1x1")
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              height: "90%",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
            className='animation-breathing'
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "80%",
                height: "80%",
                borderBottom: `solid ${COLOR_PRIMARY} 5px`,
                borderLeft: `solid ${COLOR_PRIMARY} 5px`,
                paddingBottom: 10,
              }}
            >
              {[...Array(8)].map((item, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      height: `${(index + 1) * 10}%`, //`${Math.floor(Math.random() * 60 + 40)}%`,
                      backgroundColor: COLOR_PRIMARY,
                      width: 50,
                      marginLeft: 10,
                      alignSelf: "flex-end",
                    }}
                  />
                );
              })}
            </div>
          </div>
        );
      else
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              height: "90%",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
            className='animation-breathing'
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "90%",
                height: "80%",
                borderBottom: `solid ${COLOR_PRIMARY} 5px`,
                borderLeft: `solid ${COLOR_PRIMARY} 5px`,
                paddingBottom: 10,
              }}
            >
              {[...Array(20)].map((item, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      height: `${(index + 1) * 5}%`,
                      backgroundColor: COLOR_PRIMARY,
                      width: 50,
                      marginLeft: 10,
                      alignSelf: "flex-end",
                    }}
                  />
                );
              })}
            </div>
          </div>
        );
    },
    DevicesMap: (d) => {
      if (d === "1x1") return basicMapSetup(d);
      if (d === "1x2")
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "90%",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
              paddingTop: 45,
            }}
            className='animation-breathing'
          >
            <div style={{ height: "100%", width: "100%" }}>{fillMap()}</div>
            <div style={{ height: "100%", width: "100%", paddingTop: 45 }}>
              {fillMap()}
            </div>
          </div>
        );

      if (d === "2x1")
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              height: "90%",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
            className='animation-breathing'
          >
            <div style={{ height: "100%", width: "100%" }}>{fillMap()}</div>
            <div style={{ height: "100%", width: "100%", marginLeft: 45 }}>
              {fillMap()}
            </div>
          </div>
        );
    },
    DevicesGraph: (d) => {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            height: "90%",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
          className='animation-breathing'
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "90%",
              height: "80%",
              borderBottom: `solid ${COLOR_PRIMARY} 5px`,
              borderLeft: `solid ${COLOR_PRIMARY} 5px`,
              paddingBottom: 10,
            }}
          >
            {[...Array(20)].map((item, index) => {
              return (
                <div
                  key={index}
                  style={{
                    height: `${(index + 1) * 5}%`,
                    backgroundColor: COLOR_PRIMARY,
                    width: 50,
                    marginLeft: 10,
                    alignSelf: "flex-end",
                  }}
                />
              );
            })}
          </div>
        </div>
      );
    },
    Doppler: (d) => {
      return basicMapSetup(d, 3);
    },
    ActiveAlerts: (d) => {
      if (d === "1x2")
        return <div className='animation-breathing'>{fillBoxes(15)}</div>;
      return <div className='animation-breathing'>{fillBoxes(7)}</div>;
    },
    WeatherAlerts: (d) => {
      if (d === "1x2")
        return <div className='animation-breathing'>{fillBoxes(15)}</div>;
      return <div className='animation-breathing'>{fillBoxes(7)}</div>;
    },
  };

  //Return the loading animation based on the widget name if it exists,
  //otherwise return the default loading animation
  // if (!dimensions) return ("Loading...")
  return (
    (dimensions &&
      widgetLoadingAnimations[widgetName] &&
      widgetLoadingAnimations[widgetName](
        `${dimensions[0]}x${dimensions[1]}`
      )) || (
      <div
        style={{
          width: "100%",
          height: "80%",
          alignItems: "center",

          display: "flex",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: 10,
          }}
        >
          <div
            style={{
              // background:'red',
              opacity: 0.5,
              width: "max-content",
              animation: "spin 2s ease infinite",
            }}
          >
            <FaSpinner size={40} />
          </div>
          <div>Loading...</div>
        </div>
      </div>
    )
  );
};

export default WidgetLoadingAnimation;
