import React from "react";
import { FaSpinner } from "react-icons/fa";

export default function LoadingAnimation({ style, size }) {
  return (
    <div
      style={{
        opacity: 0.5,
        width: size,
        height: size,
        animation: "spin 2s ease infinite",
        ...style,
      }}
    >
      <FaSpinner size={size} />
    </div>
  );
}
