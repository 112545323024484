import React, { useEffect, useState, useContext } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import CurrentDevices from "components/Connections/CurrentDevices";
import IntegrationUsage from "components/Connections/IntegrationUsage";
import NewConnection from "components/Connections/NewConnection";
import DevicesMap from "./DevicesMap";
import AwareFloodDeviceSettings from "./DeviceSettings/DeviceSettings.js";
import AwareDeviceGraphs from "./AwareDeviceGraphs";
import * as api from "apis/FusionAPI";
import { width } from "@mui/system";
import WidgetTimeUpdate from "components/Widgets/WidgetTimeUpdate";
import TextToggle from "components/UI/TextToggle";
import WidgetLoadingAnimation from "components/Widgets/WidgetLoadingAnimation";
import { usePerms } from "hooks/usePerms";
import { FaChevronLeft } from "react-icons/fa6";
import { FaArrowLeft, FaChevronRight } from "react-icons/fa";
import {
  ColorContext,
  DeviceContext,
  LayoutSizeContext,
  UniqueWidgetRefreshContext,
} from "App";
import Cookies from "js-cookie";
import { useAPIData } from "hooks/useAPIData";
import { extractDateTimeFromUrl } from "utils/dateUtils";
import RescAlertDeviceSettings from "./DeviceSettings/RescAlertDeviceSettings";
import { ConnectionsContext } from "./Connections";
import useSidebarOpen from "hooks/useSidebarOpen";
import DeviceHistory from "./DeviceSettings/DeviceHistory";

export default function RescAlertDevicesWidget({ type = "", dimensions }) {
  const connectionsContext = useContext(ConnectionsContext);
  const deviceContext = useContext(DeviceContext);
  const layout = useContext(LayoutSizeContext);

  const [selectedDevice, setSelectedDeviceIndex] = useState(0);
  const [startTime, setStartTime] = useState(
    new Date().getTime() - 24 * 60 * 60 * 1000
  );
  const [endTime, setEndTime] = useState(new Date().getTime());
  const [updatedSelectedDevice, setUpdatedSelectedDevice] = useState(false);

  const [showDeviceSettings, setShowDeviceSettings] = useState(false);
  const [pageIndex, setPageIndex] = useState(1);

  const [showHistory, setShowHistory] = useState(false);
  const [viewImageInWidget, setViewImageInWidget] = useState(false);

  const [mapWidth, setMapWidth] = useState(501);
  const [mapHeight, setMapHeight] = useState(451);

  const [imageNodes, setImageNodes] = useState([]);
  const [selectedImg, setSelectedImg] = useState(0);
  const [customDataOverride, setCustomDataOverride] = useState(null);
  const theme = useContext(ColorContext);

  const setSelectedDevice = (index) => {
    setSelectedDeviceIndex(index);
    setUpdatedSelectedDevice(!updatedSelectedDevice);
  };

  const uwf = useContext(UniqueWidgetRefreshContext);
  const ROUTE = "getNodes";
  const LOCATION = Cookies.get("city") + "-" + Cookies.get("state");
  const UNIQUEKEY = LOCATION + "-" + ROUTE;
  const deviceTypeToFilterToType = (deviceType) => {
    let t;
    if (type === "flood") {
      t = "Flood";
    }
    if (type === "manualRoadClosure") {
      t = "Manual Road Closure";
    }
    if (type === "technologyBridge") {
      t = "Technology Bridge_Flashing Lights";
    }
    if (type === "technologyBridgeSiren") {
      t = "Technology Bridge_Siren/Giant Voice";
    }
    return t;
  };

  useEffect(() => {
    if (!deviceContext.devices) return;

    if (!deviceContext.devices[selectedDevice]?.data) {
      return;
    }
    deviceContext.getDeviceData(startTime, endTime);
  }, [uwf[UNIQUEKEY]]);

  useEffect(() => {
    //find first node of type, then set selectedDevice to that index
    let dtype = "";
    if (type === "flood") {
      dtype = "Flood";
    }
    if (type === "manualRoadClosure") {
      dtype = "Manual Road Closure";
    }
    if (type === "technologyBridge") {
      dtype = "Technology Bridge_Flashing Lights";
    }
    if (type === "technologyBridgeSiren") {
      dtype = "Technology Bridge_Siren/Giant Voice";
    }
    const firstNodeOfType = deviceContext.devices.findIndex(
      (device) => device.device_type == dtype
    );
    if (firstNodeOfType != -1) setSelectedDevice(firstNodeOfType);
  }, [deviceContext.devices]);

  if (deviceContext.fetching) {
    return "fetching...";
    return <WidgetLoadingAnimation />;
  }

  if (deviceContext.fetching) {
    return (
      <div
        style={{
          height: "100%",
        }}
      >
        <WidgetLoadingAnimation
          widgetName='DevicesMap'
          dimensions={dimensions}
        />
      </div>
    );
  }

  const renderDeviceData = (size) => {
    return (
      <DeviceHistory
        device={deviceContext.devices[selectedDevice]}
        selectedDevice={selectedDevice}
        widget={true}
        size={size}
        setShowHistory={setShowHistory}
      />
    );

    return (
      <div
        style={{
          margin: 5,
          padding: 10,
          background: theme.primary,
          height:
            size === "2x1"
              ? layout.widgetHeight - 90
              : layout.widgetHeight * 2 - 80,
          overflow: "auto",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: 10,
            color: "white",
          }}
        >
          <div onClick={() => setShowHistory(!showHistory)}>
            <FaChevronLeft />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div style={{ fontWeight: "bold" }}>Device History</div>
            {deviceContext.devices[selectedDevice].alias}
          </div>
          <div></div>
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns:
              size === "2x1" ? "1.5fr 1fr 3fr" : "2fr 1fr 3fr",
            textAlign: "left",
          }}
        >
          <div>Date/Time</div>
          <div>Status</div>
          <div>Comment</div>
        </div>
        {deviceContext.devices[selectedDevice].data.length > 0
          ? deviceContext.devices[selectedDevice].data.map((data, index) => {
              return (
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns:
                      size === "2x1" ? "1.5fr 1fr 3fr" : "2fr 1fr 3fr",
                    borderBottom: "1px solid white",
                    padding: 5,
                    textAlign: "left",
                    gap: 10,
                  }}
                >
                  <div>{new Date(data.ts).toLocaleString()}</div>
                  <div>{data.status ? "Closed" : "Open"}</div>
                  <div>
                    {data.comments || (
                      <div style={{ color: "grey" }}>No info</div>
                    )}
                  </div>
                </div>
              );
            })
          : "No Data Available"}
      </div>
    );
  };

  const renderWidgetMap = (sidebarOpen) => {
    if (dimensions[0] == 2 && dimensions[1] == 1) {
      return (
        <div>
          <WidgetTimeUpdate route={ROUTE} realtime />
          <div
            style={{
              height: layout.widgetHeight - 60,
            }}
          >
            {showHistory ? (
              <div>{renderDeviceData("2x1")}</div>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <div
                  style={{
                    width: "50%",
                    height: "100%",
                    overflow: "hidden",
                  }}
                >
                  <DevicesMap
                    sidebarOpen={sidebarOpen}
                    setViewImageInWidget={setViewImageInWidget}
                    setPageIndex={setPageIndex}
                    selectorMargin={50}
                    widget
                    height={mapHeight}
                    width={mapWidth / 2}
                    selectedDevice={selectedDevice}
                    setSelectedDevice={setSelectedDevice}
                    nodeList={deviceContext.devices}
                    deviceTypeToFilter={type}
                    setShowCurrentDevice={() => {}}
                    customDataOverride={customDataOverride}
                  />
                </div>

                <div
                  style={{
                    width: layout.widgetWidth - 43,
                    display: "flex",
                    flexDirection: "column",
                    margin: 15,
                    overflow: "auto",
                    height: layout.widgetHeight - 80,
                    overflowX: "hidden",
                  }}
                >
                  {deviceContext.devices.filter((device) => {
                    return (
                      device.device_type === deviceTypeToFilterToType(type) &&
                      connectionsContext.canViewDeviceType(device)
                    );
                  }).length > 0 ? (
                    <RescAlertDeviceSettings
                      setCustomDataOverride={setCustomDataOverride}
                      setShowDeviceSettings={() => {}}
                      selectedDevice={selectedDevice}
                      nodeList={deviceContext.devices}
                      showHistory={showHistory}
                      setShowHistory={setShowHistory}
                      widget
                    />
                  ) : (
                    <div>No Devices Found</div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      );
    }

    if (dimensions[0] == 1 && dimensions[1] == 2) {
      return (
        <div>
          <WidgetTimeUpdate route={ROUTE} realtime />
          <div
            style={{
              height: layout.widgetHeight * 2 - 60,
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            {showHistory ? (
              <div>{renderDeviceData("1x2")}</div>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    overflow: "hidden",
                  }}
                >
                  <DevicesMap
                    sidebarOpen={sidebarOpen}
                    setViewImageInWidget={setViewImageInWidget}
                    setPageIndex={setPageIndex}
                    selectorMargin={50}
                    widget
                    selectedDevice={selectedDevice}
                    setSelectedDevice={setSelectedDevice}
                    nodeList={deviceContext.devices}
                    height={mapHeight}
                    width={mapWidth / 2}
                    deviceTypeToFilter={type}
                    setShowCurrentDevice={() => {}}
                    customDataOverride={customDataOverride}
                  />
                </div>

                <div
                  style={{
                    width: layout.widgetWidth - 43,
                    display: "flex",
                    flexDirection: "column",
                    margin: 15,
                  }}
                >
                  {deviceContext.devices.filter((device) => {
                    return (
                      device.device_type === deviceTypeToFilterToType(type) &&
                      connectionsContext.canViewDeviceType(device)
                    );
                  }).length > 0 ? (
                    <RescAlertDeviceSettings
                      setCustomDataOverride={setCustomDataOverride}
                      setShowDeviceSettings={() => {}}
                      selectedDevice={selectedDevice}
                      nodeList={deviceContext.devices}
                      showHistory={showHistory}
                      setShowHistory={setShowHistory}
                      widget
                    />
                  ) : (
                    <div>No Devices Found</div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      );
    }
  };

  if (deviceContext.devices.length > 0) {
    return renderWidgetMap();
  }

  return "Loading...";
  return <WidgetLoadingAnimation />;
}
