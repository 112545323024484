import React, { memo, useCallback, useState } from "react";

const Recipient = ({
  recipient,
  index,
  updateData,
  data,
  sms,
  theme,
  formatPhoneNumber,
  CARRIER_MAP,
}) => {
  const [localRecipient, setLocalRecipient] = useState(recipient.recipient);
  const [localCarrier, setLocalCarrier] = useState(recipient.carrier);

  const handleRecipientChange = (e) => {
    let value = e.target.value;
    if (sms) {
      value = value.replace(/[^\d]/g, "");
      if (!value.match(/^\d{0,10}$/)) return;
    }
    setLocalRecipient(value);
  };

  const handleRecipientBlur = () => {
    const finalValue =
      sms && localCarrier ? `${localRecipient}${localCarrier}` : localRecipient;
    const newRecipients = [...data.recipients];
    newRecipients[index] = {
      ...recipient,
      recipient: finalValue,
      carrier: localCarrier,
    };
    updateData({
      recipients: newRecipients,
      recipient: newRecipients.map((r) => r.recipient).join(","),
    });
  };

  const handleCarrierChange = (e) => {
    const carrierValue = e.target.value;
    setLocalCarrier(carrierValue);

    const finalValue =
      sms && carrierValue ? `${localRecipient}${carrierValue}` : localRecipient;
    const newRecipients = [...data.recipients];
    newRecipients[index] = {
      ...recipient,
      recipient: finalValue,
      carrier: carrierValue,
    };
    updateData({
      recipients: newRecipients,
      recipient: newRecipients.map((r) => r.recipient).join(","),
    });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        paddingTop: 10,
      }}
    >
      {(index !== 0 || (index === 0 && data.recipients.length > 1)) && (
        <button
          style={{
            background: theme.primaryShadow,
            height: 30,
            width: 30,
            marginLeft: 10,
            borderRadius: 5,
            marginTop: index === 0 ? 20 : 0,
          }}
          onClick={() => {
            const newRecipients = [...data.recipients];
            newRecipients.splice(index, 1);
            updateData({
              recipients: newRecipients,
              recipient: newRecipients.map((r) => r.recipient).join(","),
            });
          }}
        >
          -
        </button>
      )}
      <div>
        {index === 0 && <p style={{ color: theme.fontColor }}>Recipient</p>}
        <input
          style={{
            padding: 5,
            borderRadius: 5,
            border: "1px solid grey",
            marginLeft: 10,
          }}
          type={sms ? "tel" : "text"}
          value={sms ? formatPhoneNumber(localRecipient) : localRecipient}
          onChange={handleRecipientChange}
          onBlur={handleRecipientBlur}
        />
      </div>
      {sms && (
        <div>
          {index === 0 && <p style={{ color: theme.fontColor }}>Carrier</p>}
          <select
            style={{
              padding: 5,
              borderRadius: 5,
              border: "1px solid grey",
              marginLeft: 10,
              background: theme.primaryShadow,
            }}
            value={localCarrier}
            onChange={handleCarrierChange}
          >
            <option value="">Select Carrier</option>
            {CARRIER_MAP.sort((a, b) => a.name.localeCompare(b.name)).map(
              (carrier) => (
                <option key={carrier.name} value={carrier.sms}>
                  {carrier.name}
                </option>
              )
            )}
          </select>
        </div>
      )}
      {index === data.recipients.length - 1 && (
        <button
          style={{
            background: theme.primaryShadow,
            height: 30,
            width: 30,
            marginLeft: 10,
            borderRadius: 5,
            marginTop: index === 0 ? 20 : 0,
          }}
          onClick={() => {
            updateData({
              recipients: [...data.recipients, { recipient: "", carrier: "" }],
            });
          }}
        >
          +
        </button>
      )}
    </div>
  );
};

export default Recipient;
