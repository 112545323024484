import { DeviceContext } from "App";
import React, { useContext, useEffect, useState } from "react";
import MobileDevicesList from "./MobileDevices/MobileDevicesList";
import connectionsIcon from "assets/nIcons/Integrations.png";
import MobilePageTemplate from "./MobilePageTemplate";
import MobileSystemsList from "./MobileDevices/MobileSystemsList";
import MobileDevicesMap from "./MobileDevices/MobileDevicesMap";

const MobileIntegrations = () => {
  const deviceContext = useContext(DeviceContext);

  // Group devices by type
  const [groupedDevices, setGroupedDevices] = useState([]);
  const [pages, setPages] = useState([]);

  // useEffect(() => {
  //   deviceContext.getDevices();
  // }, []);

  useEffect(() => {
    deviceContext.getDevices();
    // const interval = setInterval(() => {
    //   deviceContext.getDevices({ ignoreFetching: true });
    // }, 15000);

    // return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const gd = deviceContext.devices.reduce((acc, device) => {
      const type = (device.device_type || "Unknown").split("_").join(" - ");
      if (!acc[type]) {
        acc[type] = [];
      }
      acc[type].push(device);
      return acc;
    }, {});

    const sortedGd = Object.keys(gd)
      .sort()
      .reduce((acc, key) => {
        acc[key] = gd[key];
        return acc;
      }, {});

    setGroupedDevices(sortedGd);
  }, [deviceContext.devices]);

  useEffect(() => {
    const pages = [
      // {
      //   name: "Systems",
      //   component: <MobileSystemsList />,
      // },
      {
        name: "Devices",
        component: <MobileDevicesList groupedDevices={groupedDevices} />,
      },
      {
        name: "Map",
        component: <MobileDevicesMap />,
      },
      // {
      //   name: "Graph",
      // },
      // {
      //   name: "Groups",
      // },
    ];
    setPages(pages);
  }, [groupedDevices]);

  return (
    <MobilePageTemplate
      icon={connectionsIcon}
      title='Integrations'
      pages={pages}
      loadingMessage='Loading devices...'
      loadingCondition={deviceContext.devices.length === 0}
    />
  );
};

export default MobileIntegrations;
