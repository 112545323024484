import { message } from "antd";
import { ColorContext } from "App";
import moment from "moment";
import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import Recipient from "./Recipient";

const SendEmail = ({ setData, data, setError, sms = false }) => {
  // const [storedData, setStoredData] = useState({
  //   dateTime: type === "dateTime" ? new Date() : "",
  //   date: type === "date" ? new Date() : "",
  //   time: type === "time" ? new Date() : ""
  // })


  const CARRIER_MAP = [
    { name: "AT&T", sms: "@txt.att.net", mms: "@mms.att.net" },
    // {
    //   name: "Sprint",
    //   sms: "@pm.sprint.com",
    //   mms: "@pm.sprint.com",
    // },
    { name: "T-Mobile", sms: "@tmomail.net", mms: "@tmomail.net" },
    {
      name: "Verizon Wireless",
      sms: "@vtext.com",
      mms: "@vzwpix.com",
    },
    { name: "Virgin Mobile", sms: "@vmobl.com", mms: "@vmobl.com" },
    {
      name: "Sprint",
      sms: "@messaging.sprintpcs.com",
      mms: "@pm.sprint.com",
    },
    {
      name: "AT&T Enterprise Paging",
      sms: "@page.att.net",
      mms: "@page.att.net",
    },
    {
      name: "Rogers Wireless",
      sms: "@sms.rogers.com",
      mms: "@pcs.rogers.com",
    },
    {
      name: "Boost Mobile",
      sms: "@sms.myboostmobile.com",
      mms: "@myboostmobile.com",
    },
    {
      name: "Telus Mobility",
      sms: "@msg.telus.com",
      mms: "@msg.telus.com",
    },
    {
      name: "Airfire Mobile",
      sms: "@sms.airfiremobile.com",
      mms: "@sms.airfire.ca",
    },
    {
      name: "Ameritech",
      sms: "@paging.acswireless.com",
      mms: "@paging.acswireless.com",
    },
    {
      name: "Assurance Wireless",
      sms: "@vmobl.com",
      mms: "@vmobl.com",
    },
    {
      name: "BellSouth",
      sms: "@bellsouth.cl",
      mms: "@bellsouth.cl",
    },
    {
      name: "Bluegrass Cellular",
      sms: "@sms.bluecell.com",
      mms: "@sms.bluecell.com",
    },
    {
      name: "Cellcom",
      sms: "@cellcom.quiktxt.com",
      mms: "@cellcom.quiktxt.com",
    },
    {
      name: "Cellular South",
      sms: "@csouth1.com",
      mms: "@csouth1.com",
    },
    {
      name: "Chariton Valley Wireless",
      sms: "@sms.cvalley.net",
      mms: "@sms.cvalley.net",
    },
    {
      name: "Chat Mobility",
      sms: "@mail.msgsender.com",
      mms: "@mail.msgsender.com",
    },
    {
      name: "Cleartalk",
      sms: "@sms.cleartalk.us",
      mms: "@sms.cleartalk.us",
    },
    // {
    //   name: "Consumer Cellular",
    //   sms: "@cingularme.com",
    //   mms: "@mailmymobile.net",
    // },
    {
      name: "Consumer Cellular",
      sms: "@mailmymobile.net",
      mms: "@txt.att.net",
    },
    {
      name: "Cricket",
      sms: "@sms.cricketwireless.net",
      mms: "@mms.cricketwireless.net",
    },
    {
      name: "Element Mobile",
      sms: "@SMS.elementmobile.net",
      mms: "@myelementmobile.com",
    },
    {
      name: "Esendex",
      sms: "@echoemail.net",
      mms: "@echoemail.net",
    },
    {
      name: "Mellon Mobile",
      sms: "@mellonmobile.ga",
      mms: "@mellonmobile.com",
    },
    {
      name: "MetroPCS",
      sms: "@mymetropcs.com",
      mms: "@mymetropcs.com",
    },
    {
      name: "Nextech",
      sms: "@sms.ntwls.net",
      mms: "@sms.nextechwireless.com",
    },
    {
      name: "Page Plus Cellular",
      sms: "@vtext.com",
      mms: "@vtext.com",
    },
    {
      name: "South Central Communications",
      sms: "@rinasms.com",
      mms: "@rinasms.com",
    },
    {
      name: "Southernlinc",
      sms: "@page.southernlinc.com",
      mms: "@page.southernlinc.com",
    },
    {
      name: "Straight Talk",
      sms: "@txt.att.net",
      mms: "@mypixmessages.com",
    },
    {
      name: "Syringa Wireless",
      sms: "@rinasms.com",
      mms: "@vtext.com",
    },
    { name: "Teleflip", sms: "@teleflip.com", mms: "@rinasms.com" },
    {
      name: "Union Wireless",
      sms: "@union-tel.com",
      mms: "@teleflip.com",
    },
    {
      name: "US Cellular",
      sms: "@email.uscc.net",
      mms: "@union-tel.com",
    },
    {
      name: "Voyager Mobile",
      sms: "@text.voyagermobile.com",
      mms: "@email.uscc.net",
    },
    {
      name: "Centennial Wireless",
      sms: "@cwemail.com",
      mms: "@text.voyagermobile.com",
    },
    { name: "TracFone", sms: "@txt.att.net", mms: "@cwemail.com" },
  ];

  const formatPhoneNumber = (value) => {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, "");
    if (phoneNumber.length < 4) return phoneNumber;
    if (phoneNumber.length < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  };

  useEffect(() => {
    if (
      data?.subject === undefined ||
      data?.body === undefined ||
      data?.recipient === undefined ||
      data?.recipients === undefined ||
      data?.recipients?.length === 0
    ) {
      setData({
        subject: "",
        body: "",
        recipient: "",
        recipients: [
          {
            recipient: "",
            carrier: "",
          },
        ],
        // carrier: "",
        error: true,
      });
    }
  }, []);

  const updateData = (item) => {
    setData({
      ...data,
      ...item,
      error: verifyData({ ...data, ...item }),
    });
  };

  const theme = useContext(ColorContext);
  const verifyData = (data) => {
    let error = false;
    if (data.subject === "" || data.body === "" || data.recipient === "") {
      error = true;
      setError({ message: "Action incomplete, check for missing fields." });
    }

    if (sms) {
      // Check SMS recipients
      for (const recipient of data.recipients) {
        if (!recipient.carrier) {
          error = true;
          setError({ message: "Please select a carrier for all recipients" });
          break;
        }
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const phone = recipient.recipient.split("@")[0].trim();
        if (!phone.match(/^\d{10}$/)) {
          error = true;
          setError({ message: `Invalid recipient: ${formatPhoneNumber(phone)}` });
          break;
        }
      }
    } else {
      // Check email recipients
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const emails = data.recipient.split(",").map((email) => email.trim());

      for (const email of emails) {
        if (!emailRegex.test(email)) {
          error = true;
          setError({ message: `Invalid email address: ${email}` });
          break;
        }
      }
    }

    if (!error) {
      setError(null);
    }
    return error;
  };

  // useEffect(() => {
  //   setData(storedData)
  // }, [storedData])

  // const Recipient = memo(
  //   ({ recipient, index, updateData, data, sms, theme }) => {
  //     const [localRecipient, setLocalRecipient] = useState(recipient.recipient);
  //     const [localCarrier, setLocalCarrier] = useState(recipient.carrier);

  //     const debounce = (func, delay) => {
  //       let timer;
  //       return (...args) => {
  //         if (timer) clearTimeout(timer);
  //         timer = setTimeout(() => {
  //           func(...args);
  //           timer = null;
  //         }, delay);
  //       };
  //     };

  //     const debouncedUpdate = useCallback(
  //       debounce((newValue, newCarrier) => {
  //         const newRecipients = [...data.recipients];
  //         newRecipients[index] = {
  //           ...recipient,
  //           recipient: newValue,
  //           carrier: newCarrier,
  //         };
  //         updateData({ recipients: newRecipients });
  //       }, 300),
  //       [data.recipients, index]
  //     );

  //     const handleRecipientChange = (e) => {
  //       let value = e.target.value;
  //       if (sms) {
  //         value = value.replace(/[^\d]/g, "");
  //         if (!value.match(/^\d{0,10}$/)) return;
  //       }

  //       const finalValue =
  //         sms && localCarrier ? `${value}${localCarrier}` : value;
  //       setLocalRecipient(value);
  //       debouncedUpdate(finalValue, localCarrier);
  //       const allRecipients = data.recipients
  //         .map((recipient) => recipient.recipient)
  //         .join(",");
  //       updateData({ recipient: allRecipients });
  //     };

  //     const handleCarrierChange = (e) => {
  //       const carrierValue = e.target.value;
  //       setLocalCarrier(carrierValue);
  //       const finalValue =
  //         sms && carrierValue
  //           ? `${localRecipient}${carrierValue}`
  //           : localRecipient;
  //       debouncedUpdate(finalValue, carrierValue);
  //       const allRecipients = data.recipients
  //         .map((recipient) => recipient.recipient)
  //         .join(",");
  //       updateData({ recipient: allRecipients });
  //     };

  //     return (
  //       <div
  //         style={{
  //           display: "flex",
  //           flexDirection: "row",
  //           paddingTop: 10,
  //         }}
  //       >
  //         {index !== 0 && (
  //           <button
  //             style={{
  //               background: theme.primaryShadow,
  //               height: 30,
  //               width: 30,
  //               marginLeft: 10,
  //               borderRadius: 5,
  //             }}
  //             onClick={() => {
  //               const newRecipients = [...data.recipients];
  //               newRecipients.splice(index, 1);
  //               updateData({ recipients: newRecipients });
  //             }}
  //           >
  //             -
  //           </button>
  //         )}
  //         <div>
  //           {index === 0 && <p style={{ color: theme.fontColor }}>Recipient</p>}
  //           <input
  //             style={{
  //               padding: 5,
  //               borderRadius: 5,
  //               border: "1px solid grey",
  //               marginLeft: 10,
  //             }}
  //             type={sms ? "tel" : "text"}
  //             value={sms ? formatPhoneNumber(localRecipient) : localRecipient}
  //             onChange={handleRecipientChange}
  //           />
  //         </div>
  //         {sms && (
  //           <div>
  //             {index === 0 && <p style={{ color: theme.fontColor }}>Carrier</p>}
  //             <select
  //               style={{
  //                 padding: 5,
  //                 borderRadius: 5,
  //                 border: "1px solid grey",
  //                 marginLeft: 10,
  //                 background: theme.primaryShadow,
  //               }}
  //               value={localCarrier}
  //               onChange={handleCarrierChange}
  //             >
  //               <option value="">Select Carrier</option>
  //               {CARRIER_MAP.sort((a, b) => {
  //                 return a.name.localeCompare(b.name);
  //               }).map((carrier) => (
  //                 <option key={carrier.name} value={carrier.sms}>
  //                   {carrier.name}
  //                 </option>
  //               ))}
  //             </select>
  //           </div>
  //         )}
  //         {index === data.recipients.length - 1 && (
  //           <button
  //             style={{
  //               background: theme.primaryShadow,
  //               height: 30,
  //               width: 30,
  //               marginLeft: 10,
  //               borderRadius: 5,
  //             }}
  //             onClick={() => {
  //               updateData({
  //                 recipients: [
  //                   ...data.recipients,
  //                   { recipient: "", carrier: "" },
  //                 ],
  //               });
  //             }}
  //           >
  //             +
  //           </button>
  //         )}
  //       </div>
  //     );
  //   },
  //   (prevProps, nextProps) => {
  //     return (
  //       prevProps.recipient.recipient === nextProps.recipient.recipient &&
  //       prevProps.recipient.carrier === nextProps.recipient.carrier
  //     );
  //   }
  // );

  return (
    <div
      style={{
        padding: 20,
        borderRadius: 10,
        margin: 10,
        background: theme.primary,
      }}
    >
      {data.recipients?.map((recipient, index) => (
        <Recipient
          key={`recipient-${index}-${recipient.recipient}`}
          recipient={recipient}
          index={index}
          updateData={updateData}
          data={data}
          sms={sms}
          theme={theme}
          CARRIER_MAP={CARRIER_MAP}
          formatPhoneNumber={formatPhoneNumber}
        />
      ))}
      <div>
        <p style={{ color: theme.fontColor }}>Subject</p>
        <input
          style={{
            padding: 5,
            borderRadius: 5,
            border: "1px solid grey",
            marginLeft: 10,
          }}
          type="text"
          name="subject"
          value={data.subject}
          onChange={(e) => updateData({ subject: e.target.value })}
        />
      </div>
      <div>
        <p style={{ color: theme.fontColor }}>Body</p>
        <textarea
          style={{
            padding: 5,
            borderRadius: 5,
            border: "1px solid grey",
            marginLeft: 10,
            width: 400,
          }}
          lines={4}
          type="text"
          name="body"
          value={data.body}
          onChange={(e) => updateData({ body: e.target.value })}
        />
      </div>
    </div>
  );
};

export default SendEmail;
