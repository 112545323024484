export const awareFloodSensorData = {
  depth1: "Depth measurement",
  airTemp: "Air temperature",
  baro: "Barometric pressure",
  dropSDI: "SDI-12 device drop count",
  battery: "Battery level",
  lat: "Latitude",
  lon: "Longitude",
  mode: "Reporting mode",
  calibration: "Calibration value",
  h2oTemp: "Water temperature",
  rssi: "Signal strength",
  samp: "Sampling mode",
  hex: "Raw data of packet",
  IMEI: "IMEI modem number",
  soilSDI: "SDI-12 soil moisture reading",
  ffi1: "Rise rate indicator",
  pict: "Picture filename on server",
  NI: "Node indicator",
  elev: "Elevation (GPS)",
  ffTheshold: "Current flash flood threshold",
  calType: "Device type",
  dDetImgEnable: "Depth detection imaging enabled/disabled",
  depthDet1: "Depth detection level 1",
  depthDet2: "Depth detection level 2",
  depthDet3: "Depth detection level 3",
  depthHoldHours: "Depth hold hours",
  depthInd: "Depth trigger indicator",
  dRateImgEnable: "Drop rate imaging enabled",
  drInd: "Drop rate indicator",
  dropThresh: "Drop rate threshold",
  ffiImgEnable: "Rise rate imaging enabled/disabled",
  firstPkt: "First packet indicator",
  gatewayType: "Gateway type",
  gpsSync: "GPS synchronization",
  hResImgEnable: "High resolution imaging enabled",
  imgHoldStart: "Triggered image hold start",
  imgHoldEnd: "Triggered image hold end",
  ipAddress: "Device IP address",
  port: "Device network port",
  pCommand: "Previous command",
  protocol: "Packet protocol",
  soilTempSDI: "SDI-12 soil temperature reading",
  timestamp_links: "Timestamp of links",
  water_level_adj: "Water level adjustment",
  full_img_path: "Full image path",
  img_relative_path: "Relative image path",
  created_utc: "Created timestamp",
  uuid: "Device UUID",
  node_alias: "Device alias",
  node_name: "Entity name",
  aware_id: "Aware ID",
  ts: "Timestamp",
  node_id: "Node ID",
  NI: "Node ID",
  data_hash: "Data hash",
  name: "Entity name",
  user_created: "User created",
  user_updated: "User updated",
  date_created: "Date created",
  date_updated: "Date updated",
  device_type: "Device type",
  sensordepths: "Actual depths",
  tips: "Tips",
  intensity: "Intensity",
  rainfall: "Rainfall",
};

/*
intensity
rainfall
saltwater
tips
*/
