import React, { useEffect, useState, useContext, useRef } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import CurrentDevices from "components/Connections/CurrentDevices";
import WidgetLoadingAnimation from "components/Widgets/WidgetLoadingAnimation";
import { usePerms } from "hooks/usePerms";
import {
  DeviceContext,
  LayoutSizeContext,
  UniqueWidgetRefreshContext,
} from "App";
import Cookies from "js-cookie";

const MobileAwareDataSheet = ({
  selectedDevice, //index
  setSelectedDeviceIndex,
}) => {
  const deviceContext = useContext(DeviceContext);

  const perms = usePerms();
  const navigate = useNavigate();
  const location = useLocation();
  const layout = useContext(LayoutSizeContext);

  const [params, setParams] = useSearchParams();
  const [updatedSelectedDevice, setUpdatedSelectedDevice] = useState(false);

  const [showDeviceSettings, setShowDeviceSettings] = useState(false);
  const [showCurrentDevice, setShowCurrentDeviceState] = useState(false);
  const [pageIndex, setPageIndex] = useState(1);

  const [imageNodes, setImageNodes] = useState([]);
  const [selectedImg, setSelectedImg] = useState(0);
  const [showExportModal, setShowExportModal] = useState(false);

  const [init, setInit] = useState(false);
  const [showAdvancedDate, setShowAdvancedDate] = useState(false);
  const [usingCustomDate, setUsingCustomDate] = useState(false);

  useEffect(() => {}, [showCurrentDevice]);

  const setShowCurrentDevice = (value) => {
    setShowCurrentDeviceState(value);
  };

  const setSelectedDevice = (index) => {
    setSelectedDeviceIndex(index);
    setUpdatedSelectedDevice(!updatedSelectedDevice);
  };

  const showDeviceSettingsRef = useRef(showDeviceSettings);
  const showAdvancedDateRef = useRef(showAdvancedDate);
  const usingCustomDateRef = useRef(usingCustomDate);
  const showExportModalRef = useRef(showExportModal);

  useEffect(() => {
    showDeviceSettingsRef.current = showDeviceSettings;
  }, [showDeviceSettings]);

  useEffect(() => {
    showAdvancedDateRef.current = showAdvancedDate;
  }, [showAdvancedDate]);

  useEffect(() => {
    showExportModalRef.current = showExportModal;
  }, [showExportModal]);

  useEffect(() => {
    usingCustomDateRef.current = usingCustomDate;
  }, [usingCustomDate]);

  useEffect(() => {
    if (!deviceContext.devices) return;

    if (!deviceContext.devices[selectedDevice]?.data) {
      // setSelectedDevice(0);
      return;
    }

    const foundImg = [];

    deviceContext.devices[selectedDevice].data.forEach((data, index) => {
      if (
        !foundImg
          .map((img) => {
            return (
              deviceContext.devices[selectedDevice].data[img]?.pict ===
              data?.pict
            );
          })
          .includes(true)
      ) {
        //new entry
        foundImg.push(index);
      }
    });

    setImageNodes(foundImg);
    setSelectedImg(0);
  }, [selectedDevice, deviceContext.devices]);

  useEffect(() => {
    if (deviceContext.devices.length === 0) return;
    //set page index to max length
    setPageIndex(deviceContext.devices[selectedDevice].data.length);
  }, [selectedDevice]);

  const uwf = useContext(UniqueWidgetRefreshContext);
  const ROUTE = "getNodes";
  const LOCATION = Cookies.get("city") + "-" + Cookies.get("state");
  const UNIQUEKEY = LOCATION + "-" + ROUTE;

  // useEffect(() => {
  //   deviceContext.getDeviceData(startTime, endTime);
  // }, [uwf[UNIQUEKEY]]);

  const [deviceTypeForGraph, setDeviceTypeForGraph] = useState("");

  useEffect(() => {
    setTimeout(() => {
      if (deviceContext.devices.length > 0) {
        const type =
          deviceContext.devices[selectedDevice].system_type +
          "_" +
          deviceContext.devices[selectedDevice].device_type;
        setDeviceTypeForGraph(type);
      }
    }, 500);
  }, [deviceContext.devices, selectedDevice]);

  useEffect(() => {
    if (init || deviceContext.devices.length === 0) return;

    //get search params
    if (params.get("device")) {
      const nodeId = parseInt(params.get("device"));
      let nodeIndex = -1;

      deviceContext.devices.forEach((node, index) => {
        if (nodeIndex >= 0) return;
        if (node.nodeId === nodeId) nodeIndex = index;
      });

      if (nodeIndex < 0) return;

      setSelectedDevice(nodeIndex);
      setPageIndex(1);
      // setShowCurrentDevice(true);
      setInit(true);
    }
  }, [deviceContext.devices]);

  if (deviceContext.fetching) {
    return <WidgetLoadingAnimation />;
  }

  // const renderDeviceSettings = () => {
  //   const type =
  //     deviceContext.devices[selectedDevice].system_type +
  //     "_" +
  //     deviceContext.devices[selectedDevice].device_type;
  //   if (showDeviceSettings)
  //     switch (type.toLowerCase()) {
  //       case "aware_flood":
  //         return (
  //           <AwareFloodDeviceSettings
  //             setShowDeviceSettings={setShowDeviceSettings}
  //             selectedDevice={selectedDevice}
  //             nodeList={deviceContext.devices}
  //           />
  //         );
  //       case "rescalert devices_manual road closure":
  //         return (
  //           <RescAlertDeviceSettings
  //             setShowDeviceSettings={setShowDeviceSettings}
  //             selectedDevice={selectedDevice}
  //             nodeList={deviceContext.devices}
  //             type="manualRoadClosure"
  //           />
  //         );
  //       case "rescalert devices_technology bridge_flashing lights":
  //         return (
  //           <RescAlertDeviceSettings
  //             setShowDeviceSettings={setShowDeviceSettings}
  //             selectedDevice={selectedDevice}
  //             nodeList={deviceContext.devices}
  //             type="technologyBridge"
  //           />
  //         );
  //       case "rescalert devices_technology bridge_siren/giant voice":
  //         return (
  //           <RescAlertDeviceSettings
  //             setShowDeviceSettings={setShowDeviceSettings}
  //             selectedDevice={selectedDevice}
  //             nodeList={deviceContext.devices}
  //             type="technologyBridgeSiren"
  //           />
  //         );
  //         return null;
  //     }
  // };

  // const RenderGraph = () => {
  //   const deviceTypeStr = deviceTypeForGraph;

  //   switch (deviceTypeStr.toLowerCase()) {
  //     case "aware_flood":
  //       return (
  //         <AwareDeviceGraphs
  //           nodeList={deviceContext.devices}
  //           selectedDevice={selectedDevice}
  //           setSelectedDevice={setSelectedDevice}
  //           setPageIndex={setPageIndex}
  //           setShowCurrentDevice={setShowCurrentDevice}
  //           updatedSelectedDevice={updatedSelectedDevice}
  //           startTime={startTime}
  //           endTime={endTime}
  //           timeSpan={timeSpan}
  //         />
  //       );
  //     case "rescalert devices_manual road closure":
  //       return (
  //         <div
  //           style={{
  //             height: window.innerHeight / 3 + 50,
  //             width: window.innerWidth / 2,
  //             display: "flex",
  //             justifyContent: "center",
  //             alignItems: "center",
  //             textAlign: "center",
  //           }}
  //         >
  //           Select a Device
  //         </div>
  //       );
  //     case "rescalert devices_technology bridge_flashing lights":
  //       return (
  //         <div
  //           style={{
  //             height: window.innerHeight / 3 + 50,
  //             width: window.innerWidth / 2,
  //             display: "flex",
  //             justifyContent: "center",
  //             alignItems: "center",
  //             textAlign: "center",
  //           }}
  //         >
  //           Select a Device
  //         </div>
  //       );
  //     case "rescalert devices_technology bridge_siren/giant voice":
  //       return (
  //         <div
  //           style={{
  //             height: window.innerHeight / 3 + 50,
  //             width: window.innerWidth / 2,
  //             display: "flex",
  //             justifyContent: "center",
  //             alignItems: "center",
  //             textAlign: "center",
  //           }}
  //         >
  //           Select a Device
  //         </div>
  //       );
  //     default:
  //       return (
  //         <div
  //           style={{
  //             height: window.innerHeight / 3 + 50,
  //             width: window.innerWidth / 2,
  //             display: "flex",
  //             justifyContent: "center",
  //             alignItems: "center",
  //             textAlign: "center",
  //           }}
  //         >
  //           Select a Device
  //         </div>
  //       );
  //   }
  // };

  return (
    <CurrentDevices
      // setShowDeviceSettings={null}
      selectedDevice={selectedDevice}
      // setSelectedDevice={null}
      // showCurrentDevice={null}
      // setShowCurrentDevice={null}
      nodeList={deviceContext.devices}
      pageIndex={pageIndex}
      setPageIndex={setPageIndex}
      // handleSubmit={null}
      // timeMultiplier={null}
      // setTimeMultiplier={null}
      // startTime={null}
      // setStartTime={null}
      // endTime={null}
      // setEndTime={null}
      // successMessage={null}
      setSuccessMessage={() => {}}
      // showExportModal={null}
      // showAdvancedDate={null}
      // setShowAdvancedDate={null}
      // setShowExportModal={null}
      // loadingMessage={null}
      // setTimeSpan={null}
      // setUsingCustomDate={null}
      awareDataViewOnly={true}
    />
  );
};

export default MobileAwareDataSheet;
