import React, { useContext, useEffect, useState } from "react";
import * as api from "apis/FusionAPI";
import { awareFloodSensorData } from "pages/System/GlobalActions/Devices/DeviceKeyMaps";
import xlsx from "json-as-xlsx";
import ExcelJS from "exceljs";
import { ColorContext } from "App";
import { CUSTOM_URL, TEAM_PREFIX } from "apis/FusionAPI";

const ExportModal = ({
  startTime,
  setStartTime,
  endTime,
  setEndTime,
  formatDateTimeLocal,
  data,
  selectedDevice,
  setShowExportModal,
}) => {
  const [exportFormat, setExportFormat] = useState("csv");
  const [selectedDevices, setSelectedDevices] = useState([]);

  const BASE_FIELDS = ["NI", "ts", "pict", "img_link_url"];

  const EXCLUDED_FIELDS = [
    "data_hash",
    "img_sys_path",
    "hash_counter",
    // "workplace_id",
    // "uuid",
    // "name",
    // "user_created",
    // "user_updated",
    // "date_created",
    // "date_updated",
    "id",
  ];

  const [selectedFields, setSelectedFields] = useState(BASE_FIELDS);

  const HEADER_MAP = awareFloodSensorData;

  const [timeframeData, setTimeframeData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [customTimeInputs, setCustomTimeImputs] = useState({});

  const [exportingString, setExportingString] = useState("");

  const [includeImages, setIncludeImages] = useState(false);

  const [dataFieldLength, setDataFieldLength] = useState(0);

  const theme = useContext(ColorContext);
  function convertToCSV(arr) {
    const array = [Object.keys(arr[0])].concat(arr);

    return array
      .map((it) => {
        return Object.values(it).toString();
      })
      .join("\n");
  }

  const handleExport = () => {
    if (selectedDevices.length === 0) {
      alert("Please select at least one device to export.");
      return;
    }

    setExportingString("Exporting...");
    //get new dataset from server
    api
      .getNodeDataByTimestamps(startTime, endTime, selectedDevices, true)
      .then((res) => {
        const resData = res.data.result;
        if (resData.length === 0) {
          setExportingString("");
          alert("No data available for the selected timeframe.");
          return;
        }

        setTimeframeData(resData);
        //filter data

        //add base fields to selected fields
        let selectedFieldsFull = [...selectedFields, ...BASE_FIELDS];
        //remove excluded fields from selected fields
        selectedFieldsFull = selectedFieldsFull.filter((field) => {
          return !EXCLUDED_FIELDS.includes(field);
        });

        const filteredData = resData.map((device) => {
          const filteredDeviceData = device.map((deviceData) => {
            const filteredData = {};
            Object.keys(deviceData).forEach((field) => {
              if (selectedFieldsFull.includes(field)) {
                filteredData[field] = deviceData[field];
              }
            });
            return filteredData;
          });
          return {
            node_id: device.node_id,
            data: filteredDeviceData,
          };
        });
        setFilteredData(filteredData);
        if (filteredData.length === 0) {
          setExportingString("");
          alert("No filtered data available for the selected devices.");
          return;
        }

        //map filtered data based on expected values
        const CONVERT_TYPES = ["ts", "gatewayType", "calType"];

        const mappedData = filteredData.map((device) => {
          return device.data.map((deviceData) => {
            const mappedData = {};
            Object.keys(deviceData).forEach((field) => {
              if (field === "ts") {
                mappedData[field] = new Date(
                  deviceData[field]
                ).toLocaleString();
              } else if (field === "gatewayType") {
                mappedData[field] =
                  device[field] === 1 ? "FirstNet Cellular" : "Satellite";
              } else if (field === "calType") {
                const calibrationType = [
                  "Lower Pressure Sensor",
                  "Lower Pressure Sensor + SDI-12 Soil Moisture",
                  "Lower Pressure Sensor + Waterlog Rain Sensor",
                  "Lower Pressure Sensor + Intellisense Rain Sensor",
                  "Water Level Radar",
                ];
                mappedData[field] = calibrationType[deviceData[field]];
              } else {
                mappedData[field] = deviceData[field];
              }
            });
            return mappedData;
          });
        });

        if (!mappedData || mappedData.length === 0) {
          setExportingString("");
          alert("No matching data available for the selected devices.");
          return;
        }
        //export data
        if (exportFormat === "csv") {
          const headers = Object.keys(mappedData[0][0])
            .map((header) => `"${HEADER_MAP[header] || header}"`)
            .join(",");
          const csvData = mappedData
            .map((device) => {
              return device
                .map((device) => {
                  return Object.values(device)
                    .map((value) => `"${String(value).replace(/"/g, '""')}"`)
                    .join(",");
                })
                .join("\n");
            })
            .join("\n");
          const csvString = headers + "\n" + csvData;

          const csvBlob = new Blob([csvString], { type: "text/csv" });
          const csvUrl = URL.createObjectURL(csvBlob);
          const a = document.createElement("a");
          a.href = csvUrl;
          a.download = "export.csv";
          a.click();

          setExportingString("Download complete.");
          setTimeout(() => {
            setExportingString("");
          }, 5000);
        } else if (exportFormat === "xls") {
          let headers = Object.keys(mappedData[0][0]);
          if (includeImages) headers.push("image"); // Add 'image' column to headers
          // headers = headers.filter((header) => header !== "img_link_url"); // Remove 'pict' column from headers
          const workbook = new ExcelJS.Workbook();
          const worksheet = workbook.addWorksheet("Sheet1");

          // Add headers
          worksheet.addRow(
            headers.map((header) => HEADER_MAP[header] || header)
          );

          // Add data
          mappedData.forEach((device) => {
            device.forEach((deviceData) => {
              const row = headers.map((header) => {
                if (header === "image") {
                  return null;
                  return `https://flashflood.info:8282/${deviceData["IMEI"]}/${deviceData["pict"]}`;
                } else {
                  return deviceData[header];
                }
              });
              worksheet.addRow(row);
            });
          });
          worksheet.eachRow((row, rowNumber) => {
            if (includeImages && rowNumber !== 1) row.height = 160;
            row.eachCell((cell) => {
              cell.alignment = { vertical: "top", wrapText: true };
            });
          });

          worksheet.columns.forEach((column, index) => {
            let maxLength = 0;
            column.eachCell({ includeEmpty: true }, (cell) => {
              const columnLength = cell.value
                ? cell.value.toString().length
                : 10;
              if (columnLength > maxLength) {
                maxLength = columnLength;
              }
            });
            column.width = maxLength + 2; // Add some padding to the width
          });
          // Add images
          const initTime = new Date().getTime();
          const addImages = async () => {
            if (!includeImages) {
              return;
            }

            let rowIndex = 1;

            const fetchPromises = [];
            const uniquePicts = new Set();
            const imageFetchMap = new Map();

            for (let device of mappedData) {
              for (let [i, deviceData] of device.entries()) {
                // const imageUrl = `https://flashflood.info:8282/${data["IMEI"]}/${data["pict"]}`;
                const imageUrl = deviceData["img_link_url"];

                if (!imageUrl) {
                  continue;
                }

                if (uniquePicts.has(imageUrl)) {
                  //   "Skipping duplicate image",
                  //   i + 1,
                  //   "/",
                  //   device.length
                  // );
                  fetchPromises.push(imageFetchMap.get(imageUrl));
                  continue;
                }

                uniquePicts.add(imageUrl);

                const localImageUrl = `${CUSTOM_URL}${TEAM_PREFIX}/fusion/serve-image?url=${encodeURIComponent(
                  // hfjdkshaflkdsa
                  imageUrl
                )}`;

                const fetchPromise = fetch(localImageUrl)
                  .then((response) => {
                    if (!response.ok) {
                      console.error(
                        `Fetch failed for URL: ${localImageUrl} with status: ${response.status}`
                      );
                      throw new Error(`HTTP error! status: ${response.status}`);
                    }

                    setExportingString(
                      `Adding images: ${i + 1}/${
                        device.length * mappedData.length
                      }`
                    );
                    return response.arrayBuffer();
                  })
                  .catch((error) => {
                    console.error(
                      `Error fetching image via backend: ${localImageUrl}, error`
                    );
                    throw error;
                  });

                // const fetchPromise = fetch(imageUrl).then((response) => {
                //   setExportingString(
                //     `Adding images: ${i + 1}/${device.length * mappedData.length}`
                //   );
                //   return response.arrayBuffer();
                // });

                imageFetchMap.set(imageUrl, fetchPromise);
                fetchPromises.push(fetchPromise);
              }
            }

            const imageBuffers = await Promise.all(fetchPromises);

            setExportingString("Building file...");
            let bufferIndex = 0;
            for (let device of mappedData) {
              for (let [i, deviceData] of device.entries()) {
                const imageUrl = deviceData["img_link_url"];

                if (!imageUrl) {
                  continue;
                }

                const imageBuffer = imageBuffers[bufferIndex++];
                const imageId = workbook.addImage({
                  buffer: imageBuffer,
                  extension: "jpeg",
                });
                worksheet.addImage(imageId, {
                  tl: { col: headers.indexOf("image"), row: rowIndex++ },
                  ext: { width: 64 * 4, height: 48 * 4 },
                });
              }
            }
            setExportingString("Downloading...");
          };

          addImages()
            .then(() => {
              // Generate Excel file and trigger download
              const workbookStartTime = new Date().getTime();
              workbook.xlsx
                .writeBuffer()
                .then((buffer) => {
                  const workbookEndTime = new Date().getTime();
                  const blob = new Blob([buffer], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                  });
                  const url = URL.createObjectURL(blob);
                  const a = document.createElement("a");
                  a.href = url;
                  a.download = "export.xlsx";
                  a.click();
                  setExportingString("Download complete.");
                  const currentTime3 = new Date().getTime();
                  setTimeout(() => {
                    setExportingString("");
                  }, 5000);
                })
                .catch((err) => {
                  console.error(err);
                  setExportingString("Download failed.");
                });
            })
            .catch((err) => {
              console.error(err);
              setExportingString("Failed to add images.");
            });
        }
      });

    //export data
  };

  return (
    <div
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        background: theme.base,
        padding: 20,
        color: theme.fontColor,
        zIndex: "100000",
        display: "flex",
        flexDirection: "column",
        width: "70%",
        gap: 20,
        borderRadius: 10,
        border: "1px solid white",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            fontWeight: "bold",
            fontSize: 24,
          }}
        >
          Export Device Data
        </div>
        <button
          style={{
            fontSize: 18,
          }}
          onClick={() => setShowExportModal(false)}
        >
          X
        </button>
      </div>
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "space-between",
            padding: 10,
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 20,
              width: "100%",
            }}
          >
            Start Date
            <input
              style={{
                background: theme.primaryHighlight,
                padding: 10,
                borderRadius: 10,
                marginBottom: 10,
              }}
              type='datetime-local'
              value={customTimeInputs.start || formatDateTimeLocal(startTime)}
              onChange={(e) => {
                setCustomTimeImputs({
                  ...customTimeInputs,
                  start: e.target.value,
                });
                setStartTime(new Date(e.target.value).getTime());
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 20,
              width: "100%",
            }}
          >
            End Date
            <input
              style={{
                background: theme.primaryHighlight,
                padding: 10,
                borderRadius: 10,
              }}
              type='datetime-local'
              value={customTimeInputs.end || formatDateTimeLocal(endTime)}
              onChange={(e) => {
                setCustomTimeImputs({
                  ...customTimeInputs,
                  end: e.target.value,
                });

                setEndTime(new Date(e.target.value).getTime());
              }}
            />
          </div>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div style={{ width: "50%" }}>
          <div
            style={{
              fontWeight: "bold",
              marginBottom: 10,
            }}
          >
            Select Device(s)
          </div>
          <div style={{ marginBottom: 10 }}>
            <label style={{ display: "flex", gap: 10 }}>
              <input
                type='checkbox'
                checked={
                  data.length > 0 &&
                  selectedDevices.length ===
                    data.filter((device) => {
                      return device.system_type === "AWARE";
                    }).length
                }
                onChange={(e) => {
                  if (e.target.checked) {
                    const allDeviceIds = data
                      .map((device) => {
                        if (
                          device.system_type === "AWARE" &&
                          parseInt(device.id)
                        )
                          return parseInt(device.id);
                      })
                      .filter((deviceId) => deviceId !== undefined);
                    setSelectedDevices(allDeviceIds);
                  } else {
                    setSelectedDevices([]);
                  }
                }}
              />
              <div>Select All</div>
            </label>
          </div>
        </div>
        <div style={{ width: "50%" }}>
          <div
            style={{
              fontWeight: "bold",
              marginBottom: 10,
            }}
          >
            Data to Export
          </div>
          <div style={{ marginBottom: 10 }}>
            <label style={{ display: "flex", gap: 10 }}>
              <input
                type='checkbox'
                checked={
                  selectedFields.length ===
                  Object.keys(data[0].data[0]).length -
                    EXCLUDED_FIELDS.length -
                    BASE_FIELDS.length
                }
                onChange={(e) => {
                  if (e.target.checked) {
                    const allFields = Object.keys(data[0].data[0]).filter(
                      (field) => {
                        if (BASE_FIELDS.includes(field)) {
                          return false;
                        } else if (EXCLUDED_FIELDS.includes(field)) {
                          return false;
                        } else {
                          return true;
                        }
                      }
                    );
                    setSelectedFields(allFields);
                  } else {
                    setSelectedFields(BASE_FIELDS);
                  }
                }}
              />
              <div>Select All</div>
            </label>
          </div>
        </div>
      </div>
      <div style={{ display: "flex", gap: "7.5%" }}>
        <div
          style={{
            backgroundColor: theme.primary,
            height: 150,
            overflowY: "auto",
            padding: 10,
            width: "40%",
          }}
        >
          {data
            .filter((device) => {
              return device.system_type === "AWARE";
            })
            .map((device) => {
              return (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 20,
                    marginBottom: 10,
                  }}
                >
                  <label style={{ display: "flex", gap: 10 }}>
                    <input
                      type='checkbox'
                      checked={selectedDevices.includes(parseInt(device.id))}
                      onChange={() => {
                        if (selectedDevices.includes(parseInt(device.id))) {
                          const newSelectedDevices = selectedDevices.filter(
                            (id) => id !== parseInt(device.id)
                          );
                          setSelectedDevices(newSelectedDevices);
                        } else {
                          setSelectedDevices([
                            ...selectedDevices,
                            parseInt(device.id),
                          ]);
                        }
                      }}
                    />
                    <div>
                      {(device.device_type !== "Manual Road Closure"
                        ? device.static_id + " "
                        : "") + device.alias}
                    </div>
                  </label>
                </div>
              );
            })}
        </div>
        <div
          style={{
            backgroundColor: theme.primary,
            height: 150,
            overflowY: "auto",
            padding: 10,
            width: "40%",
          }}
        >
          {Object.keys(data[0].data[0])
            .filter((field) => {
              if (BASE_FIELDS.includes(field)) {
                return false;
              } else if (EXCLUDED_FIELDS.includes(field)) {
                return false;
              } else {
                return true;
              }
            })
            .sort((a, b) => {
              return HEADER_MAP[a] > HEADER_MAP[b] ? 1 : -1;
            })
            .map((field) => {
              return (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 20,
                    marginBottom: 10,
                  }}
                >
                  <label style={{ display: "flex", gap: 10 }}>
                    <input
                      type='checkbox'
                      checked={selectedFields.includes(field)}
                      onChange={() => {
                        if (selectedFields.includes(field)) {
                          const newSelectedFields = selectedFields.filter(
                            (f) => f !== field
                          );
                          setSelectedFields(newSelectedFields);
                        } else {
                          setSelectedFields([...selectedFields, field]);
                        }
                      }}
                    />
                    <div>{HEADER_MAP[field] || field}</div>
                  </label>
                </div>
              );
            })}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
        }}
      >
        <div>
          <div
            style={{
              fontWeight: "bold",
              fontSize: 16,
              marginBottom: 10,
            }}
          >
            Export Document Format
          </div>
          <div style={{ marginBottom: 10, display: "flex" }}>
            <label style={{ marginRight: 10, display: "flex", gap: 10 }}>
              <input
                type='checkbox'
                checked={exportFormat === "csv"}
                onChange={() => setExportFormat("csv")}
              />
              <div style={{ marginleft: 10, marginRight: 30 }}>CSV</div>
            </label>
            <label style={{ marginRight: 10, display: "flex", gap: 10 }}>
              <input
                type='checkbox'
                checked={exportFormat === "xls"}
                onChange={() => setExportFormat("xls")}
              />
              <div style={{ marginleft: 10, marginRight: 20 }}>Excel</div>
            </label>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignContent: "center",
            gap: 20,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "right",
              gap: 10,
            }}
          >
            {exportFormat === "xls" && (
              <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                <input
                  type='checkbox'
                  checked={includeImages}
                  onChange={() => setIncludeImages(!includeImages)}
                />
                <div>Include Images {`(Larger file)`}</div>
              </div>
            )}
            {exportingString || " "}
          </div>
          <button
            style={{
              padding: 20,
              backgroundColor: theme.primaryHighlight,
              color: theme.fontColor,
              border: "none",
              borderRadius: 10,
              cursor: "pointer",
            }}
            onClick={() => {
              handleExport();
            }}
          >
            <div
              style={{
                fontWeight: "bold",
                fontSize: 16,
              }}
            >
              Export
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ExportModal;
