import { ColorContext, DeviceContext } from "App";
import TextToggle from "components/UI/TextToggle";
import React, { useContext, useEffect, useState } from "react";
import { GetDeviceTypeString } from "utils/stringUtils";
import { displayStatusOptions } from "utils/iconUtils";
import RescAlertDeviceSettings from "pages/System/DeviceSettings/RescAlertDeviceSettings";
import AudioPreview from "components/UI/AudioPreview";

export function VerifyToggleFlashingLights(dataObj) {
  if (!dataObj) return false;

  return !(dataObj?.status === "" || dataObj?.comment === "");
}

export default function ToggleFlashingLights({
  onUpdate,
  deviceList,
  dataObj,
}) {
  const theme = useContext(ColorContext);
  const deviceContext = useContext(DeviceContext);

  useEffect(() => {
    if (!dataObj.status) {
      onUpdate({ status: 0 });
    }
  }, []);

  const sirenSelector = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          alignContent: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            width: "30%",
          }}
        >
          Siren Tone:
        </div>
        <div
          style={{
            width: "70%",
            // padding: 5,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <select
              style={{
                background: theme.primaryShadow,
                height: 40,
                marginTop: 10,
                // marginBottom: 20,
                borderRadius: 10,
                paddingLeft: 15,
                width: "100%",
              }}
              value={dataObj.status}
              onChange={(e) => {
                const tone = deviceContext.deviceTypes[
                  dataObj.selectedDevice.system_type
                ][dataObj.selectedDevice.device_type.split("_")[0]][
                  dataObj.selectedDevice.device_type.split("_")[1]
                ].sirenTypes.find((tone) => tone.relay_id == e.target.value);
                onUpdate({ status: tone.relay_id });
              }}
            >
              {deviceContext.deviceTypes[dataObj.selectedDevice.system_type][
                dataObj.selectedDevice.device_type.split("_")[0]
              ][
                dataObj.selectedDevice.device_type.split("_")[1]
              ]?.sirenTypes.map((tone) => {
                return (
                  <option key={tone.name} value={tone.relay_id}>
                    {tone.displayName}
                  </option>
                );
              })}
            </select>
            <AudioPreview
              type="siren"
              tone={deviceContext.deviceTypes[
                dataObj.selectedDevice.system_type
              ][dataObj.selectedDevice.device_type.split("_")[0]][
                dataObj.selectedDevice.device_type.split("_")[1]
              ].sirenTypes.find((tone) => tone.relay_id == dataObj?.status)}
              roadStatus={dataObj.selectedDevice.status}
              setRoadStatus={(value) => onUpdate({ status: value })}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: 20,
        borderRadius: 10,
        margin: 10,
        background: theme.primary,
        display: "flex",
        gap: 20,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          alignItems: "center",
        }}
      >
        <div style={{ flex: 1 }}>Device Alias:</div>
        <select
          style={{ flex: 1, background: theme.base, padding: 5 }}
          onChange={(e) => {
            const device = deviceList.find((device) => {
              return device.node_id.toString() === e.target.value;
            });
            if (device)
              onUpdate({
                node_id: e.target.value,
                selectedDevice: device,
              });
          }}
          value={dataObj.node_id}
        >
          <option value="">-Select-</option>
          {deviceList &&
            deviceList.map((device) => {
              if (GetDeviceTypeString(device) === dataObj.deviceType)
                return (
                  <option value={device.node_id}>
                    {(device.device_type !== "Manual Road Closure"
                      ? device.static_id + " "
                      : "") + device.alias}
                  </option>
                );
            })}
        </select>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          alignItems: "center",
        }}
      >
        <div style={{ flex: 1 }}>Status</div>
        <div
          style={{ flex: 1, display: "flex", flexDirection: "row", gap: 10 }}
        >
          <TextToggle
            value={![0, 7, 8].includes(dataObj?.status)}
            setValue={(value) => {
              if (
                dataObj?.selectedDevice.device_type ===
                "Technology Bridge_Siren/Giant Voice"
              ) {
                onUpdate({ status: value ? 2 : 0 });
              } else {
                onUpdate({ status: value ? 1 : 0 });
              }
            }}
            options={displayStatusOptions(dataObj)}
            optionColorOn={
              [0, 7, 8].includes(dataObj?.status) ? "white" : "#C73B1D"
            }
            optionTextColorOn={
              [0, 7, 8].includes(dataObj?.status) ? "black" : "white"
            }
            optionBorder1="2px solid #01b574"
          />
          {dataObj?.selectedDevice.device_type ===
            "Technology Bridge_Siren/Giant Voice" && (
            <div
              style={{
                padding: 10,
                borderRadius: 5,
                cursor: "pointer",
                textAlign: "center",
                backgroundColor:
                  dataObj?.status === 7
                    ? theme.primaryHighlight
                    : theme.primaryShadow,

                // width: 80,
              }}
              onClick={() => {
                onUpdate({ status: dataObj?.status === 7 ? 0 : 7 });
              }}
            >
              Cancel Audio
            </div>
          )}
          {dataObj?.selectedDevice.device_type ===
            "Technology Bridge_Siren/Giant Voice" && (
            <div
              style={{
                padding: 10,
                borderRadius: 5,
                cursor: "pointer",
                textAlign: "center",
                backgroundColor:
                  dataObj?.status === 8
                    ? theme.primaryHighlight
                    : theme.primaryShadow,

                // width: 80,
              }}
              onClick={() => {
                onUpdate({ status: dataObj?.status === 8 ? 0 : 8 });
              }}
            >
              Power Cycle Device
            </div>
          )}
        </div>
      </div>
      {dataObj?.selectedDevice.device_type ===
        "Technology Bridge_Siren/Giant Voice" && (
        <div>
          {(dataObj?.status == 1 ||
            dataObj?.status == 2 ||
            dataObj?.status == 6) &&
            sirenSelector()}
        </div>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          alignItems: "center",
        }}
      >
        <div style={{ flex: 1 }}>Comments</div>
        <textarea
          rows={4}
          type="text"
          value={dataObj.comment}
          style={{
            border: "1px solid white",
            width: "75%",
            padding: 5,
          }}
          onChange={(e) => onUpdate({ comment: e.target.value })}
          placeholder="(Optional)"
        />
      </div>
    </div>
  );
}
