import React, { useContext, useEffect, useState } from "react";
import * as api from "apis/FusionAPI";
import { AlertListPage } from "components/AlertListPage";
import { usePerms } from "hooks/usePerms";
import { useNavigate } from "react-router-dom";
import WidgetTimeUpdate from "components/Widgets/WidgetTimeUpdate";
import { UniqueWidgetRefreshContext } from "App";
import Cookies from "js-cookie";
import WidgetLoadingAnimation from "components/Widgets/WidgetLoadingAnimation";
import useDataRefresh from "hooks/useDataRefresh";

export default function Alerts({
  dimensions,
  sidebarOpen,
  filterAlerts,
}) {
  const [alertsList, FsetAlertsList] = useState([]);
  const [fetching, setFetching] = useState(true);

  const setAlertsList = (newList) => {
    console.trace("Alerts list update:", newList);
    FsetAlertsList(newList);
  }
  
  const perms = usePerms();
  const navigate = useNavigate();

  const uwf = useContext(UniqueWidgetRefreshContext);
  const ROUTE = "getAllActiveAlerts";
  const LOCATION = Cookies.get("city") + "-" + Cookies.get("state");
  const UNIQUEKEY = LOCATION + "-" + ROUTE;
  const key = uwf[UNIQUEKEY];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.getAllActiveAlerts();

        //get distance for each alert
        const alerts = response.data.alerts;

        setAlertsList(alerts);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setFetching(false);
      }
    };

    fetchData();
  }, [key]);

  const dataRefresh = useDataRefresh()
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await api.getAllActiveAlerts(); // FusionData.get(ROUTE);
          
          const filteredAlerts = filterAlerts ? filterAlerts(response.data.alerts) : response.data.alerts;
  
          setAlertsList(filteredAlerts);
          setFetching(false);
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          setFetching(false);
        }
      };
  
      fetchData();
    }, [dataRefresh.widgetRefresh, filterAlerts]);

  useEffect(() => {
    (async () => {
      if (!(await perms.validatePermissions(["ViewAlerts"]))) {
        navigate("/404");
        return;
      }
    })();
  }, [navigate, perms]);

  if (fetching) {
    return <WidgetLoadingAnimation widgetName={"ActiveAlerts"} dimensions={dimensions}/>;
  }

  if (perms.testPermission([8, 9, 10, 11, 12, 13], ["WeatherAlerts"]))
    if (dimensions) {
      return (
        <div>
          {fetching ? (
            <div>fetching</div>
          ) : (
            <AlertListPage
              widget
              dimensions={dimensions}
              title={"Active Weather Alerts"}
              alertsList={alertsList}
              setAlertsList={setAlertsList}
              sidebarOpen={sidebarOpen}
              ROUTE={ROUTE}
            />
          )}
        </div>
      );
    } else {
      return (
        <div style={{height: "calc(100% - 20px)"}}>
          <WidgetTimeUpdate route={ROUTE}  realtime dataUpdateOnly addMargin />
          <AlertListPage
            title={"Active Weather Alerts"}
            alertsList={alertsList}
            setAlertsList={setAlertsList}
            sidebarOpen={sidebarOpen}
            ROUTE={ROUTE}
          />
        </div>
      );
    }
}
