import useTextToSpeech from "hooks/useTextToSpeech";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import * as api from "../apis/FusionAPI";
import { useAlarms } from "hooks";

export default function AlarmListener({ isAuthenticated }) {
  const alarms = useAlarms(true);
  const tts = useTextToSpeech();

  const runAlarms = () => {
    if (tts.isSpeaking() || tts.isPending()) {
      return;
    }

    let readAlarmsList = [
      {
        text: "Pending Alarms",
        id: -1,
        index: -1,
        silenced: false,
      },
    ];

    for (let i = alarms.alarms.length - 1; i >= 0; i--) {
      let alarm = alarms.alarms[i];
      readAlarmsList.push({
        text: alarm.info.type || "",
        id: alarm.id,
        index: i,
        silenced: alarm.silenced,
      });
    }

    const speakList = [];
    readAlarmsList.forEach((item) => {
      if (!item.silenced && item.source !== "Overwatch AI") {
        speakList.push({ text: item.text, repeat: true });
      } else if (!item.silenced && item.source === "Overwatch AI") {
        speakList.push({ text: item.text, repeat: false });
      }
    });

    if (speakList.length > 1) {
      tts.beginNewQueue(speakList, false);
    }

    if (speakList.length < 2) {
      tts.cancel();
    }
  };

  useEffect(() => {
    if (alarms?.alarms?.length > 0) runAlarms();
  }, [alarms.alarms]);

  return null;
}
