import React, { useContext, useEffect, useState } from "react";
import * as d3 from "d3";
import { RiArrowRightSLine, RiArrowDownSLine } from "react-icons/ri";
import { MapContainer, TileLayer, Polygon, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import * as api from "../apis/FusionAPI";
import { splitByCharacter } from "utils/stringUtils";
import { usePerms } from "hooks/usePerms";
import { ColorContext } from "App";

export function AlertCard({
  widget,
  dimensions,
  index,
  alert,
  getAlerts,
  canCloseAlert,
  selectedAlert,
  setSelectedAlert,
  setMapCenter,
  setMapPolygon,
}) {
  const [showInfo, setShowInfo] = useState(false);
  const theme = useContext(ColorContext);

  const perms = usePerms();

  const colorScale = d3
    .scaleLinear()
    .domain([0, 66, 100])
    .range(["#CD2C2E", "#EE8918", "#34A100"]);

  useEffect(() => {
    if (index === 0) {
      if (alert.processedPolygon && alert.processedCenter && setSelectedAlert) {
        setSelectedAlert(alert);
        setMapCenter(alert.processedCenter);
        setMapPolygon(alert.processedPolygon);
      }
    }
  }, [alert.processedPolygon, alert.processedCenter, setSelectedAlert]);

  const closeAlert = async () => {
    if (!perms.testPermission([8, 9, 10, 11, 12, 13], ["DeleteAlerts"])) return;

    try {
      api.setAlertEnd(alert.message_id, parseInt(alert.workplaceId));
    } catch (e) {}
  };

  function ChangeView({ center, bounds }) {
    const map = useMap();
    map.setView(center, map.getBoundsZoom(bounds));
    return null;
  }

  function getSeverityLabel(severity) {
    if (severity >= 0 && severity <= 24) return "High";
    if (severity >= 25 && severity <= 49) return "Med-High";
    if (severity >= 50 && severity <= 74) return "Med";
    if (severity >= 75 && severity <= 100) return "Low";
  }

  if (!widget) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          backgroundColor: theme.primary,
          borderRadius: 10,
          marginTop: 10,
          width: "100%",
        }}
      >
        <div
          style={{
            backgroundColor: colorScale(alert.severity),
            width: 30,
            marginRight: 20,
            borderTopLeftRadius: 10,
            borderBottomLeftRadius: 10,
          }}
        />

        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div
              onClick={() => setShowInfo(!showInfo)}
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                borderBottom: showInfo ? "solid grey 1px" : "",
                cursor: "pointer",
                justifyContent: "space-between",
              }}
            >
              <div style={{ margin: 10, marginRight: 10, width: 400 }}>
                {/* <h1>{index}</h1> */}
                <h1>{alert.type}</h1>
                <h3>{alert.sender}</h3>
                <h3>
                  In Effect {alert.sentTime} - {alert.endTime}
                </h3>
                {alert.processedCenter && (
                  <h3>
                    Coordinates: {Number(alert.processedCenter[0]).toFixed(5)}° N,{" "}
                    {Number(alert.processedCenter[1]).toFixed(5)}° W
                  </h3>
                )}
              </div>

              <div
                key="map"
                style={{ margin: 5, height: "150px", width: "200px" }}
                onClick={(e) => e.stopPropagation()}
              >
                {alert.processedCenter && alert.processedPolygon && alert.processedPolygon[0][0] !== 0 && (
                  <MapContainer
                    center={alert.processedCenter}
                    zoom={11}
                    style={{ height: "100%", width: "100%" }}
                  >
                    <ChangeView center={alert.processedCenter} bounds={alert.processedPolygon} />
                    <TileLayer
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    />
                    {alert.processedPolygon.map((poly, index) => (
                      <Polygon key={index} positions={poly} />
                    ))}
                  </MapContainer>
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  width: 80,
                }}
              >
                {canCloseAlert &&
                  perms.testPermission(
                    [8, 9, 10, 11, 12, 13],
                    ["DeleteAlerts"]
                  ) && (
                    <button
                      style={{
                        backgroundColor: "green",
                        borderRadius: 10,
                        padding: 10,
                        margin: 5,
                      }}
                      onClick={() => {
                        if (
                          window.confirm(
                            "Are you sure you want to close this alert? This action cannot be undone."
                          )
                        ) {
                          closeAlert();
                          getAlerts();
                        }
                      }}
                    >
                      Close
                    </button>
                  )}
                <button onClick={() => setShowInfo(!showInfo)}>
                  {showInfo ? (
                    <RiArrowDownSLine size={30} />
                  ) : (
                    <RiArrowRightSLine size={30} />
                  )}
                </button>
              </div>
            </div>
          </div>

          {showInfo && (
            <div style={{ paddingRight: 15 }}>
              <div>
                <div
                  style={{
                    marginTop: 10,
                    marginBottom: 15,
                    fontSize: 20,
                    fontWeight: "bold",
                  }}
                >
                  {alert.provider?.toLowerCase().includes("fusion") && (
                    <div style={{ padding: 2 }}>
                      <div>{alert.type}</div>
                      <div>
                        Until: {new Date(alert.endTime).toLocaleString()}
                      </div>
                    </div>
                  )}
                  {splitByCharacter(alert.headline, ";").map(
                    (line, index) => {
                      if (line.includes("till:")) {
                        // Parse the date string to a Date object
                        const parsedDate = new Date(
                          line.replace("till:", "")
                        );

                        // Get the current year
                        const currentYear = new Date().getFullYear();

                        // Set the year of the parsed date to the current year
                        parsedDate.setFullYear(currentYear);

                        // Convert the modified date back to a locale string
                        const updatedDateLocaleString =
                          parsedDate.toLocaleString();
                        return (
                          <div key={index} style={{ padding: 2 }}>
                            Until: {updatedDateLocaleString}
                          </div>
                        );
                      } else
                        return (
                          <div key={index} style={{ padding: 2 }}>
                            {line}
                          </div>
                        );
                    }
                  )}
                </div>
                <div style={{ marginBottom: 15 }}>
                  {splitByCharacter(alert.description, "*").map(
                    (line, index) => {
                      if (line)
                        return (
                          <li key={index} style={{ padding: 2 }}>
                            {line}
                          </li>
                        );
                    }
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  if (widget) {
    if (dimensions[0] === 1 && dimensions[1] === 1) {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: 1,
          }}
        >
          <div
            style={{
              backgroundColor: colorScale(alert.severity),
              width: 30,
              height: 70,
              marginRight: 5,
              borderTopLeftRadius: 10,
              borderBottomLeftRadius: 10,
            }}
          />
          <p style={{ width: 90 }}>{getSeverityLabel(alert.severity)}</p>
          <p style={{ width: 150, textAlign: "center" }}>{alert.type}</p>
          <p style={{ width: 220, textAlign: "center" }}>{alert.sender}</p>
        </div>
      );
    }

    if (dimensions[0] === 1 && dimensions[1] === 2) {
      return (
        <div
          onClick={() => {
            setSelectedAlert(alert);
            setMapCenter(alert.processedCenter);
            setMapPolygon(alert.processedPolygon);
          }}
          style={{
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            marginBottom: 1,
            borderRadius: 10,
            backgroundColor:
              selectedAlert?.message_id === alert.message_id
                ? theme.primary
                : "",
          }}
        >
          <div
            style={{
              backgroundColor: colorScale(alert.severity),
              width: 30,
              height: 70,
              marginRight: 5,
              borderTopLeftRadius: 10,
              borderBottomLeftRadius: 10,
            }}
          />
          <p style={{ width: 90 }}>{getSeverityLabel(alert.severity)}</p>
          <p style={{ width: 150, textAlign: "center" }}>{alert.type}</p>
          <p style={{ width: 220, textAlign: "center" }}>{alert.sender}</p>
        </div>
      );
    }

    if (dimensions[0] === 2 && dimensions[1] === 1) {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: 1,
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: 140,
            }}
          >
            <div
              style={{
                backgroundColor: colorScale(alert.severity),
                width: 30,
                height: 70,
                marginRight: 5,
                borderTopLeftRadius: 10,
                borderBottomLeftRadius: 10,
              }}
            />
            <p>{getSeverityLabel(alert.severity)}</p>
          </div>
          <p style={{ width: 200 }}>{alert.type}</p>
          <p style={{ width: 200 }}>{alert.sender}</p>
          {canCloseAlert ? (
            <button
              style={{
                backgroundColor: "green",
                borderRadius: 10,
                padding: 10,
                width: 150,
                height: 50,
                marginLeft: 45,
              }}
              onClick={() => {
                if (
                  window.confirm(
                    "Are you sure you want to close this alert? This action cannot be undone."
                  )
                ) {
                  closeAlert();
                  getAlerts();
                }
              }}
            >
              Close
            </button>
          ) : (
            <p style={{ width: 150 }}>Active</p>
          )}
        </div>
      );
    }
  }
}
