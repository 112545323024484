import React, { useContext, useState } from "react";
import { IoMenu } from "react-icons/io5";
import * as api from "apis/FusionAPI";

import openLogo from "assets/Sidebar_Logo_Open.png";
import openLogoDEV from "assets/Sidebar_Logo_Open_DEV.png";
import openLogoSTAGING from "assets/Sidebar_Logo_Open_STAGING.png";
import openLogoPITTSBURGHPA from "assets/Sidebar_Logo_Open_PittsburghPA.png";
import openLogoCOMALTX from "assets/Sidebar_Logo_Open_ComalTX.png";
import Cookies from "js-cookie";
import {
  FaChevronLeft,
  FaCog,
  FaLock,
  FaPaintBrush,
  FaUser,
} from "react-icons/fa";
import { FiLogOut } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { usePerms } from "hooks/usePerms";
import { clearCookies } from "utils/cookieUtils";


const MobileHeader = ({
  setIsAuthenticated,
  setShowFull,
  setNewTermsFullyAgreed,
  setTermsFullyViewed,
  isAuthenticated,
  setHasSignedIn,
  setHasFetchedPerms,
  setShowThemeSelector,
  showThemeSelector,
}) => {
  const navigate = useNavigate();
  const { instance, accounts, inProgress } = useMsal();
  const isMsalAuthenticated = useIsAuthenticated();
  const perms = usePerms();

  const getLogo = () => {
    const environment = api._Environment();

    if (environment === "PITTSBURGHPA") {
      return openLogoPITTSBURGHPA;
    }
    if (environment === "COMALTX") {
      return openLogoCOMALTX;
    }
    if (environment === "DEV") {
      return openLogoDEV;
    } else if (environment === "STAGING") {
      return openLogoSTAGING;
    } else return openLogo;
  };

  const [menuOpen, setMenuOpen] = useState(false);

  const handleLogout = () => {
    // setShowFull(Cookies.get("rememberMe") !== "true");
    if (isMsalAuthenticated) {
      // instance.removeAccount(accounts[0]);
      instance.logoutRedirect({
        onRedirectNavigate: (url) => {
          return false;
        },
      });
    }
    setShowFull(true);
    if (isAuthenticated) api.logout();
    let keepCookies = [];
    if (Cookies.get("rememberMe") === "true") {
      keepCookies = ["rememberMe", "email"];
    }
    clearCookies(keepCookies);
    perms.reset();
    setHasFetchedPerms(false);
    setIsAuthenticated(false);
    setNewTermsFullyAgreed(false);
    setTermsFullyViewed(false);
    setHasSignedIn(false);
    navigate("/");
  };

  const handleNavigateHome = () => {
    setMenuOpen(false);
    navigate("/mobile");
  };

  const MenuItem = ({ name, icon, onPress, style, backgroundColor }) => {
    return (
      <button onClick={onPress}>
        <div
          className={`listItem border-bottom center ${
            backgroundColor ? backgroundColor : "bg-base"
          }`}
          style={{
            padding: 10,
            ...style,
          }}
        >
          {icon} {name}
        </div>
      </button>
    );
  };

  const Menu = ({ open = false }) => {
    return (
      <div
        className="menu border-bottom bg-primary"
        style={{
          position: open && menuOpen ? "fixed" : "",
          top: open && 0,
          width: open && "100%",
        }}
      >
        <div className="header">
          <div
            style={{
              opacity: 0,
            }}
          >
            <button className="button">
              <IoMenu size={40} />
            </button>
          </div>
          <div className="logo">
            <button onClick={handleNavigateHome}>
              <img src={getLogo()} alt="Fusion Logo" />
            </button>
          </div>

          <div>
            <button
              className="button"
              onClick={() => {
                setMenuOpen(!menuOpen);
              }}
            >
              <IoMenu size={40} />
            </button>
          </div>
        </div>
        {open && (
          <div className="expandedMenu">
            <MenuItem
              backgroundColor="bg-primaryShadow"
              name={Cookies.get("username")}
              icon={<FaUser />}
              onPress={() => {}}
              // style={{
              //   backgroundColor: colors.primaryShadow,
              // }}
            />
            <MenuItem
              name={"Privacy Settings"}
              icon={<FaLock />}
              onPress={() => {
                setShowFull(true);
              }}
            />
            <MenuItem
              name="Logout"
              icon={<FiLogOut />}
              onPress={handleLogout}
            />
            {/* {process.env.NODE_ENV === "development" && (
              <MenuItem
                name="Theme Settings (devtool)"
                icon={<FaPaintBrush />}
                onPress={() => {
                  setShowThemeSelector(!showThemeSelector);
                }}
              />
            )} */}
          </div>
        )}
      </div>
    );
  };

  return (
    <div>
      <Menu />
      {menuOpen && <Menu open />}
    </div>
  );
};

export default MobileHeader;
