import React from "react";
import Signin from "pages/Signin";

const MobileSignIn = ({
  setShowFull,
  hasSignedIn,
  setHasSignedIn,
  setAuthenticated,
  widgetRefresh,
  setCookiesAgreed,
  setPrivacyAgreed,
  setTermsAgreed,
  setNewTermsFullyAgreed,
  newTermsFullyAgreed,
  cookiesAgreed,
  privacyAgreed,
  termsAgreed,
  termsVersion,
  policyVersion,
  termsDate,
  terms,
  termsFullyViewed,
  setTermsFullyViewed,
  setMobileLoadingSignin,
  mobileLoadingSignin,
}) => {
  return (
    <Signin
      mobile
      setShowFull={setShowFull}
      hasSignedIn={hasSignedIn}
      setHasSignedIn={setHasSignedIn}
      setAuthenticated={setAuthenticated}
      widgetRefresh={widgetRefresh}
      setCookiesAgreed={setCookiesAgreed}
      setPrivacyAgreed={setPrivacyAgreed}
      setTermsAgreed={setTermsAgreed}
      setNewTermsFullyAgreed={setNewTermsFullyAgreed}
      newTermsFullyAgreed={newTermsFullyAgreed}
      cookiesAgreed={cookiesAgreed}
      privacyAgreed={privacyAgreed}
      termsAgreed={termsAgreed}
      termsVersion={termsVersion}
      policyVersion={policyVersion}
      termsDate={termsDate}
      terms={terms}
      termsFullyViewed={termsFullyViewed}
      setTermsFullyViewed={setTermsFullyViewed}
      setMobileLoadingSignin={setMobileLoadingSignin}
      mobileLoadingSignin={mobileLoadingSignin}
    />
  );
};

export default MobileSignIn;
