import { useEffect, useRef, useState } from "react";
import * as api from "apis/FusionAPI";
import useDebounce from "./useDebounce";

export const LIMIT_OPTIONS = [25, 50, 100];

/**
 * Use alarm data fetched from fusion api.
 * @param {boolean} [pending] True = Use pending alarms, False = Use alarm history
 * @returns {{totalCount: number, alarms: {ackowledged: *[], activity: string, ca_identifier: string, cw_identifier: string, device: string, expire_time: string, ga_identifier: string, headline: string, id: number, info: Object, message: string, organization_id: number, silenced: boolean, silenced_by: (string | number | null), source: string, state: boolean}[], fetchAlarms: (limit: number, page: number, search: string), setLimit: (limit: number), setPage: (page: number), setSearch: (search: string), LIMIT_OPTIONS: number[]}}
 */
export default function useAlarms(pending = false) {
  const [totalCount, setTotalCount] = useState(0);
  const [alarms, setAlarms] = useState();

  const [limit, setLimit] = useState(LIMIT_OPTIONS[0]);

  const [page, setPage] = useState(0);

  const [search, setSearch] = useState("");
  const searchDebounce = useDebounce(search, 1000);

  const [eventReceived, setEventReceived] = useState(0);
  const eventReceivedDebounce = useDebounce(eventReceived, 1000);

  useEffect(() => {
    const eventSource = api.connectToLiveAlarms();

    eventSource.onmessage = async (msg) => {
      console.log("Notified of alarm change, re-fetching.");
      setEventReceived(Math.random());
    };

    eventSource.onerror = (err) => {
      console.log("Error from server:", err);
      eventSource.close();
    };

    eventSource.onclose = (e) => {
      console.log("Close request from server:", e);
      eventSource.close();
    };

    fetchAlarms(LIMIT_OPTIONS[0], 0);

    return () => {
      eventSource.close();
    };
  }, []);

  useEffect(() => {
    setPage(0);
    fetchAlarms(limit, 0, searchDebounce);
  }, [searchDebounce]);

  useEffect(() => {
    if (eventReceivedDebounce > 0) {
      fetchAlarms(limit, page * limit, search);
      setEventReceived(0);
    }
  }, [eventReceivedDebounce]);

  const fetchAlarms = async (_limit, _offset, _search) => {
    if (pending)
      await api
        .getPendingAlarmsPaginated(_limit, _offset, _search)
        .then((res) => {
          console.log("fetchAlarms returned with:", res);
          setAlarms(res.data.data);
          setTotalCount(res.data.totalCount);
        })
        .catch((error) =>
          console.error("Error in useAlarms.fetchAlarmData:", error)
        );
    else
      await api
        .getAlarmHistoryPaginated(_limit, _offset, _search)
        .then((res) => {
          console.log("fetchAlarms returned with:", res);
          setAlarms(res.data.data);
          setTotalCount(res.data.totalCount);
        })
        .catch((error) =>
          console.error("Error in useAlarms.fetchAlarmData:", error)
        );
  };

  return {
    totalCount: totalCount,
    alarms: alarms,
    limit,
    setLimit: (_limit) => {
      setLimit(_limit);
      setPage(0);
      fetchAlarms(_limit, 0, search);
    },
    page,
    setPage: (_page) => {
      setPage(_page);
      fetchAlarms(limit, _page * limit, search);
    },
    search,
    setSearch: (_search) => {
      setSearch(_search);
    },
    LIMIT_OPTIONS: LIMIT_OPTIONS,
  };
}
