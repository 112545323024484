import { Slider } from "antd";
import { ColorContext } from "App";
import { usePerms } from "hooks/usePerms";
import React, { useContext, useEffect, useState } from "react";
import { alertColorScale, getSeverityLevel } from "utils/alertUtils";
import { EllipsisMenu } from "components";
import aware from "assets/icons/AWAREICON.png";
import default1 from "assets/icons/Default System Icon 1.png";
import default2 from "assets/icons/Default System Icon 2.png";
import background from "assets/icons/Default System Icon Background.png";
import styles from "./AlarmContentRow.module.css";
import "styles/global.css";
import classNames from "classnames";
import { BsCheck } from "react-icons/bs";
import { IoClose } from "react-icons/io5";
import { FaBellSlash } from "react-icons/fa";

const iconMap = {
  AWARE: aware,
  "Fusion User": default1,
  "global-actions": default1,
  "Overwatch AI": default2,
  Other: background,
};

/**
 * A row view displaying information and controls for alarms.
 * @param {{ alarm: {index: number, ackowledged: *[], activity: string, ca_identifier: string, cw_identifier: string, device: string, expire_time: string, ga_identifier: string, headline: string, id: number, info: Object, message: string, organization_id: number, silenced: boolean, silenced_by: (string | number | null), source: string, state: boolean}, controlsEnabled?: boolean, selected?: boolean, silenced?: boolean, onSelect?: (), onAcknowledge?: (), onSilence?: ()}}
 * @returns React Component
 */
export default function AlarmContentRow({
  alarm,
  controlsEnabled = false,
  selected = false,
  silenced = false,
  onSelect = () => {},
  onAcknowledge = () => {},
  onSilence = () => {},
}) {
  const theme = useContext(ColorContext);
  const perms = usePerms();
  const [timeSinceActivity, setTimeSinceActivity] = useState("");
  const [headlineShort, setHeadlineShort] = useState("");
  const [controlsOpen, setControlsOpen] = useState(false);

  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    const msSinceActivity = Date.now() - new Date(alarm.activity);
    const seconds = Math.floor(msSinceActivity / 1000) % 60;
    const minutes = Math.floor(msSinceActivity / 1000 / 60) % 60;
    const hours = Math.floor(msSinceActivity / 1000 / 60 / 60) % 24;
    const days = Math.floor(msSinceActivity / 1000 / 60 / 60 / 24);

    setTimeSinceActivity(`
    ${days > 0 ? `${days} Days` : ""}\t
    ${hours >= 10 ? `${hours}` : `0${hours}`}:
    ${minutes >= 10 ? `${minutes}` : `0${minutes}`}:
    ${seconds >= 10 ? `${seconds}` : `0${seconds}`} hours
    `);

    const prestring = alarm.headline;
    setHeadlineShort(alarm.headline.split(";")[0]);
    alarm.headline = prestring;
  }, [alarm]);

  // Event Listeners
  const acknowledge = () => {
    setControlsOpen(false);
    onAcknowledge();
  };

  const silence = () => {
    setControlsOpen(false);
    onSilence();
  };

  // Helper functions
  const keySort = (a, b) => {
    const keys = [
      "type",
      "startTime",
      "expireTime",
      "location",
      "lat",
      "lon",
      "nwsoffice",
      "alarm_id",
    ];

    for (const key of keys) {
      if (a.toLowerCase() === key) return -1;
      if (b.toLowerCase() === key) return 1;
    }

    return a.localeCompare(b);
  };

  const parseKey = (key) => {
    switch (key.toLowerCase()) {
      case "type":
        return "Alert Type";
      case "nwsoffice":
        return "Issuing Office";
      case "starttime":
        return "Start Time";
      case "expiretime":
        return "Expiration Time";
      case "severity":
        return "Severity";
      case "timezone":
        return "Time Zone";
      case "latitude":
        return "Latitude";
      case "longitude":
        return "Longitude";
      case "location":
        return "Location";
      default:
        return "";
    }
  };

  const getAlarmId = () => {
    if (alarm.ga_identifier) return `GA_${alarm.ga_identifier}`;
    else if (alarm.ca_identifier) return `CA_${alarm.ca_identifier}`;
    else if (alarm.cw_identifier) return `CW_${alarm.cw_identifier}`;
    else return `ID_${alarm.id}`;
  };

  const sourceStringCorrection = (str) => {
    return str.replace("global-actions", "Global Action");
  };

  const getKeyValue = (key) => {
    if (key.toLowerCase() !== "severity")
      return `${parseKey(key)}: ${alarm.info[key]}`;

    return (
      <div className={styles["slider"]}>
        <div
          className={classNames(styles["body"], styles["slider-text"])}
          style={{ backgroundColor: alertColorScale(alarm.info[key]) }}
        >
          {parseKey(key)}: {getSeverityLevel(alarm.info[key])}
        </div>
        <div
          style={{
            backgroundColor: theme.base,
            width: 200,
            marginLeft: 10,
            borderRadius: 5,
          }}
        >
          <Slider
            min={0}
            max={100}
            defaultValue={100 - alarm.info[key]}
            disabled
          />
        </div>
      </div>
    );
  };

  return (
    <div
      className={classNames(
        styles["content-row"],
        styles["root"],
        expanded ? styles["expanded"] : styles["collapsed"]
      )}
      style={{
        "--color": expanded
          ? theme.primaryHighlight
          : alarm.index % 2 === 0
          ? theme.base
          : "#2C373B",
      }}
      onClick={() => setExpanded(!expanded)}
    >
      <div className={classNames(styles["content-row"], styles["header"])}>
        <div className={classNames(styles["content-row"], styles["section"])}>
          {controlsEnabled && (
            <div
              className={classNames(
                selected ? "circle-fill" : "circle-border",
                "center-content",
                styles["inner-margin"]
              )}
              style={{
                "--radius": selected ? "16px" : "15px", //This is to remove motion when the circle changes from border to
              }}
              onClick={(e) => {
                e.stopPropagation();
                onSelect();
              }}
            >
              {selected && <BsCheck color={"black"} size={25} />}
            </div>
          )}
          {alarm.state ? "Active" : "Inactive"}
          {Boolean(silenced) && (
            <div
              onClick={(e) => {
                e.stopPropagation();
                onSilence();
              }}
            >
              <FaBellSlash />
            </div>
          )}
        </div>
        <div className={classNames(styles["content-row"], styles["section"])}>
          <div
            className={classNames(
              "circle-fill",
              "circle-border",
              "center-content"
            )}
            style={{
              "--fill-color": theme.primaryShadow,
              "--border-color": theme.fontColor,
              "--radius": "22px",
            }}
          >
            <img src={iconMap[alarm.source]} alt={"source-icon"} size={25} />
          </div>
          {sourceStringCorrection(alarm.source)}
        </div>
        <div className={classNames(styles["content-row"], styles["section"])}>
          {alarm.device}
        </div>
        <div className={classNames(styles["content-row"], styles["section"])}>
          {headlineShort}
        </div>
        <div className={classNames(styles["content-row"], styles["section"])}>
          {new Date(alarm.activity).toLocaleString()}
        </div>
        <div className={classNames(styles["content-row"], styles["section"])}>
          {timeSinceActivity}
        </div>
        {/* Ellipsis Controls. * Optional render */}
        <div className={classNames(styles["content-row"], styles["section"])}>
          {controlsEnabled && (
            <EllipsisMenu
              options={[
                {
                  component: (
                    <AcknowledgeButton
                      onClick={acknowledge}
                      color={theme.primary}
                      fontColor={theme.fontColor}
                    />
                  ),
                },
                {
                  component: (
                    <SilenceButton
                      onClick={silence}
                      color={theme.primary}
                      fontColor={theme.fontColor}
                    />
                  ),
                },
              ]}
              open={controlsOpen}
              onOpen={() => setControlsOpen(true)}
              onClose={() => setControlsOpen(false)}
            />
          )}
        </div>
      </div>
      {expanded && (
        <div className={classNames(styles["content-row"], styles["body"])}>
          <div
            className={classNames(styles["content-row"], styles["headline"])}
          >
            {alarm.headline}
          </div>
          <div
            className={classNames(
              styles["content-row"],
              styles["body"],
              styles["message"]
            )}
          >
            {alarm.message
              .trim()
              .split("*")
              .map((line, index) => {
                if (line) return <li key={index}>{line}</li>;
                return <></>;
              })}
          </div>
          <div
            className={classNames(
              styles["content-row"],
              styles["body"],
              styles["info-grid"]
            )}
            style={{
              "--grid-size": `${Array(
                Math.floor(Math.sqrt(Object.keys(alarm.info).length))
              )
                .fill("1fr")
                .join(" ")}`,
            }}
          >
            {Object.keys(alarm.info)
              .filter(
                (key) =>
                  !["timezone", "popaffected"].includes(key.toLowerCase()) //from what I can tell, we ignore the timezone and popaffected keys
              )
              .sort(keySort)
              .map((key) => (
                <div key={key}>{getKeyValue(key)}</div>
              ))}
            <div>{`Alarm ID: ${getAlarmId()}`}</div>
          </div>
        </div>
      )}
    </div>
  );
}

function AcknowledgeButton({ onClick, color, fontColor }) {
  return (
    <div className={styles["control-option"]} onClick={onClick}>
      <div
        className={classNames("circle-fill", "center-content")}
        style={{ "--fill-color": color, "--radius": "12px", color: fontColor }}
      >
        <BsCheck />
      </div>
      <div>Acknowledge</div>
    </div>
  );
}

function SilenceButton({ onClick, color, fontColor }) {
  return (
    <div className={styles["control-option"]} onClick={onClick}>
      <div
        className={classNames("circle-fill", "center-content")}
        style={{ "--fill-color": color, "--radius": "12px", color: fontColor }}
      >
        <IoClose />
      </div>
      <div>Silence</div>
    </div>
  );
}
