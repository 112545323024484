import React, { useState, useEffect, useContext } from "react";

import background from "../assets/Resc_Office.png";
import logo from "../assets/Sidebar_Logo_Open.png";
import logoDEV from "../assets/Sidebar_Logo_Open_DEV.png";
import logoSTAGING from "../assets/Sidebar_Logo_Open_STAGING.png";
import openLogoPITTSBURGHPA from "../assets/Sidebar_Logo_Open_PittsburghPA.png";
import openLogoCOMALTX from "../assets/Sidebar_Logo_Open_ComalTX.png";
import { useLocation, useNavigate } from "react-router-dom";

import * as api from "../apis/FusionAPI";
import Cookies from "js-cookie";
import Toggle from "components/UI/Toggle";
import { CustomInput } from "components/UI/CustomInput";
import { PrivacyPopup } from "./PrivacyPopup";
import { FaCheck } from "react-icons/fa";
import Checkbox from "components/UI/Checkbox";
import { usePerms } from "hooks/usePerms";
import { ColorContext } from "App";

import { useMsal, useAccount } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { Client } from "@microsoft/microsoft-graph-client";

import microsoftlogo from "../assets/microsoftlogo.png";
import { clearCookies } from "utils/cookieUtils";
import MobilePopup from "mobile/pages/MobilePopup";
import MobileLoadingMessage from "mobile/pages/MobileLoadingMessage";

let checkingLogin = false;

export default function Signin({
  setAuthenticated,
  widgetRefresh,
  termsAgreed,
  setTermsAgreed,
  privacyAgreed,
  setPrivacyAgreed,
  cookiesAgreed,
  setCookiesAgreed,
  policyVersion,
  termsVersion,
  setNewTermsFullyAgreed,
  newTermsFullyAgreed,
  termsDate,
  terms,
  termsFullyViewed,
  setTermsFullyViewed,
  hasSignedIn,
  setHasSignedIn,
  setShowFull,
  mobile = false,
  isAuthenticated,
  showFull,
  setIsAuthenticated,
  setMobileLoadingSignin,
  mobileLoadingSignin,
}) {
  const navigate = useNavigate();
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const [email, setEmail] = useState(Cookies.get("email") || "");
  const [password, setPassword] = useState("");
  const [resetEmail, setResetEmail] = useState(null);
  const [resetMessage, setResetMessage] = useState(null);
  const perms = usePerms();

  const [display, setDisplay] = useState("login");
  const [rememberMe, setRememberMe] = useState(
    Cookies.get("rememberMe") === "true"
  );

  //MICROSOFT LOGIN
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [apiData, setApiData] = useState(null);

  useEffect(() => {
    perms.reset();
    // setCookiesAgreed(false);
    // setPrivacyAgreed(false);
    // setTermsAgreed(false);
  }, []);

  const getLogo = () => {
    const environment = api._Environment();

    if (environment === "PITTSBURGHPA") {
      return openLogoPITTSBURGHPA;
    }
    if (environment === "COMALTX") {
      return openLogoCOMALTX;
    }
    if (environment === "DEV") {
      return logoDEV;
    } else if (environment === "STAGING") {
      return logoSTAGING;
    } else if (environment === "PROD") return logo;
    else return logo;
  };
  const theme = useContext(ColorContext);

  const [contactName, setContactName] = useState(null);
  const [contactEmail, setContactEmail] = useState(null);
  const [contactPhone, setContactPhone] = useState(null);
  const [contactSuccess, setContactSuccess] = useState(false);
  const [contactNotes, setContactNotes] = useState(null);
  const [signInProvider, setSignInProvider] = useState(null);
  const [microsoftButtonPressed, setMicrosoftButtonPressed] = useState(false);

  useEffect(() => {
    const onResize = () => {
      setDimensions({ width: window.innerWidth, height: window.innerHeight });
    };

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  // useEffect(() => {
  //   (async () => {
  //     if (!checkingLogin && newTermsFullyAgreed) {
  //       checkingLogin = true;
  //       const refreshToken = Cookies.get("refreshToken");
  //       const email = Cookies.get("email");

  //       if (refreshToken && email) {
  //         try {
  //           // When it comes to refreshing the token, the response immediately returns the object
  //           // There is no need for res.data the way most other API calls will require
  //           const res = await api.postRefresh(email, refreshToken);

  //           if (res.message === "User authenticated") {
  //             Cookies.set("accessToken", res.accessToken);
  //             if (rememberMe) Cookies.set("refreshToken", res.refreshToken);
  //             else {
  //               Cookies.set("refreshToken", res.refreshToken);
  //             }
  //             Cookies.set("email", email, { expires: 365 });
  //             setIsAccountActive(true);
  //             moveToDashboard();

  //             // setAuthenticated(true);
  //           }
  //         } catch (err) {
  //           Cookies.remove("accessToken");
  //           Cookies.remove("refreshToken");
  //           navigate("/");
  //           if (Cookies.get("rememberMe") === "false") Cookies.remove("email");
  //         }
  //       }
  //       checkingLogin = false;
  //     } else {
  //     }
  //   })();
  // }, [newTermsFullyAgreed]);

  useEffect(() => {
    if (Cookies.get("rememberMe") === "true") {
      setEmail(email);
      setRememberMe(true);

      if (Cookies.get("policyAgreed") == policyVersion) {
        setPrivacyAgreed(true);
        setCookiesAgreed(true);
      }
      if (Cookies.get("termsAgreed") == termsVersion) setTermsAgreed(true);
    } else {
      if (Cookies.get("rememberMe") === "false") Cookies.remove("email");
      setEmail("");
      setPassword("");
      setRememberMe(false);
    }
  }, []);

  const [isAccountActive, setIsAccountActive] = useState(false);
  const [signInError, setSignInError] = useState(null);
  const [msLoginTry, setMsLoginTry] = useState(false);

  const onMicrosoftSignIn = () => {
    // do stuff here
    setMicrosoftButtonPressed(true);

    if (instance) {
      try {
        instance
          .loginRedirect()
          .then(() => {
            setMsLoginTry(true);
          })
          .catch((e) => {
            alert(e);
          });
      } catch (e) {}
    } else {
      console.error("ms bad");
    }
  };

  const doNewSignIn = (provider) => {
    if (!provider && !(password && email)) {
      return;
    }
    setHasSignedIn(true);
    setSignInProvider(provider);
  };

  useEffect(() => {
    (async () => {
      if (mobile) {
      }
      if (!microsoftButtonPressed) {
        // return;
      }

      if (!account) {
        return;
      }

      if (mobile) setMobileLoadingSignin(true);

      const request = {
        scopes: ["User.Read"],
        account: account,
      };

      let msRes = null;
      if (account) {
        try {
          instance.setActiveAccount(account);
          msRes = await instance.acquireTokenSilent(request);
        } catch (err) {
          try {
            if (err instanceof InteractionRequiredAuthError) {
              msRes = await instance.acquireTokenPopup(request);
            }
          } catch (error) {
            console.error(error);
          }
        }
      }

      try {
        if (msRes) {
          Cookies.set("msToken", msRes.accessToken);

          // return
          if (
            Cookies.get("rememberMe") === "true" ||
            Cookies.get("rememberMe") === true ||
            rememberMe === true
          ) {
            Cookies.set("rememberMe", rememberMe, { expires: 365 });

            Cookies.set("policyAgreed", policyVersion, { expires: 365 });
            Cookies.set("termsAgreed", termsVersion, { expires: 365 });
          } else {
            if (Cookies.get("rememberMe") === "false") {
              Cookies.remove("email");
              Cookies.remove("rememberMe");
              Cookies.remove("policyAgreed");
              Cookies.remove("termsAgreed");
            }
          }
          api
            .login(
              email,
              password,
              termsAgreed && privacyAgreed && cookiesAgreed,
              termsVersion,
              policyVersion,
              {
                name: "microsoft-azure",
                ...msRes,
              },
              instance
            )
            .then((res) => {
              const { accessToken, refreshToken, userName, organization } =
                res.data;

              if (res.data.message === "User authenticated") {
                Cookies.set("accessToken", accessToken);
                Cookies.set("refreshToken", refreshToken);
                Cookies.set("email", email);
                Cookies.set("username", userName);
                Cookies.set("organizationName", organization);

                // setAuthenticated(true);
                setHasSignedIn(true);
                setIsAccountActive(res.data.activated.status === "Active");
                if (mobile) setMobileLoadingSignin(false);
                moveToDashboard();
              }
            })
            .catch((err) => {
              setSignInError(err.response.data.message);
              if (err.response.data.message === "API DISABLED") {
                setSignInError(
                  "License disabled, contact RescAlert to restore access."
                );
              }
              if (mobile) setMobileLoadingSignin(false);
              setHasSignedIn(false);
              setTermsFullyViewed(false);
            });
        } else {
        }
      } catch (err) {}
    })();
    // }, [account, instance]);
  }, [msLoginTry, account, instance]);

  const moveToDashboard = () => {
    //change to switch if we implement more providers
    if (mobile) setMobileLoadingSignin(false);
    if (signInProvider === "microsoft-azure") {
      api.ms_syncAllUsers(instance).catch(() => {});
    }

    setAuthenticated(true);
    navigate("/dashboard");
  };

  const onSignIn = () => {
    perms.reset();
    try {
      if (
        Cookies.get("rememberMe") === "true" ||
        Cookies.get("rememberMe") === true ||
        rememberMe === true
      ) {
        Cookies.set("email", email, { expires: 365 });
        Cookies.set("rememberMe", rememberMe, { expires: 365 });

        Cookies.set("policyAgreed", policyVersion, { expires: 365 });
        Cookies.set("termsAgreed", termsVersion, { expires: 365 });
      } else {
        if (Cookies.get("rememberMe") === "false") {
          Cookies.remove("email");
          Cookies.remove("rememberMe");
          Cookies.remove("policyAgreed");
          Cookies.remove("termsAgreed");
        }
      }
      api
        .login(
          email,
          password,
          termsAgreed && privacyAgreed && cookiesAgreed,
          termsVersion,
          policyVersion
        )
        .then((res) => {
          const { accessToken, refreshToken, userName, organization } =
            res.data;

          if (res.data.message === "User authenticated") {
            Cookies.set("accessToken", accessToken);
            if (rememberMe) Cookies.set("refreshToken", refreshToken);
            else {
              Cookies.set("refreshToken", refreshToken);
            }
            Cookies.set("email", email, { expires: 365 });
            Cookies.set("username", userName);
            Cookies.set("organizationName", organization);

            // setAuthenticated(true);
            setIsAccountActive(res.data.activated.status === "Active");
            moveToDashboard();
          }
        })
        .catch((err) => {
          setSignInError(err.response.data.message);
          if (err.response.data.message === "API DISABLED") {
            setSignInError(
              "License disabled, contact RescAlert to restore access."
            );
          }
          setHasSignedIn(false);
          setTermsFullyViewed(false);
        });
    } catch (err) {}
  };

  useEffect(() => {
    setTermsFullyViewed(false);
    setNewTermsFullyAgreed(false);
  }, []);

  const onSignUp = () => {
    navigate("/register");
  };

  const onPasswordReset = () => {
    setResetMessage("An email has been sent to reset your password.");
  };

  const onContactUs = () => {
    if (!contactName || !contactEmail || !contactPhone) {
      return;
    }

    try {
      api
        .submitFusionInquiry(
          contactName,
          contactEmail,
          contactPhone,
          contactNotes
        )
        .then((res) => {
          setContactSuccess(true);
        })
        .catch((err) => {});
    } catch (err) {}
  };

  const onRememberToggle = (val) => {
    setRememberMe(val);
    if (!val) {
      if (Cookies.get("rememberMe") === "false") Cookies.remove("email");
    }
    // setRememberMe(!rememberMe);
  };

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;

    // Check if the content height is less than or equal to the container height
    if (scrollHeight <= clientHeight) {
      setTermsFullyViewed(true);
    } else if (scrollTop + clientHeight >= scrollHeight) {
      setTermsFullyViewed(true);
    }
  };
  if (mobile) {
    if (!newTermsFullyAgreed && hasSignedIn) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            // width: "100%",
            // height: "100%",
            padding: 20,
          }}
        >
          <img
            src={getLogo()}
            style={{
              width: "80%",
            }}
          />
          <h1>Terms of Use</h1>
          <p>This Terms of Use Policy was last modified on {termsDate}</p>
          <div
            className="customScrollbar-Y"
            style={{
              background: theme.primaryShadow,
              overflow: "auto",
              width: "90%",
              marginTop: 40,
              padding: 20,
              height: "50%",
              whiteSpace: "pre-wrap",
              display: "flex",
              flexDirection: "column",
              maxHeight: "40vh",
            }}
            onScroll={handleScroll}
          >
            <div
              className="html-content"
              dangerouslySetInnerHTML={{ __html: terms }}
            ></div>
          </div>
          {!termsFullyViewed && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                gap: 20,
                marginTop: 20,
              }}
            >
              <input
                type="checkbox"
                value={termsFullyViewed}
                onChange={(e) => {
                  setTermsFullyViewed(e.target.checked);
                }}
              />
              I have read the Terms of Use
            </div>
          )}
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <button
                style={{
                  backgroundColor: theme.background,
                  border: "1px solid white",
                  color: theme.fontColor,
                  padding: 10,
                  margin: 10,
                  marginLeft: 0,
                  cursor: "pointer",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  clearCookies();
                  setNewTermsFullyAgreed(false);
                  setTermsFullyViewed(false);
                  setPrivacyAgreed(false);
                  setCookiesAgreed(false);
                  setHasSignedIn(false);
                  setShowFull(true);
                }}
              >
                Decline
              </button>
              <button
                style={{
                  backgroundColor: termsFullyViewed ? "#ffffff" : "#777777",
                  border: "1px solid #0b1826",
                  color: theme.fontColor,
                  padding: 10,
                  margin: 10,
                  cursor: termsFullyViewed ? "pointer" : "not-allowed",
                  color: theme.background,
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  if (privacyAgreed && cookiesAgreed) {
                    if (signInProvider === "microsoft-azure") {
                      onMicrosoftSignIn();
                    } else {
                      onSignIn();
                    }
                  } else {
                    alert("Please accept the Privacy & Cookie Policy");
                  }
                }}
              >
                Accept Terms of Use
              </button>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: dimensions.width,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            height: dimensions.height,
            background: theme.background,
            color: theme.fontColor,
            alignItems: "center",
            justifyContent: "space-around",
          }}
        >
          {/* Logo */}
          <img
            src={getLogo()}
            style={{
              width: "80%",
            }}
          />
          {/* Forum */}
          {display === "login" && (
            <div
              style={{
                //   backgroundColor: "blue",
                display: "flex",
                flexDirection: "column",
                width: 350,
                height: 456.25,
                justifyContent: "space-around",
              }}
            >
              {/* Message */}
              <div>
                <div>
                  <h1>Nice to see you!</h1>
                </div>
                <div style={{ color: "#A0AEC0" }}>
                  Enter your email and password to sign in
                </div>
              </div>

              {/* Fields */}
              <div style={{ width: 350 }}>
                <div style={{ marginLeft: 15, marginBottom: 5 }}>Email</div>
                <CustomInput
                  callback={setEmail}
                  value={email}
                  type="text"
                  placeholder="Your email address"
                  readonly
                />
              </div>
              <div style={{ width: 350 }}>
                <div style={{ marginLeft: 15, marginBottom: 5 }}>Password</div>

                <CustomInput
                  callback={setPassword}
                  // value={password}
                  type="password"
                  placeholder="Your password"
                />
              </div>

              {/* Remember switch */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Toggle
                  callback={onRememberToggle}
                  value={rememberMe}
                  size={20}
                />
                <div style={{ marginLeft: 15 }}>Remember me</div>
              </div>

              {/* Error messages */}
              {signInError && <div style={{ color: "red" }}>{signInError}</div>}
              {/* Buttons */}

              <button
                onClick={() => {
                  if (privacyAgreed && cookiesAgreed) {
                    doNewSignIn();
                  } else {
                    alert("Please accept the Privacy & Cookie Policy");
                  }
                }}
              >
                <div
                  style={{
                    backgroundColor:
                      privacyAgreed && cookiesAgreed
                        ? theme.primaryHighlight
                        : theme.primaryShadow,
                    display: "flex",
                    width: 350,
                    height: 46.57,
                    textAlign: "center",
                    alignItems: "center",
                    justifyContent: "space-around",
                    borderRadius: 15,
                  }}
                >
                  SIGN IN
                </div>
              </button>
              <button
                onClick={() => {
                  if (privacyAgreed && cookiesAgreed) {
                    doNewSignIn("microsoft-azure");
                  } else {
                    alert("Please accept the Privacy & Cookie Policy");
                  }
                }}
              >
                <div
                  style={{
                    backgroundColor:
                      privacyAgreed && cookiesAgreed
                        ? theme.primaryHighlight
                        : theme.primaryShadow,
                    display: "flex",
                    width: 350,
                    height: 46.57,
                    textAlign: "center",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: 15,
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                >
                  <img
                    src={microsoftlogo}
                    style={{ height: 20, marginRight: 10 }}
                  />
                  <p>Sign in with Microsoft</p>
                </div>
              </button>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: 15,
                }}
              >
                {/* Contact Us */}
                <div style={{ color: "#A0AEC0" }}>
                  Don't have an account?{" "}
                  <span
                    onClick={() => {
                      setDisplay("contact");
                    }}
                    style={{
                      color: theme.fontColor,
                      textDecoration: "none",
                      cursor: "pointer",
                    }}
                  >
                    Contact Us
                  </span>
                </div>

                {/* Forgot password */}
                <a
                  href={`${mobile ? "/mobile" : ""}/forgotpassword`}
                  style={{
                    color: theme.fontColor,
                    textDecoration: "none",
                    cursor: "pointer",
                  }}
                >
                  Forgot Password?
                </a>
              </div>
              {/* <PrivacyPopup
                showFull={showFull}
                setShowFull={setShowFull}
                isAuthenticated={isAuthenticated}
                acceptAction={() => {
                  setCookiesAgreed(true);
                  setPrivacyAgreed(true);
                }}
                declineAction={() => {
                  if (isAuthenticated) api.logout();
                  setCookiesAgreed(false);
                  setPrivacyAgreed(false);
                  setNewTermsFullyAgreed(false);
                  setTermsFullyViewed(false);
                  setHasSignedIn(false);
                  setIsAuthenticated(false);
                  clearCookies();
                }}
              /> */}
            </div>
          )}

          {/* Forum */}
          {display === "contact" && !contactSuccess && (
            <div
              style={{
                //   backgroundColor: "blue",
                display: "flex",
                flexDirection: "column",
                width: 350,
                height: 600,
                justifyContent: "space-around",
              }}
            >
              {/* Message */}
              <div>
                <div>
                  <h1>Welcome!</h1>
                </div>
                <div style={{ color: "#A0AEC0" }}>
                  Enter your information so we can contact you with information
                  on how to create an account.
                </div>
              </div>

              {/* Fields */}
              <div style={{ width: 350 }}>
                <div style={{ marginLeft: 15, marginBottom: 5 }}>Name</div>

                <CustomInput
                  callback={setContactName}
                  type="text"
                  placeholder="Name or Organization Name"
                />
              </div>
              <div style={{ width: 350 }}>
                <div style={{ marginLeft: 15, marginBottom: 5 }}>
                  Phone Number
                </div>

                <CustomInput
                  callback={setContactPhone}
                  type="text"
                  placeholder="Phone Number"
                />
              </div>
              <div style={{ width: 350 }}>
                <div style={{ marginLeft: 15, marginBottom: 5 }}>Email</div>

                <CustomInput
                  callback={setContactEmail}
                  type="text"
                  placeholder="Email Address"
                />
              </div>
              <div style={{ width: 350 }}>
                <div style={{ marginLeft: 15, marginBottom: 5 }}>
                  Notes (optional)
                </div>
                <CustomInput
                  callback={setContactNotes}
                  type="text"
                  placeholder="Enter any additional information"
                  textarea
                  height={100}
                />
              </div>

              {/* Remember switch */}
              {/* <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  height: 20,
                  width: 40,
                  borderRadius: 20,
                  cursor: "pointer",
                  alignItems: "center",
                  background: rememberMe ? theme.primaryHighlight : theme.base,

                }}
                onClick={(e) => {
                 Cookies.set("rememberMe", !rememberMe)
                 setRememberMe(!rememberMe)
               }}
              >
                <div
                  style={{
                    height: 15,
                    borderRadius: 20,
                    width: 15,
                    background:'white',
                    marginLeft: rememberMe ? 21 : 4,
                  }}
                >

                </div>
              </div>
              <div style={{marginLeft: 15}}>Remember me</div>

            </div> */}

              {/* Error messages */}
              {/* Buttons */}
              <button onClick={() => onContactUs()}>
                <div
                  style={{
                    backgroundColor: theme.primaryHighlight,
                    display: "flex",
                    width: 350,
                    height: 46.57,
                    textAlign: "center",
                    alignItems: "center",
                    justifyContent: "space-around",
                    borderRadius: 15,
                  }}
                >
                  SEND
                </div>
              </button>

              {/* Sign up (Not used)*/}
              {/* <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignSelf: "center",
                width: 204,
                height: 20.72,
                fontSize: 14,
                alignItems: "center",
                justifyContent: "space-around",
              }}
            >
              <div>Dont have an account?</div>
              <button onClick={() => onSignUp()}>
                <div>Sign up</div>
              </button>
            </div> */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: 15,
                }}
              >
                {/* Contact Us */}
                <div style={{ color: "#A0AEC0" }}>
                  Already have an account?{" "}
                  <span
                    onClick={() => {
                      setDisplay("login");
                    }}
                    style={{
                      color: theme.fontColor,
                      textDecoration: "none",
                      cursor: "pointer",
                    }}
                  >
                    Sign in
                  </span>
                </div>
              </div>
            </div>
          )}

          {/* Forum */}
          {display === "contact" && contactSuccess && (
            <div
              style={{
                //   backgroundColor: "blue",
                display: "flex",
                flexDirection: "column",
                width: 350,
                height: 456.25,
                justifyContent: "space-around",
              }}
            >
              {/* Message */}
              <div>
                <div>
                  <h1>Thank You!</h1>
                </div>
                <div style={{ color: "#A0AEC0" }}>
                  We will contact you as soon as possible with more information.
                  Thank you for your interest.
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: 15,
                }}
              >
                {/* Contact Us */}

                {/* <div style={{color: "#A0AEC0", marginTop: 20}}>
                Had a issue? <span onClick={() => {setContactSuccess(false)}} style={{color:'white', textDecoration:'none', cursor:'pointer'}}>Try again</span>
              </div> */}
                <button onClick={() => setDisplay("login")}>
                  <div
                    style={{
                      backgroundColor: theme.primaryHighlight,
                      display: "flex",
                      width: 350,
                      height: 46.57,
                      textAlign: "center",
                      alignItems: "center",
                      justifyContent: "space-around",
                      borderRadius: 15,
                    }}
                  >
                    Return to Sign In
                  </div>
                </button>
              </div>
            </div>
          )}

          {/* Footer */}
          <div
            style={{
              //   backgroundColor: "blue",
              display: "flex",
              width: 250,
              height: 52.56,
              textAlign: "center",
              alignItems: "center",
            }}
          >
            <div>
              @ 2023 - {new Date().getFullYear()}, Powered by RescAlert Inc.
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (!newTermsFullyAgreed && hasSignedIn) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <div
          style={{
            backgroundImage: `url(${getLogo()})`,
            backgroundSize: 289,
            width: 289,
            height: 126,
            alignSelf: "flex-start",
          }}
        />
        <h1>Terms of Use</h1>
        <p>This Terms of Use Policy was last modified on {termsDate}</p>
        <div
          className="customScrollbar-Y"
          style={{
            background: theme.primaryShadow,
            overflow: "auto",
            width: "90%",
            marginTop: 40,
            padding: 20,
            height: "50%",
            whiteSpace: "pre-wrap",
            display: "flex",
            flexDirection: "column",
          }}
          onScroll={handleScroll}
        >
          <div
            className="html-content"
            dangerouslySetInnerHTML={{ __html: terms }}
          ></div>
        </div>
        {!termsFullyViewed && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              gap: 20,
              marginTop: 20,
            }}
          >
            <input
              type="checkbox"
              value={termsFullyViewed}
              onChange={(e) => {
                setTermsFullyViewed(e.target.checked);
              }}
            />
            I have read the Terms of Use
          </div>
        )}
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <button
              style={{
                backgroundColor: theme.background,
                border: "1px solid white",
                color: theme.fontColor,
                padding: 10,
                margin: 10,
                marginLeft: 0,
                cursor: "pointer",
              }}
              onClick={(e) => {
                e.stopPropagation();
                clearCookies();
                setNewTermsFullyAgreed(false);
                setTermsFullyViewed(false);
                setPrivacyAgreed(false);
                setCookiesAgreed(false);
                setHasSignedIn(false);
                setShowFull(true);
              }}
            >
              Decline
            </button>
            <button
              style={{
                backgroundColor: termsFullyViewed ? "#ffffff" : "#777777",
                border: "1px solid #0b1826",
                color: theme.fontColor,
                padding: 10,
                margin: 10,
                cursor: termsFullyViewed ? "pointer" : "not-allowed",
                color: theme.background,
              }}
              onClick={(e) => {
                e.stopPropagation();
                if (privacyAgreed && cookiesAgreed) {
                  if (signInProvider === "microsoft-azure") onMicrosoftSignIn();
                  else {
                    onSignIn();
                  }
                } else {
                  alert("Please accept the Privacy & Cookie Policy");
                }
              }}
            >
              Accept Terms of Use
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      style={{
        userSelect: "none",
        position: "absolute",
        top: 0,
        left: 0,
        zIndex: 100,
      }}
    >
      {/* Background */}
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: -999,
          backgroundImage: `url(${background})`,
          backgroundSize: "cover",
          minWidth: dimensions.width / 2,
          minHeight: dimensions.height,
          backgroundPositionX: "60%",
          backgroundPositionY: "100%",
        }}
      />
      {/* Foreground */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: dimensions.width,
        }}
      >
        <div
          style={{
            display: "flex",
            // flex: 1,
            width: dimensions.width / 2,
            height: dimensions.height,
            color: theme.fontColor,
            alignItems: "end",
            justifyContent: "space-around",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              // width: 616,
              height: 75,
              textAlign: "center",
              marginBottom: 113,
              WebkitTextStroke: "0.3px black",
            }}
          >
            <div style={{ fontSize: 20 }}>Overwatch Fusion </div>
            <div style={{ fontSize: 36 }}>Powered by RescAlert Inc.</div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            height: dimensions.height,
            background: theme.background,
            color: theme.fontColor,
            alignItems: "center",
            justifyContent: "space-around",
          }}
        >
          {/* Logo */}
          <div
            style={{
              backgroundImage: `url(${getLogo()})`,
              backgroundSize: 391,
              width: 389,
              height: 171,
            }}
          />
          {/* Forum */}
          {display === "login" && (
            <div
              style={{
                //   backgroundColor: "blue",
                display: "flex",
                flexDirection: "column",
                width: 350,
                height: 456.25,
                justifyContent: "space-around",
              }}
            >
              {/* Message */}
              <div>
                <div>
                  <h1>Nice to see you!</h1>
                </div>
                <div style={{ color: "#A0AEC0" }}>
                  Enter your email and password to sign in
                </div>
              </div>

              {/* Fields */}
              <div style={{ width: 350 }}>
                <div style={{ marginLeft: 15, marginBottom: 5 }}>Email</div>
                <CustomInput
                  callback={setEmail}
                  value={email}
                  type="text"
                  placeholder="Your email address"
                  readonly
                />
              </div>
              <div style={{ width: 350 }}>
                <div style={{ marginLeft: 15, marginBottom: 5 }}>Password</div>

                <CustomInput
                  callback={setPassword}
                  // value={password}
                  type="password"
                  placeholder="Your password"
                />
              </div>

              {/* Remember switch */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Toggle
                  callback={onRememberToggle}
                  value={rememberMe}
                  size={20}
                />
                <div style={{ marginLeft: 15 }}>Remember me</div>
              </div>

              {/* Error messages */}
              {signInError && <div style={{ color: "red" }}>{signInError}</div>}
              {/* Buttons */}
              <div
                style={{
                  marginTop: 20,
                  marginBottom: 20,
                }}
              >
                {/* <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                    gap: 20,
                  }}
                >
                  <Checkbox value={termsAgreed} callback={setTermsAgreed} />
                  <div>
                    Accept{" "}
                    <a
                      style={{
                        color: "#A0AEC0",
                        cursor: "pointer",
                      }}
                      href="/terms"
                      target="_blank"
                    >
                      Terms of Use
                    </a>
                  </div>
                </div> */}
                {/* <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    gap: 20,
                  }}
                >
                  <input
                    type="checkbox"
                    value={privacyAgreed}
                    onChange={(e) => {
                      setPrivacyAgreed(e.target.checked);
                    }}
                  />
                  <div>
                    Accept{" "}
                    <a
                      style={{
                        color: "#A0AEC0",
                        cursor: "pointer",
                      }}
                      href="URL_TO_PRIVACY"
                      target="_blank"
                    >
                      Privacy Policy
                    </a>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    gap: 20,
                  }}
                >
                  <input
                    type="checkbox"
                    value={cookiesAgreed}
                    onChange={(e) => {
                      setCookiesAgreed(e.target.checked);
                    }}
                  />
                  <div>
                    Accept{" "}
                    <a
                      style={{
                        color: "#A0AEC0",
                        cursor: "pointer",
                      }}
                      href="URL_TO_COOKIES"
                      target="_blank"
                    >
                      Cookie Agreement
                    </a>
                  </div>
                </div> */}
              </div>
              {/* {(!privacyAgreed || !termsAgreed || !cookiesAgreed) && (
                <div style={{ color: "red", justifyContent: "center" }}>
                  Please agree to {!termsAgreed && "Terms of Use"}{" "}
                  {!(termsAgreed || privacyAgreed || cookiesAgreed) && "and"}{" "}
                  {!(privacyAgreed || cookiesAgreed) &&
                    "Privacy & Cookie Policy"}{" "}
                  to continue
                </div>
              )} */}
              <button
                onClick={() => {
                  if (privacyAgreed && cookiesAgreed) {
                    doNewSignIn();
                  } else {
                    alert("Please accept the Privacy & Cookie Policy");
                  }
                }}
              >
                <div
                  style={{
                    backgroundColor:
                      privacyAgreed && cookiesAgreed
                        ? theme.primaryHighlight
                        : theme.primaryShadow,
                    display: "flex",
                    width: 350,
                    height: 46.57,
                    textAlign: "center",
                    alignItems: "center",
                    justifyContent: "space-around",
                    borderRadius: 15,
                  }}
                >
                  SIGN IN
                </div>
              </button>
              <button
                onClick={() => {
                  if (privacyAgreed && cookiesAgreed) {
                    doNewSignIn("microsoft-azure");
                  } else {
                    alert("Please accept the Privacy & Cookie Policy");
                  }
                }}
              >
                <div
                  style={{
                    backgroundColor:
                      privacyAgreed && cookiesAgreed
                        ? theme.primaryHighlight
                        : theme.primaryShadow,
                    display: "flex",
                    width: 350,
                    height: 46.57,
                    textAlign: "center",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: 15,
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                >
                  <img
                    src={microsoftlogo}
                    style={{ height: 20, marginRight: 10 }}
                  />
                  <p>Sign in with Microsoft</p>
                </div>
              </button>

              {/* Sign up (Not used)*/}
              {/* <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignSelf: "center",
                width: 204,
                height: 20.72,
                fontSize: 14,
                alignItems: "center",
                justifyContent: "space-around",
              }}
            >
              <div>Dont have an account?</div>
              <button onClick={() => onSignUp()}>
                <div>Sign up</div>
              </button>
            </div> */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: 15,
                }}
              >
                {/* Contact Us */}
                <div style={{ color: "#A0AEC0" }}>
                  Don't have an account?{" "}
                  <span
                    onClick={() => {
                      setDisplay("contact");
                    }}
                    style={{
                      color: theme.fontColor,
                      textDecoration: "none",
                      cursor: "pointer",
                    }}
                  >
                    Contact Us
                  </span>
                </div>

                {/* Forgot password */}
                <a
                  href="/forgotpassword"
                  style={{
                    color: theme.fontColor,
                    textDecoration: "none",
                    cursor: "pointer",
                  }}
                >
                  Forgot Password?
                </a>
              </div>
              {/* <PrivacyPopup
                acceptAction={() => {
                  setPrivacyAgreed(true);
                  setCookiesAgreed(true);
                }}
                declineAction={() => {
                  setPrivacyAgreed(false);
                  setCookiesAgreed(false);
                }}
              /> */}
            </div>
          )}

          {/* Forum */}
          {display === "contact" && !contactSuccess && (
            <div
              style={{
                //   backgroundColor: "blue",
                display: "flex",
                flexDirection: "column",
                width: 350,
                height: 600,
                justifyContent: "space-around",
              }}
            >
              {/* Message */}
              <div>
                <div>
                  <h1>Welcome!</h1>
                </div>
                <div style={{ color: "#A0AEC0" }}>
                  Enter your information so we can contact you with information
                  on how to create an account.
                </div>
              </div>

              {/* Fields */}
              <div style={{ width: 350 }}>
                <div style={{ marginLeft: 15, marginBottom: 5 }}>Name</div>

                <CustomInput
                  callback={setContactName}
                  type="text"
                  placeholder="Name or Organization Name"
                />
              </div>
              <div style={{ width: 350 }}>
                <div style={{ marginLeft: 15, marginBottom: 5 }}>
                  Phone Number
                </div>

                <CustomInput
                  callback={setContactPhone}
                  type="text"
                  placeholder="Phone Number"
                />
              </div>
              <div style={{ width: 350 }}>
                <div style={{ marginLeft: 15, marginBottom: 5 }}>Email</div>

                <CustomInput
                  callback={setContactEmail}
                  type="text"
                  placeholder="Email Address"
                />
              </div>
              <div style={{ width: 350 }}>
                <div style={{ marginLeft: 15, marginBottom: 5 }}>
                  Notes (optional)
                </div>
                <CustomInput
                  callback={setContactNotes}
                  type="text"
                  placeholder="Enter any additional information"
                  textarea
                  height={100}
                />
              </div>

              {/* Remember switch */}
              {/* <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  height: 20,
                  width: 40,
                  borderRadius: 20,
                  cursor: "pointer",
                  alignItems: "center",
                  background: rememberMe ? theme.primaryHighlight : theme.base,

                }}
                onClick={(e) => {
                 Cookies.set("rememberMe", !rememberMe)
                 setRememberMe(!rememberMe)
               }}
              >
                <div
                  style={{
                    height: 15,
                    borderRadius: 20,
                    width: 15,
                    background:'white',
                    marginLeft: rememberMe ? 21 : 4,
                  }}
                >

                </div>
              </div>
              <div style={{marginLeft: 15}}>Remember me</div>

            </div> */}

              {/* Error messages */}
              {/* Buttons */}
              <button onClick={() => onContactUs()}>
                <div
                  style={{
                    backgroundColor: theme.primaryHighlight,
                    display: "flex",
                    width: 350,
                    height: 46.57,
                    textAlign: "center",
                    alignItems: "center",
                    justifyContent: "space-around",
                    borderRadius: 15,
                  }}
                >
                  SEND
                </div>
              </button>

              {/* Sign up (Not used)*/}
              {/* <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignSelf: "center",
                width: 204,
                height: 20.72,
                fontSize: 14,
                alignItems: "center",
                justifyContent: "space-around",
              }}
            >
              <div>Dont have an account?</div>
              <button onClick={() => onSignUp()}>
                <div>Sign up</div>
              </button>
            </div> */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: 15,
                }}
              >
                {/* Contact Us */}
                <div style={{ color: "#A0AEC0" }}>
                  Already have an account?{" "}
                  <span
                    onClick={() => {
                      setDisplay("login");
                    }}
                    style={{
                      color: theme.fontColor,
                      textDecoration: "none",
                      cursor: "pointer",
                    }}
                  >
                    Sign in
                  </span>
                </div>
              </div>
            </div>
          )}

          {/* Forum */}
          {display === "contact" && contactSuccess && (
            <div
              style={{
                //   backgroundColor: "blue",
                display: "flex",
                flexDirection: "column",
                width: 350,
                height: 456.25,
                justifyContent: "space-around",
              }}
            >
              {/* Message */}
              <div>
                <div>
                  <h1>Thank You!</h1>
                </div>
                <div style={{ color: "#A0AEC0" }}>
                  We will contact you as soon as possible with more information.
                  Thank you for your interest.
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: 15,
                }}
              >
                {/* Contact Us */}

                {/* <div style={{color: "#A0AEC0", marginTop: 20}}>
                Had a issue? <span onClick={() => {setContactSuccess(false)}} style={{color:'white', textDecoration:'none', cursor:'pointer'}}>Try again</span>
              </div> */}
                <button onClick={() => setDisplay("login")}>
                  <div
                    style={{
                      backgroundColor: theme.primaryHighlight,
                      display: "flex",
                      width: 350,
                      height: 46.57,
                      textAlign: "center",
                      alignItems: "center",
                      justifyContent: "space-around",
                      borderRadius: 15,
                    }}
                  >
                    Return to Sign In
                  </div>
                </button>
              </div>
            </div>
          )}

          {/* Footer */}
          <div
            style={{
              //   backgroundColor: "blue",
              display: "flex",
              width: 250,
              height: 52.56,
              textAlign: "center",
              alignItems: "center",
            }}
          >
            <div>
              @ 2023 - {new Date().getFullYear()}, Powered by RescAlert Inc.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
