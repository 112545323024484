import { UserAgentContext } from "App";
import React, { useContext } from "react";
import { FaChevronLeft } from "react-icons/fa";
import { FaX } from "react-icons/fa6";

const MobilePopup = ({
  title = "Page Title",
  content = <div>Page Content</div>,
  options = [],
  close = () => {},
  canClose = true,
}) => {
  const userAgent = useContext(UserAgentContext);
  return (
    <div className="popup">
      <div
        className="popup-base"
        style={{
          marginBottom: userAgent.isIPhone() ? "10vh" : "0px",
          height: userAgent.isIPhone() ? "85vh" : "",
        }}
      >
        <div className="popup-header border-bottom">
          {canClose && (
            <button
              onClick={() => {
                close();
              }}
            >
              <FaX />
            </button>
          )}
          {title}
        </div>
        <div className="popup-content">{content}</div>
        {options && (
          <div className="popup-footer flex justify-between">
            {options.map((option, index) => {
              return (
                <button
                  key={index}
                  className={"button " + option.className}
                  onClick={() => {
                    option.action();
                  }}
                >
                  {option.display}
                </button>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default MobilePopup;
