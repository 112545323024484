import React from "react";
import ToggleButton from "./ToggleButton";
import { TbCheck } from "react-icons/tb";

const SettingButton = ({
  displayName,
  settingName,
  displayCheckmark = true,
  deviceSettings,
  device,
  setDeviceSettings,
  enabled = true,
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: 5,
        justifyContent: "space-between",
        width: 330,
      }}
    >
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <div style={{ width: 10, marginRight: 15, alignItems: "center" }}>
          {displayCheckmark &&
            !(
              (settingName === "hResImgEnable"
                ? deviceSettings[settingName] === 0
                : deviceSettings[settingName] === 2) &&
              device.device_settings[settingName] === null
            ) &&
            deviceSettings[settingName] !==
              device.device_settings[settingName] && (
              <TbCheck color='green' size={20} />
            )}
        </div>
        <label>{displayName}:</label>
      </div>

      <div>
        <ToggleButton
          isOff={
            settingName === "hResImgEnable"
              ? deviceSettings[settingName] > 1
              : !deviceSettings[settingName]
          }
          onClick={
            enabled
              ? () => {
                  let newValue;

                  if (settingName === "hResImgEnable")
                    newValue = deviceSettings[settingName] === 1 ? 2 : 1;
                  else newValue = deviceSettings[settingName] === 0 ? 1 : 0;

                  setDeviceSettings({
                    ...deviceSettings,
                    [settingName]: newValue,
                  });
                }
              : () => {}
          }
        />
      </div>
    </div>
  );
};

export default SettingButton;
