import React, { useEffect, useRef } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  TimeScale,
} from "chart.js/auto";
import * as Charts from "react-chartjs-2";
import { renderToStaticMarkup } from "react-dom/server";
import ConditionIcons from "components/ConditionIcons";
import { WiDirectionUp } from "react-icons/wi";
import { FaCamera } from "react-icons/fa";
import zoomPlugin from "chartjs-plugin-zoom";
//custom plugins
export const TextAtPoint = {
  id: "textAtPoint",
  afterDatasetsDraw(chart, args, options) {
    if (!options?.enabled) return;

    const { ctx, data } = chart;

    ctx.save();

    //draw text on top of bars
    const renderText = (dataset, datapoint, dataindex) => {
      ctx.font = `bold ${12 * (ctx.canvas.width / 1554)}px san-serif`;
      ctx.fillStyle = options?.fillStyle ? options.fillStyle : "black";
      ctx.textAlign = "center";
      ctx.fillText(
        data.datasets[dataset].data[dataindex]?.value
          ? data.datasets[dataset].data[dataindex].value
          : "",
        datapoint.x,
        datapoint.y - 15
      );
    };

    if (options.datasets?.length > 0) {
      options.datasets.map((dataset) => {
        chart.getDatasetMeta(dataset).data.map((datapoint, index) => {
          renderText(dataset, datapoint, index);
        });
      });
    } else if (options.type) {
      chart._metasets.map((metaset, metaindex) => {
        if (metaset.type === options.type) {
          chart.getDatasetMeta(metaindex).data.map((datapoint, index) => {
            renderText(metaindex, datapoint, index);
          });
        }
      });
    } else {
      chart._metasets.map((metaset, metaindex) => {
        chart.getDatasetMeta(metaindex).data.map((datapoint, index) => {
          renderText(metaindex, datapoint, index);
        });
      });
    }

    ctx.restore();
  },
};

export const WeatherIconAtPoint = {
  id: "weatherIconAtPoint",
  afterDatasetsDraw(chart, args, options) {
    if (!options?.enabled) return;

    const { ctx, data } = chart;
    const iconSize = 50; //(100 * ctx.canvas.width) / 1554;

    ctx.save();

    //draw icon on top of bars
    const renderIcon = (datapoint, iconId) => {
      const iconSvg = renderToStaticMarkup(
        <ConditionIcons
          conditionId={iconId}
          size={iconSize}
          style={options?.iconStyle}
        />
      );
      const svgUrl = `data:image/svg+xml,${encodeURIComponent(iconSvg)}`;
      const pointImage = new Image();
      pointImage.src = svgUrl;
      ctx.drawImage(
        pointImage,
        datapoint.x - iconSize / 2,
        datapoint.y - iconSize / 2
      );
    };

    let step = 0;

    if (options.datasets?.length > 0) {
      options.datasets.map((dataset) => {
        chart.getDatasetMeta(dataset).data.map((datapoint, index) => {
          if (step == 0) {
            //only render by step
            step += options.step ? options.step : 0;
            renderIcon(datapoint, options?.iconIds[index]);
          } else step--;
        });
      });
    } else if (options.type) {
      chart._metasets.map((metaset, metaindex) => {
        if (metaset.type === options.type) {
          chart.getDatasetMeta(metaindex).data.map((datapoint, index) => {
            if (step == 0) {
              //only render by step
              step += options.step ? options.step : 0;
              renderIcon(datapoint, options?.iconIds[index]);
            } else step--;
          });
        }
      });
    } else {
      chart._metasets.map((metaset, metaindex) => {
        chart.getDatasetMeta(metaindex).data.map((datapoint, index) => {
          if (step == 0) {
            //only render by step
            step += options.step ? options.step : 0;
            renderIcon(datapoint, options?.iconIds[index]);
          } else step--;
        });
      });
    }

    ctx.restore();
  },
};

export const WindDirectionIcon = {
  id: "windDirectionIcon",
  afterDatasetsDraw(chart, args, options) {
    if (!options?.enabled) return;

    const { ctx, data } = chart;
    const iconSize = (75 * ctx.canvas.width) / 1554;

    ctx.save();

    //draw icon on top of bars
    const renderIcon = (datapoint) => {
      const iconSvg = renderToStaticMarkup(
        <WiDirectionUp
          size={iconSize}
          style={{
            transform: `rotate(${datapoint.$context.raw.windDirection}deg)`,
            ...options?.iconStyle,
          }}
        />
      );
      const svgUrl = `data:image/svg+xml,${encodeURIComponent(iconSvg)}`;
      const pointImage = new Image();
      pointImage.src = svgUrl;
      ctx.drawImage(
        pointImage,
        datapoint.x - iconSize / 2,
        datapoint.y - iconSize / 2
      );
    };

    let step = 0;

    if (options.datasets?.length > 0) {
      options.datasets.map((dataset) => {
        chart.getDatasetMeta(dataset).data.map((datapoint, index) => {
          if (step == 0) {
            //only render by step
            step += options.step ? options.step : 0;
            renderIcon(datapoint, options?.iconIds[index]);
          } else step--;
        });
      });
    } else if (options.type) {
      chart._metasets.map((metaset, metaindex) => {
        if (metaset.type === options.type) {
          chart.getDatasetMeta(metaindex).data.map((datapoint, index) => {
            if (step == 0) {
              //only render by step
              step += options.step ? options.step : 0;
              renderIcon(datapoint, options?.iconIds[index]);
            } else step--;
          });
        }
      });
    } else {
      chart._metasets.map((metaset, metaindex) => {
        chart.getDatasetMeta(metaindex).data.map((datapoint, index) => {
          if (step == 0) {
            //only render by step
            step += options.step ? options.step : 0;
            renderIcon(datapoint, options?.iconIds[index]);
          } else step--;
        });
      });
    }

    ctx.restore();
  },
};

let indexDatapoint;
const CustomTooltip = {
  id: "customTooltip",
  afterDatasetsDraw(chart, args, options) {
    if (!options.enabled) return;

    const {
      ctx,
      data,
      scales: { x, y },
    } = chart;
    const hr = ctx.canvas.width / 1554; //horizontal ratio
    const vr = ctx.canvas.height / 777; //vertical ratio

    const width = 300 * hr;
    const height = 260 * vr;
    const borderWidth = 2 * hr;
    const borderDiWidth = width + borderWidth * 2;
    const borderDiHeight = height + borderWidth * 2;

    try {
      if (
        indexDatapoint === undefined ||
        indexDatapoint.index > data.datasets[0].data.length
      ) {
        return "";
      }

      //draw boxes
      const xPos = Math.max(
        Math.min(indexDatapoint.xCoord - width / 2, 1250 * hr),
        50 * hr
      );
      const yPos = Math.min(indexDatapoint.yCoord + 15 * vr, 500 * vr);

      //border
      ctx.beginPath();
      ctx.fillStyle = "red";
      ctx.roundRect(
        xPos - borderWidth,
        yPos - borderWidth,
        borderDiWidth,
        borderDiHeight,
        10
      );
      ctx.fill();
      ctx.closePath();

      //background
      ctx.beginPath();
      ctx.fillStyle = "white";
      ctx.roundRect(xPos, yPos, width, height, 10);
      ctx.fill();
      ctx.closePath();

      //title
      ctx.fillStyle = "black";
      ctx.font = `${15 * hr}px sans-serif`;
      ctx.textAlign = "left";
      ctx.fillText(
        data.datasets[0].data[indexDatapoint.index].title
          ? data.datasets[0].data[indexDatapoint.index].title
          : "",
        xPos + 5 * hr,
        yPos + 15 * vr
      );

      //description
      ctx.font = `${20 * hr}px sans-serif`;
      ctx.fontWeight = "bold";
      ctx.fillText(
        data.datasets[0].data[indexDatapoint.index].beaufort,
        xPos + 5 * hr,
        yPos + 35 * vr
      );

      //weather icon
      const iconSize = 100 * hr;
      const iconSvg = renderToStaticMarkup(
        <ConditionIcons
          conditionId={data.datasets[0].data[indexDatapoint.index].icon}
          size={iconSize}
        />
      );
      const svgUrl = `data:image/svg+xml,${encodeURIComponent(iconSvg)}`;
      const pointImage = new Image();
      pointImage.src = svgUrl;
      ctx.drawImage(
        pointImage,
        xPos - iconSize / 2 + 55 * hr,
        yPos - iconSize / 2 + 95 * vr
      );

      //wind direction
      ctx.font = `${15 * hr}px sans-serif`;
      ctx.fontWeight = "none";
      ctx.fillText(
        data.datasets[0].data[indexDatapoint.index].windDirection,
        xPos + 205 * hr,
        yPos + 95 * vr
      );

      //temperature
      const angle = Math.PI / 180;
      ctx.beginPath();
      ctx.fillStyle = "red";
      ctx.arc(
        xPos + 20 * hr,
        yPos + 173.5 * vr,
        5 * hr,
        angle * 0,
        angle * 360,
        false
      );
      ctx.fill();
      ctx.closePath();

      ctx.fillStyle = "black";
      ctx.fillText("Temperature:", xPos + 35 * hr, yPos + 175 * vr);
      ctx.fillText(
        data.datasets[0].data[indexDatapoint.index].temperature,
        xPos + 165 * hr,
        yPos + 175 * vr
      );

      //dew point
      ctx.beginPath();
      ctx.fillStyle = "green";
      ctx.arc(
        xPos + 20 * hr,
        yPos + 193.5 * vr,
        5 * hr,
        angle * 0,
        angle * 360,
        false
      );
      ctx.fill();
      ctx.closePath();

      ctx.fillStyle = "black";
      ctx.fillText("Dew Point:", xPos + 35 * hr, yPos + 195 * vr);
      ctx.fillText(
        data.datasets[0].data[indexDatapoint.index].dewPoint,
        xPos + 165 * hr,
        yPos + 195 * vr
      );

      //precip probability
      ctx.beginPath();
      ctx.fillStyle = "lightblue";
      ctx.arc(
        xPos + 20 * hr,
        yPos + 213.5 * vr,
        5 * hr,
        angle * 0,
        angle * 360,
        false
      );
      ctx.fill();
      ctx.closePath();

      ctx.fillStyle = "black";
      ctx.fillText("Precip Probability:", xPos + 35 * hr, yPos + 215 * vr);
      ctx.fillText(
        data.datasets[0].data[indexDatapoint.index].precipProb,
        xPos + 165 * hr,
        yPos + 215 * vr
      );

      //wind
      ctx.beginPath();
      ctx.fillStyle = "black";
      ctx.arc(
        xPos + 20 * hr,
        yPos + 233.5 * vr,
        5 * hr,
        angle * 0,
        angle * 360,
        false
      );
      ctx.fill();
      ctx.closePath();

      ctx.fillStyle = "black";
      ctx.fillText("Wind:", xPos + 35 * hr, yPos + 235 * vr);
      ctx.fillText(
        data.datasets[0].data[indexDatapoint.index].windSpeed + "MPH",
        xPos + 165 * hr,
        yPos + 235 * vr
      );
    } catch (e) {
      //   "Error thrown:",
      //   e,
      //   " | Data:",
      //   data,
      //   ".",
      //   data.datasets[0],
      //   ".",
      //   data.datasets[0].data,
      //   "[",
      //   indexDatapoint.index,
      //   "].title =",
      //   data.datasets[0].data[indexDatapoint.index]?.title,
      // );
    }
  },
  afterEvent(chart, args, options) {
    if (!options.enabled) return;

    const {
      ctx,
      data,
      scales: { x, y },
    } = chart;
    const xCoord = args.event.x;
    const yCoord = args.event.y;

    ctx.save();

    chart.getDatasetMeta(0).data?.map((datapoint, index) => {
      if (datapoint.active === true && args.inChartArea) {
        indexDatapoint = {
          index: index,
          xCoord: xCoord,
          yCoord: yCoord,
        };
      }
    });

    if (!args.inChartArea) {
      indexDatapoint = undefined;
    }
  },
};

let indexDatapointDevice;
const CustomAwareFloodTooltip = {
  id: "customAwareFloodTooltip",
  afterDatasetsDraw(chart, args, options) {
    if (!options.enabled) return;

    const {
      ctx,
      data,
      scales: { x, y },
    } = chart;
    const hr = ctx.canvas.width / 1554; //horizontal ratio
    const vr = ctx.canvas.height / 777; //vertical ratio

    const width = 400 * hr;
    const height = 220 * vr;
    const borderWidth = 2 * hr;
    const borderDiWidth = width + borderWidth * 2;
    const borderDiHeight = height + borderWidth * 2;

    try {
      if (
        indexDatapoint === undefined ||
        indexDatapoint.index > data.datasets[0].data.length
      ) {
        return "";
      }

      const getClosestDataSetPoint = () => {
        const yCoord = indexDatapoint.yCoord;
        let closest = Infinity;

        chart._metasets.forEach((metaset, index) => {
          const dist = Math.abs(metaset.data[indexDatapoint.index].y - yCoord);
          if (dist < closest) closest = dist;
        });

        let indicies = [];

        chart._metasets.forEach((metaset, mIndex) => {
          const dist = Math.abs(metaset.data[indexDatapoint.index].y - yCoord);
          if (dist == closest) indicies.push(mIndex);
        });

        return indicies;
      };

      const datasetIndex = getClosestDataSetPoint();

      //draw boxes

      //TODO: replace '1100 * hr' with a calculation that keeps the tooltip on the screen
      const xPos = Math.max(
        Math.min(indexDatapoint.xCoord - width / 2, ctx.canvas.width - width),
        hr
      );

      const yPos = Math.min(
        indexDatapoint.yCoord + 30 * vr,
        (500 - (borderDiHeight * datasetIndex.length - 1)) * vr
      );

      //border
      ctx.beginPath();
      ctx.fillStyle = data.datasets[datasetIndex[0]].backgroundColor;
      ctx.roundRect(
        xPos - borderWidth,
        yPos - borderWidth,
        borderDiWidth,
        borderDiHeight * datasetIndex.length + 40,
        10
      );
      ctx.fill();
      ctx.closePath();

      //background
      ctx.beginPath();
      ctx.fillStyle = "white";
      ctx.roundRect(xPos, yPos, width, height * datasetIndex.length + 40, 10);
      ctx.fill();
      ctx.closePath();

      //timestamp
      ctx.fillStyle = "black";
      ctx.font = `${15 * hr}px sans-serif`;
      ctx.textAlign = "left";

      ctx.fillText(
        data.datasets[datasetIndex].data[indexDatapoint.index].x,
        xPos + (width / 4) * hr,
        yPos + 20 * vr
      );

      let yElement = 10;

      datasetIndex.forEach((dIndex, i) => {
        const offset = 30 * (datasetIndex.length - 1);

        if (i > 0) {
          yElement += 45;
          ctx.beginPath();
          ctx.fillStyle = "black";
          ctx.rect(xPos + 10 * hr, yPos + yElement * vr, width - 15 * hr, 1);
          ctx.fill();
          ctx.closePath();
        }

        //title
        yElement += i === 0 ? 45 : 30;
        ctx.fillStyle = "black";
        ctx.font = `${15 * hr}px sans-serif`;
        ctx.textAlign = "left";
        ctx.fillText(
          data.datasets[dIndex]._meta.alias,
          xPos + (width / 4) * hr,
          yPos + yElement * vr
        );

        //Actual Depth
        yElement += 45;

        ctx.beginPath();
        ctx.fillStyle = "yellow";
        ctx.roundRect(
          xPos + 20 * hr,
          yPos + (yElement - (25 * hr) / 1.5) * vr,
          50 * vr,
          25 * hr
        );
        ctx.fill();
        ctx.closePath();

        ctx.fillStyle = "black";
        ctx.fillText(
          `Actual Depth (in/ft): ${
            data.datasets[dIndex]._meta.data[indexDatapoint.index].sensordepths
          }/${(
            data.datasets[dIndex]._meta.data[indexDatapoint.index]
              .sensordepths / 12
          ).toFixed(3)}"`,

          xPos + 85 * hr,
          yPos + yElement * vr
        );

        //Depth
        yElement += 30;

        ctx.beginPath();
        ctx.fillStyle = "blue";
        ctx.roundRect(
          xPos + 20 * hr,
          yPos + (yElement - (25 * hr) / 1.5) * vr,
          50 * vr,
          25 * hr
        );
        ctx.fill();
        ctx.closePath();

        ctx.fillStyle = "black";
        ctx.fillText(
          `Depth (in/ft): ${
            data.datasets[dIndex]._meta.data[indexDatapoint.index].depth1
          }"/${(
            data.datasets[dIndex]._meta.data[indexDatapoint.index].depth1 / 12
          ).toFixed(3)}'`,
          xPos + 85 * hr,
          yPos + yElement * vr
        );

        //Temperature
        yElement += 30;
        ctx.beginPath();
        ctx.fillStyle = "green";
        ctx.roundRect(
          xPos + 20 * hr,
          yPos + (yElement - (25 * hr) / 1.5) * vr,
          50 * vr,
          25 * hr
        );
        ctx.fill();
        ctx.closePath();

        ctx.fillStyle = "black";
        ctx.fillText(
          `Temperature (°F/°C): ${(
            (data.datasets[dIndex]._meta.data[indexDatapoint.index].airTemp *
              9) /
              5 +
            32
          ).toFixed(3)}/${
            data.datasets[dIndex]._meta.data[indexDatapoint.index].airTemp
          }`,
          xPos + 85 * hr,
          yPos + yElement * vr
        );

        //Pressure
        yElement += 30;
        ctx.beginPath();
        ctx.fillStyle = "purple";
        ctx.roundRect(
          xPos + 20 * hr,
          yPos + (yElement - (25 * hr) / 1.5) * vr,
          50 * vr,
          25 * hr
        );
        ctx.fill();
        ctx.closePath();

        ctx.fillStyle = "black";
        ctx.fillText(
          `Pressure (mBar/inHg): ${
            data.datasets[dIndex]._meta.data[indexDatapoint.index].baro
          }/${(
            data.datasets[dIndex]._meta.data[indexDatapoint.index].baro *
            0.02953
          ).toFixed(3)}`,
          xPos + 85 * hr,
          yPos + yElement * vr
        );

        //Battery
        yElement += 30;
        ctx.beginPath();
        ctx.fillStyle = "orange";
        ctx.roundRect(
          xPos + 20 * hr,
          yPos + (yElement - (25 * hr) / 1.5) * vr,
          50 * vr,
          25 * hr
        );
        ctx.fill();
        ctx.closePath();

        ctx.fillStyle = "black";
        ctx.fillText(
          `Battery (V): ${
            data.datasets[dIndex]._meta.data[indexDatapoint.index].battery
          }V`,
          xPos + 85 * hr,
          yPos + yElement * vr
        );

        //Alert
        yElement += 30;
        ctx.beginPath();
        ctx.fillStyle = "red";
        ctx.roundRect(
          xPos + 20 * hr,
          yPos + (yElement - (25 * hr) / 1.5) * vr,
          50 * vr,
          25 * hr
        );
        ctx.fill();
        ctx.closePath();

        ctx.fillStyle = "black";

        ctx.fillText(
          `Alert Indicated: ${
            data.datasets[dIndex]._meta.data[indexDatapoint.index].redLine !== 0
          }`,
          xPos + 85 * hr,
          yPos + yElement * vr
        );

        //render image icon
        const iconSvg = renderToStaticMarkup(<FaCamera size={15} />);
        const svgUrl = `data:image/svg+xml,${encodeURIComponent(iconSvg)}`;
        const pointImage = new Image();
        pointImage.src = svgUrl;
        ctx.drawImage(pointImage, xPos + 300 * hr, yPos + (yElement - 15) * vr);
      });
    } catch (e) {}
  },
  afterEvent(chart, args, options) {
    if (!options.enabled) return;

    const {
      ctx,
      data,
      scales: { x, y },
    } = chart;
    const xCoord = args.event.x;
    const yCoord = args.event.y;

    ctx.save();

    chart.getDatasetMeta(0).data?.map((datapoint, index) => {
      if (datapoint.active === true && args.inChartArea) {
        indexDatapoint = {
          index: index,
          xCoord: xCoord,
          yCoord: yCoord,
        };
      }
    });

    if (!args.inChartArea) {
      indexDatapoint = undefined;
    }
  },
};

const VerticalLinePlugin = {
  id: "verticalLinePlugin",
  beforeDatasetsDraw(chart, args, options) {
    if (!options.enabled) return;

    const { ctx } = chart;
    const scale = chart.scales["y"];

    if (scale)
      chart._metasets.forEach((metaset) => {
        metaset._dataset._meta.data.forEach((datapoint, index) => {
          if (!datapoint.redLine) return;

          const x = metaset.data[index].x;

          //Alert line colors
          const colors = ["#ffCC00", "#ff4400", "#ff0000"];

          ctx.beginPath();
          ctx.strokeStyle = colors[datapoint.redLine - 1];
          ctx.moveTo(x, scale.bottom);
          ctx.lineTo(x, scale.bottom - 25);
          ctx.stroke();
          ctx.closePath();
        });
      });
  },
};

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  TimeScale,
  zoomPlugin,
  Title,
  Tooltip,
  Legend,
  Filler,
  TextAtPoint,
  WeatherIconAtPoint,
  WindDirectionIcon,
  CustomTooltip,
  CustomAwareFloodTooltip,
  VerticalLinePlugin
);

export default function MixedChart({
  type,
  dataSets,
  options,
  plugins = [],
  style,
  keyString,
  unitType,
  setUnitType,
  selectedDataType,
}) {
  const chartRef = useRef(null);
  const cursorX = useRef(null);
  const cursorY = useRef(null);
  const chartInstanceRef = useRef(null);

  const getUnitType = (unitType, selectedDataType) => {
    if (unitType) {
      if (selectedDataType === "temp") return "°C";
      else if (selectedDataType === "depths") return "in";
      else if (selectedDataType === "sensordepths") return "in";
      else if (selectedDataType === "baro") return "inHg";
      else if (selectedDataType === "battery") return "V";
      else if (selectedDataType === "intensity") return "in/hr";
      else if (selectedDataType === "rainfall") return "in";
      else if (selectedDataType === "tips") return "tips";
      else return "";
    } else {
      if (selectedDataType === "temp") return "°F";
      else if (selectedDataType === "depths") return "ft";
      else if (selectedDataType === "sensordepths") return "ft";
      else if (selectedDataType === "baro") return "mBar";
      else if (selectedDataType === "battery") return "V";
      else if (selectedDataType === "intensity") return "in/hr";
      else if (selectedDataType === "rainfall") return "in";
      else if (selectedDataType === "tips") return "tips";
      else return;
    }
  };

  useEffect(() => {
    const crosshairPlugin = {
      id: "crosshair",
      beforeDraw: (chart) => {
        const {
          ctx,
          chartArea: { top, bottom, left, right },
        } = chart;
        if (cursorX.current !== null && cursorY.current !== null) {
          const x = cursorX.current;
          const y = cursorY.current;
          if (x >= left && x <= right && y >= top && y <= bottom) {
            ctx.save();
            ctx.beginPath();
            // Draw horizontal line
            ctx.moveTo(left, y);
            ctx.lineTo(right, y);
            // Draw vertical line
            ctx.moveTo(x, top);
            ctx.lineTo(x, bottom);
            ctx.lineWidth = 1;
            ctx.strokeStyle = "grey";
            ctx.stroke();
            ctx.restore();
          }
        }
      },
    };

    const chartInstance = new ChartJS(chartRef.current, {
      type: type,
      data: dataSets,
      options: {
        ...options,
        plugins: {
          ...plugins,
          ...options?.plugins,
          zoom: {
            zoom: {
              wheel: {
                enabled: true,
              },
              pinch: {
                enabled: true,
              },
              mode: "x",
            },
            pan: {
              enabled: true,
              mode: "xy",
            },
          },
        },
        onHover: (event, chartElement) => {
          const { offsetX, offsetY } = event.native;
          cursorX.current = offsetX;
          cursorY.current = offsetY;
          chartInstance.draw();
        },
      },
      // plugins: [...plugins, crosshairPlugin],
    });

    chartInstanceRef.current = chartInstance;

    const handleResize = () => {
      chartInstance.resize();
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      chartInstance.destroy();
    };
  }, [type, options, dataSets, plugins]);

  const handleResetZoom = () => {
    if (chartInstanceRef.current) {
      chartInstanceRef.current.resetZoom();
    }
  };

  return (
    <div
      style={{
        backgroundColor: "white",
        width: "100%",
        height: "100%",
        position: "relative",
        ...style,
      }}
    >
      <canvas ref={chartRef} key={keyString ? keyString : 0}></canvas>
      <button
        style={{
          position: "absolute",
          backgroundColor: "#436c99",
          top: 2,
          right: 5,
          padding: 4,
          borderRadius: 5,
        }}
        onClick={handleResetZoom}
      >
        Reset Zoom
      </button>
      {setUnitType && (
        <button
          style={{
            position: "absolute",
            backgroundColor: "#436c99",
            top: 2,
            left: 85,
            padding: 4,
            borderRadius: 5,
          }}
          onClick={() => setUnitType(!unitType)}
        >
          Units: {getUnitType(unitType, selectedDataType)}
        </button>
      )}
    </div>
  );
}
